import React from 'react'
import { useMutation } from '@apollo/client'
import {
  UpdateUserInput,
  updateProfessional,
  updateProfessionalVariables,
  getProfile_getUser as UserType,
} from 'types/generated/schemaTypes'
import { FormControl, Grid, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UPDATE_PROFESSIONAL } from 'mutation'
import { ActionType, DialogForm } from 'components'
import { formatError } from 'utils'

export interface UpdateProfileOperatorValues {
  name: string
  lastname: string
  email: string
  emailCheck: string
}

const defaultInitialValues = {
  name: '',
  lastname: '',
  email: '',
  emailCheck: '',
}

const parseInitialValue = (input: UserType): UpdateProfileOperatorValues => {
  const values: UpdateProfileOperatorValues = {
    name: input.name,
    lastname: input.lastname,
    email: input.email,
    emailCheck: input.email,
  }
  return values
}

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obbligatorio'),
  lastname: Yup.string().required('Campo obbligatorio'),
  email: Yup.string()
    .email('Indirizzo email non valido')
    .required('Campo obbligatorio'),
  emailCheck: Yup.string()
    .email('Indirizzo email non valido')
    .test('equal', 'Le email non combaciano', function (v) {
      const ref = Yup.ref('email')
      return v === this.resolve(ref)
    })
    .required('Campo obbligatorio'),
})

interface UpdateProfileOperatorProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  user: UserType | null
}

const UpdateProfileOperator: React.FC<UpdateProfileOperatorProps> = ({
  open,
  onClose,
  onSuccess,
  user,
}) => {
  const [updateProfessionalMutation, { error: formError }] = useMutation<
    updateProfessional,
    updateProfessionalVariables
  >(UPDATE_PROFESSIONAL, {
    onCompleted: async (data) => {
      formik.resetForm()
      onSuccess()
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const formik = useFormik<UpdateProfileOperatorValues>({
    initialValues: user
      ? parseInitialValue(user as UserType)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables: { userData: UpdateUserInput; userId: string } = {
        userData: {
          name: values.name,
          lastname: values.lastname,
          email: values.email,
        },
        userId: user?.id as string,
      }
      await updateProfessionalMutation({ variables })
    },
  })

  return (
    <DialogForm
      open={open}
      title="Modifica profilo"
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: 'ESCI SENZA SALVARE',
          callback: () => {
            formik.resetForm()
            onClose()
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: 'SALVA E CHIUDI',
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginBottom: '1em' }}
        >
          Di seguito le informazioni relative al suo profilo. Per salvare le
          modifiche cliccare sul pulsante "Salva e chiudi" in fondo alla pagina.
        </Typography>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Dati personali
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* NOME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="outlined"
                id="name"
                size="small"
                label="Nome*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* COGNOME */}
            <FormControl fullWidth={true}>
              <TextField
                name="lastname"
                error={formik.touched.lastname && !!formik.errors.lastname}
                helperText={
                  formik.touched.lastname &&
                  !!formik.errors.lastname &&
                  formik.errors.lastname
                }
                variant="outlined"
                id="lastname"
                size="small"
                label="Cognome*"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="outlined"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* VERIFICA EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="emailCheck"
                error={formik.touched.emailCheck && !!formik.errors.emailCheck}
                helperText={
                  formik.touched.emailCheck &&
                  !!formik.errors.emailCheck &&
                  formik.errors.emailCheck
                }
                variant="outlined"
                id="emailCheck"
                size="small"
                label="Verifica email*"
                value={formik.values.emailCheck}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>
        {formError && (
          <Typography color="error" variant="body1">
            {formatError(formError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  )
}

export default UpdateProfileOperator
