import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  footerLeft: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '15px 0 15px 15px',
    alignSelf: 'flex-end',
    order: 2,
    borderTop: `solid 1px rgba(0,0,0,0.08)`,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: `calc(50% - ${theme.spacing(2)}px)`,
      padding: '45px 0 105px 60px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: `45px 0 105px calc(50vw - 580px)`,
    },
  },
  footerRight: {
    order: 1,
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(2),
    backgroundImage: 'url("001-02.jpg")',
    backgroundPosition: 'bottom right',
    backgroundSize: 'cover',
    position: 'relative',
    color: 'white',
    [theme.breakpoints.up('md')]: {
      order: 2,
      width: `calc(50% - ${theme.spacing(2)}px)`,
    },
  },
}))

export default function Home() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <div className={classes.footerLeft}>
        <Typography variant="caption" display="block" color="textSecondary">
          Copyright © 2021 Bromatech
          <br />
          Tutti i diritti riservati
          <br />
          <br />
          C.C.I.A.A. 1492592
          <br />
          Reg. Imp. 17457/96
          <br />
          <a
            href="https://www.bromatech.it/privacy-policy"
            style={{
              marginTop: '1em',
              display: 'inline-block',
            }}
          >
            PRIVACY POLICY
          </a>
          <br />
          <a
            href="https://www.bromatech.it/note-legali"
            style={{
              marginTop: '0.5em',
              display: 'inline-block',
            }}
          >
            NOTE LEGALI
          </a>
          <br />
          <a
            href="https://www.bromatech.it/cookie-policy"
            style={{
              marginTop: '0.5em',
              display: 'inline-block',
            }}
          >
            COOKIE POLICY
          </a>
        </Typography>
      </div>
      <div className={classes.footerRight}>
        <Typography variant="h2" style={{ color: 'white' }}>
          Contatti
        </Typography>
        <Typography variant="caption">
          Sede legale e amministrativa
          <br /> Bromatech Srl
          <br />
          Viale Premuda 46
          <br />
          20129 Milano, Italia
          <br />
          P.IVA e C.F. 11702480150
        </Typography>
        <Typography variant="caption" display="block">
          <a
            style={{ color: 'white', margin: '1em 0', display: 'inline-block' }}
            href="mailto:info@bromatech.it"
          >
            info@bromatech.it
          </a>
        </Typography>
        <Typography variant="caption" display="block">
          Siamo lieti di potervi fornire maggiori
          <br />
          informazioni tramite la compilazione
          <br />
          del nostro formulario di contatto.
        </Typography>
        <Typography variant="caption" display="block">
          <a
            style={{
              color: 'white',
              marginTop: '1em',
              display: 'inline-block',
            }}
            href="https://www.bromatech.it/contatti"
          >
            AL FORMULARIO
          </a>
        </Typography>
      </div>
    </footer>
  )
}
