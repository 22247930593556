import { gql } from '@apollo/client'

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`

const CREATE_PROFESSIONAL = gql`
  mutation createProfessional($userData: CreateUserInput!) {
    createUser(userData: $userData) {
      id
    }
  }
`

const CREATE_OPERATOR = gql`
  mutation createOperator($userData: CreateUserInput!) {
    createUser(userData: $userData) {
      id
    }
  }
`

const DELETE_PROFESSIONALS = gql`
  mutation deleteProfessionals($users: [ID!]!) {
    deleteUsers(users: $users)
  }
`

const DELETE_OPERATORS = gql`
  mutation deleteOperators($users: [ID!]!) {
    deleteUsers(users: $users)
  }
`

const SET_USERS_STATUS = gql`
  mutation setUsersStatus($users: [ID!]!, $active: Boolean!) {
    setUsersStatus(users: $users, active: $active)
  }
`

const UPDATE_PROFESSIONAL = gql`
  mutation updateProfessional($userId: ID!, $userData: UpdateUserInput!) {
    updateUser(userId: $userId, userData: $userData) {
      id
      email
      role
      active
      name
      lastname
      details {
        verified
        addresses {
          addressLine
          zip
          city
          province
          region
        }
        profession
        specialization
        customProfession
        customSpecialization
        businessName
        businessRole
        marketing
        agent
        gender
        birthDate
        phone
        fiscalCode
        registerId
      }
    }
  }
`

const UPDATE_PASSWORD = gql`
  mutation updatePassword($userId: ID!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      message
    }
  }
`

const UPDATE_EVENT = gql`
  mutation updateMedicalEvent(
    $eventId: ID!
    $eventData: UpdateMedicalEventInput!
  ) {
    updateMedicalEvent(eventId: $eventId, eventData: $eventData) {
      id
      title
      tagline
      registrationDetails
      registrationDetails2
      registrationConfirmation
      webinarLink
      googleMapsLink
      address
      location
      city
      agent
      cover
      schedule
      curriculum
      attachment1
      attachment2
      attachmentTitle1
      attachmentTitle2
      speakers
      dates {
        start
        end
      }
      usersTotal
      usersMax
      open
      hideEmail
      type
      status
      deadline
      area
    }
  }
`

const CREATE_EVENT = gql`
  mutation createMedicalEvent($eventData: CreateMedicalEventInput!) {
    createMedicalEvent(eventData: $eventData) {
      id
      title
      tagline
      registrationDetails
      registrationDetails2
      registrationConfirmation
      webinarLink
      googleMapsLink
      address
      location
      city
      agent
      cover
      schedule
      curriculum
      attachment1
      attachment2
      attachmentTitle1
      attachmentTitle2
      speakers
      dates {
        start
        end
      }
      usersTotal
      usersMax
      open
      listed
      hideEmail
      type
      status
      deadline
      area
    }
  }
`

const DELETE_EVENTS = gql`
  mutation deleteMedicalEvents($ids: [ID!]!) {
    deleteMedicalEvents(ids: $ids)
  }
`

const DELETE_FILE = gql`
  mutation deleteFile($url: String!) {
    deleteFile(url: $url) {
      message
    }
  }
`

const SUBSCRIBE_EVENT = gql`
  mutation subscribeEvent($userId: ID!, $eventId: ID!) {
    subscribeEvent(userId: $userId, eventId: $eventId) {
      message
    }
  }
`

const UNSUBSCRIBE_EVENT = gql`
  mutation unsubscribeEvent($userId: ID!, $eventId: ID!) {
    unsubscribeEvent(userId: $userId, eventId: $eventId) {
      message
    }
  }
`

const CREATE_DOCUMENT = gql`
  mutation createDocument($documentData: CreateDocumentInput!) {
    createDocument(documentData: $documentData) {
      id
      title
      cover
      url
      highlighted
    }
  }
`

const UPDATE_DOCUMENT = gql`
  mutation updateDocument(
    $documentId: ID!
    $documentData: UpdateDocumentInput!
  ) {
    updateDocument(documentId: $documentId, documentData: $documentData) {
      id
      title
      cover
      url
      highlighted
    }
  }
`

const DELETE_DOCUMENTS = gql`
  mutation deleteDocuments($ids: [ID!]!) {
    deleteDocuments(ids: $ids)
  }
`

const REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      message
    }
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword(
    $resetToken: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      resetToken: $resetToken
      password: $password
      confirmPassword: $confirmPassword
    ) {
      message
    }
  }
`

export {
  CREATE_DOCUMENT,
  CREATE_EVENT,
  CREATE_OPERATOR,
  CREATE_PROFESSIONAL,
  DELETE_DOCUMENTS,
  DELETE_EVENTS,
  DELETE_FILE,
  DELETE_PROFESSIONALS,
  DELETE_OPERATORS,
  LOGIN,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  SET_USERS_STATUS,
  SUBSCRIBE_EVENT,
  UNSUBSCRIBE_EVENT,
  UPDATE_DOCUMENT,
  UPDATE_EVENT,
  UPDATE_PASSWORD,
  UPDATE_PROFESSIONAL,
}
