import React, { useState } from 'react'
import { useQuery, useMutation, useReactiveVar } from '@apollo/client'
import { Typography } from '@material-ui/core'

import { GET_EVENT, MY_EVENTS } from 'query'
import { SUBSCRIBE_EVENT } from 'mutation'
import { userIdVar, roleVar } from 'cache'
import { UserRole } from 'types/generated/schemaTypes'
import {
  getMedicalEvent_getMedicalEvent,
  getMedicalEvent,
  getMedicalEventVariables,
  myEvents,
  myEventsVariables,
  subscribeEvent,
  subscribeEventVariables,
} from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  EventItem,
  Title,
  NoResults,
  DialogInfo,
  DialogError,
  Banner,
} from 'components'
import { isBefore } from 'date-fns'
import { useParams } from 'react-router-dom'

export default function Eventi() {
  const [
    currentEvent,
    setCurrentEvent,
  ] = useState<null | getMedicalEvent_getMedicalEvent>(null)
  const [showSubscribedMessage, setShowSubscribedMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { id } = useParams<{ id: string }>()
  const role = useReactiveVar(roleVar)
  const {
    data: eventData,
    loading: eventLoading,
    error: eventError,
  } = useQuery<getMedicalEvent, getMedicalEventVariables>(GET_EVENT, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  })

  const {
    data: myEventsData,
    loading: myEventsLoading,
    error: myEventsError,
  } = useQuery<myEvents, myEventsVariables>(MY_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      id: userIdVar() as string,
    },
  })

  const [subscribeEventMutation] = useMutation<
    subscribeEvent,
    subscribeEventVariables
  >(SUBSCRIBE_EVENT, {
    onCompleted: () => {
      setShowSubscribedMessage(true)
    },
    onError: () => {},
    refetchQueries: [
      {
        query: GET_EVENT,
        variables: {
          id,
        },
      },
      {
        query: MY_EVENTS,
        variables: {
          id: userIdVar() as string,
        },
      },
    ],
  })

  return (
    <>
      <Banner />
      <Title title="Evento" />
      <Breadcrumb current="Evento titolo" />

      {(eventLoading || myEventsLoading) && (
        <div>Caricamento dell'evento in corso</div>
      )}
      {!eventLoading &&
        !myEventsLoading &&
        !eventError &&
        !myEventsError &&
        eventData &&
        myEventsData && (
          <>
            {eventData.getMedicalEvent ? (
              <EventItem
                key={eventData.getMedicalEvent.id}
                event={eventData.getMedicalEvent}
                preview={role !== UserRole.PROFESSIONAL}
                infoMode={role !== UserRole.PROFESSIONAL}
                canRegister={
                  isBefore(
                    new Date(),
                    new Date(eventData.getMedicalEvent.deadline)
                  ) && eventData.getMedicalEvent.open
                }
                userRegistered={myEventsData.getUser?.events
                  ?.map((_event) => _event.id)
                  .includes(eventData.getMedicalEvent.id)}
                onRegister={async () => {
                  setCurrentEvent(eventData.getMedicalEvent)
                  await subscribeEventMutation({
                    variables: {
                      userId: userIdVar() as string,
                      eventId: eventData.getMedicalEvent!.id,
                    },
                  })
                }}
              />
            ) : (
              <NoResults />
            )}
          </>
        )}
      <DialogInfo
        title="Le confermiamo che la sua iscrizione è stata completata."
        open={showSubscribedMessage}
        onClose={() => setShowSubscribedMessage(false)}
      >
        <Typography variant="h3">
          {currentEvent?.registrationConfirmation}
        </Typography>
      </DialogInfo>

      <DialogError
        title="Errore!"
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
      >
        <Typography variant="h3">
          Si è verificato un errore durante la registrazione all'evento. Si
          prega di riprovare più tardi. Se il problema persiste contattare
          l'assistenza.
        </Typography>
      </DialogError>
    </>
  )
}
