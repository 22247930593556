import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { colors } from 'theme'

const MAX_WIDTH = 920

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    backgroundColor: colors.brand,
    marginTop: 30,
    backgroundImage: 'url("shape.png")',
    backgroundPosition: 'center right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
  },
  footerContent: {
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 20,
    paddingBottom: 30,
    [theme.breakpoints.up('sm')]: {
      //paddingTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: MAX_WIDTH,
      marginLeft: `calc(50vw + 140px - ${MAX_WIDTH / 2}px)`,
      marginRight: 15,
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContent}>
        <Typography
          variant="caption"
          display="block"
          align="center"
          style={{ color: 'white' }}
        >
          Sede legale e amministrativa Bromatech Srl, Viale Premuda 46, 20129
          Milano, Italia P.IVA e C.F. 11702480150{' '}
          <a
            href="mailto:areariservata@bromatech.it"
            style={{ color: 'inherit', fontWeight: 500 }}
          >
            areariservata@bromatech.it
          </a>
        </Typography>
        <Typography
          variant="caption"
          display="block"
          align="center"
          style={{ color: 'white' }}
        >
          Copyright © {new Date().getFullYear()} Bromatech. Tutti i diritti
          riservati.
        </Typography>
        <br />
        <Typography
          variant="caption"
          display="block"
          align="center"
          style={{ color: 'white' }}
        >
          <a
            href="https://www.bromatech.it/privacy"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'inherit' }}
          >
            PRIVACY POLICY
          </a>

          <br />
          <a
            href="https://www.bromatech.it/note-legali"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'inherit' }}
          >
            NOTE LEGALI
          </a>
          <br />
          <a
            href="https://www.bromatech.it/cookie-policy"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'inherit' }}
          >
            COOKIE POLICY
          </a>
        </Typography>
      </div>
    </footer>
  )
}
