import React, { useRef, useState } from 'react'
import {
  Button,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  makeStyles,
} from '@material-ui/core'
import {
  CloudUpload as UploadIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { useApolloClient, useMutation } from '@apollo/client'
import { GET_SIGNED_URL } from 'query'
import { DELETE_FILE } from 'mutation'
import {
  deleteFile,
  deleteFileVariables,
  getSignedUrl,
  getSignedUrlVariables,
  MediaType,
} from 'types/generated/schemaTypes'
import { DialogError } from 'components'

const useStyles = makeStyles((theme) => ({
  imageWrapper: { position: 'relative' },
  deleteImage: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
}))

interface FileUploadProps {
  url: string | null
  label: string
  labelSmall: string
  onChange: (newUrl: string | null) => void
  fullWidth?: boolean
  media: MediaType
  id: string
  error?: boolean
}

const FileUpload: React.FC<FileUploadProps> = ({
  url,
  label,
  labelSmall,
  onChange,
  fullWidth = false,
  media,
  id,
  error = false,
}) => {
  const classes = useStyles()
  const client = useApolloClient()
  const [showError, setShowError] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [errorMessage] = useState(
    'La dimensione del file non può superare i 10MB'
  )
  const [deleteFileMutation] = useMutation<deleteFile, deleteFileVariables>(
    DELETE_FILE
  )

  // const client = useApolloClient()
  const inputEl = useRef<HTMLInputElement>(null)
  let acceptFile: string
  switch (media) {
    case MediaType.DOCUMENT_COVER:
    case MediaType.EVENT_COVER:
      acceptFile = 'image/jpeg,image/gif,image/png'
      break
    case MediaType.DOCUMENT_FILE:
    case MediaType.EVENT_CURRICULUM:
      acceptFile = 'application/pdf,application/doc,application/docx'
      break
    case MediaType.EVENT_SCHEDULE:
      acceptFile = 'application/pdf,application/doc,application/docx'
      break
    case MediaType.EVENT_ATTACHMENT:
      acceptFile = 'application/pdf,application/doc,application/docx'
      break
  }
  const onSelectFile = () => {
    if (inputEl && inputEl.current) inputEl.current.click()
  }

  if (isUploading) return <div>Caricamento in corso</div>
  return (
    <>
      <DialogError open={showError} onClose={() => setShowError(false)}>
        {errorMessage}
      </DialogError>
      <input
        ref={inputEl}
        type="file"
        id={`${id}_input`}
        accept={acceptFile}
        // id="input-upload"
        style={{ display: 'none' }}
        onChange={async (e) => {
          const files = e.target.files
          if (files && files.length > 0 && files[0].size < 10 * 1024 * 1024) {
            const urlRequest = await client.query<
              getSignedUrl,
              getSignedUrlVariables
            >({
              query: GET_SIGNED_URL,
              variables: { media, filename: (files as FileList)[0].name },
              fetchPolicy: 'network-only',
            })
            const { uploadUrl, finalUrl } = urlRequest.data.getSignedUrl
            // const fileUrl = signedUrl?.split('?')[0]
            setIsUploading(true)
            const response = await fetch(uploadUrl as string, {
              method: 'PUT',
              body: (files as FileList)[0],
            })
            if (response.ok) {
              onChange(finalUrl || null)
              setIsUploading(false)
            }
          } else {
            setShowError(true)
          }
        }}
      />

      {url ? (
        <>
          {[MediaType.DOCUMENT_COVER, MediaType.EVENT_COVER].includes(media) ? (
            <div className={classes.imageWrapper}>
              <img src={url} alt="" style={{ width: '100%', height: 'auto' }} />
              <Button
                className={classes.deleteImage}
                aria-label="delete file"
                size="small"
                // color="primary"
                variant="contained"
                onClick={() => {
                  deleteFileMutation({ variables: { url } })
                  onChange(null)
                }}
                startIcon={<DeleteIcon />}
              >
                Elimina
              </Button>
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <FormControl fullWidth={true} variant="outlined" size="small">
                <InputLabel htmlFor={id}>{labelSmall}</InputLabel>
                <OutlinedInput
                  name={id}
                  id={id}
                  label={labelSmall}
                  value={url.split('/')[url.split('/').length - 1]}
                  readOnly={true}
                  type={'text'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="delete file"
                        onClick={() => {
                          deleteFileMutation({ variables: { url } })
                          onChange(null)
                        }}
                        edge="end"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          )}
        </>
      ) : (
        <>
          <FormControl
            fullWidth={true}
            variant="outlined"
            size="small"
            error={error}
          >
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
              name={id}
              id={id}
              label={label}
              onClick={onSelectFile}
              type={'text'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="upload file" edge="end">
                    <UploadIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </>
      )}
    </>
  )
}

export default FileUpload
