import React from 'react'
import {
  Button,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { Create as CreateIcon } from '@material-ui/icons'
import { professionsMap } from 'utils'
import {
  getProfile_getUser as UserType,
  Gender,
  UserRole,
  Profession,
  Specialization,
} from 'types/generated/schemaTypes'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#ffffff',
      padding: '60px 105px 50px',
    },
  },
  fixBaseline: {
    position: 'relative',
    top: 2,
  },
}))

interface ProfileDataTableProps {
  userProfile: UserType
  onChangeProfile: () => void
  onChangePassword: () => void
}

const ProfileDataTable: React.FC<ProfileDataTableProps> = ({
  userProfile,
  onChangePassword,
  onChangeProfile,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const useElevation = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <Paper className={classes.root} elevation={useElevation ? 1 : 0}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={8}>
            <TableContainer component="div">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h4"
                        color="textSecondary"
                        style={{ marginBottom: 15 }}
                      >
                        Profilo
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Hidden mdUp>
                        <Button
                          variant="text"
                          size="small"
                          color="primary"
                          startIcon={<CreateIcon />}
                          onClick={onChangeProfile}
                        >
                          MODIFICA PROFILO
                        </Button>
                      </Hidden>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={classes.fixBaseline}
                      >
                        NOME
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.fixBaseline}
                      >
                        {userProfile.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={classes.fixBaseline}
                      >
                        COGNOME
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.fixBaseline}
                      >
                        {userProfile.lastname}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {userProfile.role === UserRole.PROFESSIONAL &&
                    userProfile.details && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              SESSO
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              className={classes.fixBaseline}
                            >
                              {userProfile.details.gender === Gender.M
                                ? 'Maschio'
                                : 'Femmina'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              DATA DI NASCITA
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              className={classes.fixBaseline}
                            >
                              {format(
                                new Date(userProfile.details.birthDate),
                                'dd/MM/yyyy'
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              CODICE FISCALE
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              className={classes.fixBaseline}
                            >
                              {userProfile.details.fiscalCode}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              STUDIO PRIMARIO
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              className={classes.fixBaseline}
                            >
                              {userProfile.details.addresses[0].addressLine},{' '}
                              {userProfile.details.addresses[0].zip},{' '}
                              {userProfile.details.addresses[0].city} (
                              {userProfile.details.addresses[0].province}
                              ), {userProfile.details.addresses[0].region}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {userProfile.details.addresses.length > 1 && (
                          <>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant="h6"
                                  color="textSecondary"
                                  className={classes.fixBaseline}
                                >
                                  STUDIO SECONDARIO
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  className={classes.fixBaseline}
                                >
                                  {userProfile.details.addresses[1].addressLine}
                                  , {userProfile.details.addresses[1].zip}-{' '}
                                  {userProfile.details.addresses[1].city} (
                                  {userProfile.details.addresses[1].province}) -{' '}
                                  {userProfile.details.addresses[1].region}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              PROFESSIONE
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              className={classes.fixBaseline}
                            >
                              {
                                professionsMap[userProfile.details.profession]
                                  .label
                              }
                              {userProfile.details.customProfession &&
                                userProfile.details.customProfession ===
                                  Profession.ALTRO &&
                                ` (${userProfile.details.customProfession})`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              SPECIALIZZAZIONE
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              className={classes.fixBaseline}
                            >
                              {
                                professionsMap[
                                  userProfile.details.profession
                                ].specializations.find(
                                  (item) =>
                                    item.specialization ===
                                    userProfile.details?.specialization
                                )?.label
                              }
                              {userProfile.details.customSpecialization &&
                                userProfile.details.customSpecialization ===
                                  Specialization.ALTRO &&
                                ` (${userProfile.details.customSpecialization})`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              ISCRIZIONE
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              className={classes.fixBaseline}
                            >
                              {userProfile.details.registerId}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.fixBaseline}
                            >
                              TELEFONO
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              className={classes.fixBaseline}
                            >
                              {userProfile.details.phone}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={classes.fixBaseline}
                      >
                        EMAIL
                        {userProfile.role === UserRole.PROFESSIONAL && '*'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.fixBaseline}
                      >
                        {userProfile.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {userProfile.role === UserRole.PROFESSIONAL && (
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ marginTop: '1em' }}
              >
                * Non è possibile modificare l’indirizzo email. Se ha necessità
                di cambiarlo ci contatti.
              </Typography>
            )}
          </Grid>
          <Hidden only={['xs', 'sm']}>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{ marginTop: 55, marginLeft: 20 }}
                onClick={onChangeProfile}
              >
                MODIFICA PROFILO
              </Button>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <TableContainer component="div" style={{ marginTop: 0 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h4"
                        color="textSecondary"
                        style={{ marginBottom: 15 }}
                      >
                        Account
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Hidden mdUp>
                        <Button
                          variant="text"
                          size="small"
                          color="primary"
                          startIcon={<CreateIcon />}
                          onClick={onChangePassword}
                        >
                          MODIFICA PASSWORD
                        </Button>
                      </Hidden>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={classes.fixBaseline}
                      >
                        ID ACCOUNT
                        {userProfile.role === UserRole.PROFESSIONAL && '*'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.fixBaseline}
                      >
                        {userProfile.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={classes.fixBaseline}
                      >
                        PASSWORD
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.fixBaseline}
                      >
                        ********
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {userProfile.role === UserRole.PROFESSIONAL && (
              <>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  style={{ marginTop: '1em' }}
                >
                  * Non è possibile modificare l’ID account. Se ha necessità di
                  cambiarlo ci contatti.
                </Typography>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  style={{ marginTop: '1em' }}
                >
                  Per disiscriversi dall’Area Riservata inviare una mail a:{' '}
                  <a href="mailto:areariservata@bromatech.it">
                    areariservata@bromatech.it
                  </a>
                </Typography>
              </>
            )}
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{ marginTop: 55, marginLeft: 20 }}
                onClick={onChangePassword}
              >
                MODIFICA PASSWORD
              </Button>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
    </>
  )
}

export default ProfileDataTable
