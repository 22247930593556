import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import createPalette from '@material-ui/core/styles/createPalette'

const breakpoints = createBreakpoints({})
const palette = createPalette({})

export const colors = {
  primary: {
    main: '#2196F3',
    dark: '#0B79D0',
    light: '#BBDEFB',
  },
  text: {
    primary: 'rgba(0,0,0,1)',
    secondary: 'rgba(0,0,0,0.54)',
  },
  brand: '#003087',
  disabledBackground: 'rgba(0,0,0,0.12)',
}

// xs: 0,
// sm: 600,
// md: 960,
// lg: 1280,
// xl: 1920

const theme = createMuiTheme({
  spacing: 10,
  typography: {
    fontFamily: ['Hind', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '38px',
      fontWeight: 300,
      letterSpacing: '-0.59px',
      [breakpoints.up('md')]: {
        fontSize: '46px',
        letterSpacing: '-0.72px',
      },
    },
    h2: {
      fontSize: '31px',
      fontWeight: 500,
      lineHeight: '42px',
      letterSpacing: '-0.26px',
      [breakpoints.up('md')]: {
        fontSize: '36px',
        letterSpacing: '-0.3px',
      },
    },
    h3: {
      fontSize: '22px',
      fontWeight: 300,
      letterSpacing: '0px',
      lineHeight: '33px',
      [breakpoints.up('md')]: {
        fontSize: '22px',
        lineHeight: '33px',
        fontWeight: 300,
      },
    },
    h4: {
      fontSize: '24px',
      fontWeight: 600,
      letterSpacing: '0.18px',
      // [breakpoints.up('md')]: {
      //   fontSize: '24px',
      // },
    },
    h5: {
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0px',
      [breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
    h6: {
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: '0.4px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '26px',
      letterSpacing: '0.15px',
      // marginBottom: '1em',
      // [breakpoints.up('md')]: {
      //   fontSize: '15px',
      // },
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.15px',
      // [breakpoints.up('md')]: {
      //   fontSize: '15px',
      // },
    },
    caption: {
      fontSize: '13px',
      letterSpacing: '0.43px',
      lineHeight: '20px',
      fontWeight: 400,
      // [breakpoints.up('md')]: {
      // fontSize: '13px',
      //   fontSize: '15px',
      // },
    },
  },
  palette: {
    primary: {
      main: colors.primary.main,
      dark: colors.primary.dark,
      light: colors.primary.light,
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          marginBottom: 0,
          color: colors.text.primary,
          backgroundColor: 'rgba(245,245,245)',
        },
        li: {
          // listStyle: 'none',
          listStylePosition: 'inside',
          padding: 0,
          margin: 0,
        },
        ul: {
          padding: 0,
          margin: 0,
        },
        a: {
          color: colors.primary.main,
        },
        p: {
          marginBottom: '1em',
        },
        'input::placeholder': {
          color: `${colors.text.secondary} !important`,
          opacity: '1 !important',
        },
        '.Mui-error input::placeholder': {
          color: `${palette.error.main} !important`,
          opacity: '1 !important',
        },
        '.pdf-viewer-wrapper': {
          'text-align': 'center',
        },
      },
    },
    MuiBreadcrumbs: {
      li: {
        fontSize: 12,
        lineHeight: '20px',
        letterSpacing: '0.4px',
        fontWeight: 400,
      },
      separator: {
        marginLeft: 15,
        marginRight: 15,
      },
    },
    MuiCheckbox: { root: { top: -1 } },
    MuiButton: {
      root: {
        fontFamily: 'Roboto',
        minWidth: 143,
      },
    },
    MuiOutlinedInput: {
      root: {
        // '&:hover .MuiOutlinedInput-notchedOutline': {
        //   borderColor: colors.primary.main,
        // },
        // },
      },
      notchedOutline: {
        top: -7,
        '&:hover': {
          borderColor: 'green',
        },
      },
    },
    MuiContainer: {
      maxWidthMd: {
        paddingLeft: 15,
        paddingRight: 15,
        [breakpoints.up('md')]: {
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
      maxWidthLg: {
        paddingLeft: 15,
        paddingRight: 15,
        [breakpoints.up('md')]: {
          paddingLeft: 60,
          paddingRight: 60,
          maxWidth: 1260,
          [breakpoints.up('lg')]: {
            paddingLeft: 10,
            paddingRight: 10,
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: '0',
        // marginBottom: '0.25em',
      },
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: 912,
        margin: 15,
        [breakpoints.up('md')]: {
          margin: 32,
        },
      },
      paperWidthLg: {
        maxWidth: 1131,
        margin: '15px 0',
        width: '100%',
        // margin: 15,
        [breakpoints.up('md')]: {
          margin: 32,
        },
        [breakpoints.down('md')]: {
          '&.MuiDialog-paperScrollBody': {
            maxWidth: '100% !important',
          },
        },
      },
      paper: {
        padding: '65px 15px 15px 15px',
        [breakpoints.up('md')]: {
          padding: '65px 128px 60px 128px',
        },
      },
      paperFullWidth: {
        width: 'calc(100% - 30px)',
        [breakpoints.up('md')]: {
          width: 'calc(100% - 64px)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 16,
        color: colors.text.secondary,
        // color: 'green',
        letterSpacing: '0.15px',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 0,
        // marginBottom: 20,
        [breakpoints.up('md')]: {
          // marginBottom: 30,
          marginBottom: 0,
        },
      },
    },
    MuiTextField: {
      root: {
        marginBottom: 0,
        // marginBottom: 20,
        [breakpoints.up('md')]: {
          // marginBottom: 30,
          marginBottom: 0,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: 14,
        marginRight: 14,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },
    MuiGrid: {
      item: {
        paddingTop: '0 !important',
        // paddingBottom: '0 !important',
        paddingBottom: '20px !important',
        [breakpoints.up('md')]: {
          paddingBottom: '30px !important',
        },
      },
      container: {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
    },
    MuiDrawer: {
      paper: {
        width: 280,
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    MuiTablePagination: {
      select: {
        position: 'relative',
        top: 3,
      },
    },
    MuiTableCell: {
      sizeSmall: {
        paddingLeft: 0,
      },
    },
    MuiChip: {
      label: {
        position: 'relative',
        top: 1,
      },
    },
  },
})

export { theme }
