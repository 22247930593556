import React from 'react'
import { Typography } from '@material-ui/core'

export default function GDPR() {
  return (
    <>
      <Typography variant="caption" color="textSecondary" paragraph={true}>
        * I campi contrassegnati con l’asterisco sono obbligatori.
      </Typography>
    </>
  )
}
