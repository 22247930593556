import React from 'react'
import { Typography, Paper, makeStyles, Link } from '@material-ui/core'
import { getDocuments_getDocuments_documents } from 'types/generated/schemaTypes'
import { ReactComponent as NewSVG } from 'assets/new.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
    alignContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      padding: '24px 20px',
    },
  },
}))

interface DocumentItemProps {
  document: getDocuments_getDocuments_documents
}

const DocumentItem: React.FC<DocumentItemProps> = ({ document }) => {
  const classes = useStyles()
  console.log(document.url)
  return (
    <Paper className={classes.root}>
      {document.highlighted && (
        <NewSVG style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }} />
      )}
      <Link
        href={`/documenti/${document.id}`}
        style={{ textDecoration: 'none' }}
      >
        <img
          style={{ width: '100%', outline: 'solid black 1px' }}
          src={document.cover || ''}
          alt=""
        />
      </Link>
      <Link
        href={`/documenti/${document.id}`}
        color="textPrimary"
        style={{ textDecoration: 'none', color: 'inherit', marginTop: 20 }}
      >
        <Typography
          variant="h3"
          style={{ fontWeight: 600, lineHeight: '1.2em' }}
        >
          {document.title}
        </Typography>
      </Link>
    </Paper>
  )
}

export default DocumentItem
