import React, { useState } from 'react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  Tooltip,
  makeStyles,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core'
import {
  Create as CreateIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
} from '@material-ui/icons'
import {
  GET_CSV_PROFESSIONALS,
  GET_PENDING_PROFESSIONALS,
  GET_PROFESSIONAL,
} from 'query'
import { DELETE_PROFESSIONALS, SET_USERS_STATUS } from 'mutation'
import {
  deleteProfessionals,
  deleteProfessionalsVariables,
  setUsersStatus,
  setUsersStatusVariables,
  getCSVProfessionals,
  getCSVProfessionalsVariables,
  getPendingProfessionals,
  getPendingProfessionalsVariables,
  getProfessional,
  getProfessionalVariables,
  getProfessional_getUser as UserType,
  // updateProfessional_updateUser as UserType,
  UserRole,
  UserOrderByInput,
  UserWhereInput,
} from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  Title,
  UpdateProfessionalForm,
  DialogWarning,
  DialogDownload,
} from 'components'

const useStyles = makeStyles((theme) => ({
  search: {
    '& .MuiInputBase-root': {
      borderRadius: 20,
      backgroundColor: '#ffffff',
    },
  },
}))

export default function AttivazioneUtenti() {
  const classes = useStyles()
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [orderBy, setOrderBy] = useState<UserOrderByInput>(
    UserOrderByInput.CREATION_DESC
  )
  const [userVerificationStatus, setUserVerificationStatus] = useState<
    'ALL' | 'VERIFIED' | 'UNVERIFIED'
  >('ALL')
  const [action, setAction] = useState<'ACTIVATE' | 'DELETE' | 'DOWNLOAD' | ''>(
    ''
  )
  const [currentUser, setCurrentUser] = useState<UserType | null>(null)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [showUpdateUserForm, setShowUpdateUserForm] = useState(false)
  const [actionCallback, setActionCallback] = useState<() => () => void>(
    () => () => {}
  )
  const [showWarning, setShowWarning] = useState(false)
  const [showDownload, setShowDownload] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [searchString, setSearchString] = useState('')
  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10))
    setOffset(0)
  }

  const where: UserWhereInput = { active: false, role: UserRole.PROFESSIONAL }

  switch (userVerificationStatus) {
    case 'VERIFIED':
      where.verified = true
      break
    case 'UNVERIFIED':
      where.verified = false
      break
  }
  if (searchString.length > 2) where.searchPattern = searchString

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
    refetch: refetchPending,
  } = useQuery<getPendingProfessionals, getPendingProfessionalsVariables>(
    GET_PENDING_PROFESSIONALS,
    {
      fetchPolicy: 'network-only',
      variables: {
        where,
        orderby: orderBy,
        offset,
        limit,
      },
    }
  )
  const [getCSVProfessionalQuery] = useLazyQuery<
    getCSVProfessionals,
    getCSVProfessionalsVariables
  >(GET_CSV_PROFESSIONALS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getCSVProfessionals }) => {
      setDownloadUrl(getCSVProfessionals || '')
      setShowDownload(true)
    },
  })
  const [getProfessionalQuery] = useLazyQuery<
    getProfessional,
    getProfessionalVariables
  >(GET_PROFESSIONAL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getUser }) => {
      setCurrentUser(getUser)
      setShowUpdateUserForm(true)
    },
  })

  const [deleteProfessionalsMutation, { loading: deleteLoading }] = useMutation<
    deleteProfessionals,
    deleteProfessionalsVariables
  >(DELETE_PROFESSIONALS, {
    onCompleted: async (data) => {
      setOffset(0)
      await refetchPending()
      setShowWarning(false)
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const [
    setUsersStateMutation,
    { loading: setUsersStatusLoading },
  ] = useMutation<setUsersStatus, setUsersStatusVariables>(SET_USERS_STATUS, {
    onCompleted: async (data) => {
      setOffset(0)
      await refetchPending()
      setShowWarning(false)
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const handleUpdate = async (id: string) => {
    getProfessionalQuery({
      variables: { userId: id },
    })
  }

  const handleDelete = (id: string) => {
    setShowWarning(true)
    setActionCallback(() => async () => {
      await deleteProfessionalsMutation({ variables: { users: [id] } })
      setSelectedUsers([...selectedUsers.filter((user) => user !== id)])
    })
  }

  const handleDeleteMultiple = () => {
    setShowWarning(true)
    setActionCallback(() => async () => {
      await deleteProfessionalsMutation({ variables: { users: selectedUsers } })
      setSelectedUsers([])
    })
  }

  const handleActivateMultiple = () => {
    setShowWarning(true)
    setActionCallback(() => async () => {
      await setUsersStateMutation({
        variables: { users: selectedUsers, active: true },
      })
      setOffset(0)
      setSelectedUsers([])
    })
  }

  const handleAction = () => {
    if (selectedUsers.length === 0) return
    switch (action) {
      case 'ACTIVATE':
        handleActivateMultiple()
        break
      case 'DELETE':
        handleDeleteMultiple()
        break
      case 'DOWNLOAD':
        getCSVProfessionalQuery({ variables: { ids: selectedUsers } })
        break
    }
  }

  return (
    <>
      <DialogDownload
        open={showDownload}
        onClose={() => setShowDownload(false)}
        title="Download CSV Utenti"
      >
        <Typography variant="h3">
          Il file csv è stato generato correttamente.{' '}
          <a href={downloadUrl} target="_blank" rel="noreferrer">
            Clicca qui
          </a>{' '}
          per scaricarlo.
        </Typography>
      </DialogDownload>
      <UpdateProfessionalForm
        user={currentUser}
        open={showUpdateUserForm}
        onClose={() => {
          setShowUpdateUserForm(false)
        }}
        onSuccess={() => {
          setShowUpdateUserForm(false)
        }}
      />
      <DialogWarning
        onContinue={() => actionCallback()}
        onCancel={() => {
          setShowWarning(false)
        }}
        open={showWarning}
        disabled={deleteLoading || setUsersStatusLoading}
        title="Attenzione!"
      >
        <Typography variant="h3">Sei sicuro di voler procedere?</Typography>
      </DialogWarning>
      <Title title="Utenti inattivi" />
      <Breadcrumb current="Utenti inattivi" />
      <div>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={orderBy}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setOrderBy(e.target.value as UserOrderByInput)
                }}
              >
                <MenuItem value={UserOrderByInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={UserOrderByInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={UserOrderByInput.LASTNAME_DESC}>
                  Cognome ↓
                </MenuItem>
                <MenuItem value={UserOrderByInput.LASTNAME_ASC}>
                  Cognome ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="order-label">Visualizza</InputLabel>
              <Select
                name="status"
                value={userVerificationStatus}
                labelId="status-label"
                id="status"
                label="Visualizza"
                onChange={(e) => {
                  setOffset(0)
                  setUserVerificationStatus(
                    e.target.value as 'ALL' | 'VERIFIED' | 'UNVERIFIED'
                  )
                }}
              >
                <MenuItem value="ALL">Tutti gli utenti</MenuItem>
                <MenuItem value="VERIFIED">Utenti già verificati</MenuItem>
                <MenuItem value="UNVERIFIED">Utenti da verificare</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl
              fullWidth={true}
              variant="outlined"
              size="small"
              className={classes.search}
            >
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <OutlinedInput
                name="searchString"
                id="searchString"
                label="Cerca"
                autoFocus={searchString.length > 2}
                // type="search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                // disabled={usersLoading}
                endAdornment={
                  <InputAdornment position="end">
                    {/* <IconButton
                      aria-label="search"
                      onClick={() => {}}
                      edge="end"
                    > */}
                    <SearchIcon />
                    {/* </IconButton> */}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            container
            alignItems="flex-start"
            justify="flex-start"
          >
            <FormControl
              fullWidth={false}
              variant="outlined"
              size="small"
              color="primary"
              style={{ marginRight: 15 }}
            >
              <InputLabel id="actions-label">Azioni di gruppo</InputLabel>
              <Select
                style={{ minWidth: 175 }}
                name="actions"
                value={action}
                labelId="actions-label"
                id="actions"
                label="Azioni di gruppo"
                onChange={(e) => {
                  setOffset(0)
                  setAction(
                    e.target.value as 'ACTIVATE' | 'DELETE' | 'DOWNLOAD'
                  )
                }}
              >
                <MenuItem value="ACTIVATE">Attiva</MenuItem>
                <MenuItem value="DELETE">Elimina</MenuItem>
                <MenuItem value="DOWNLOAD">Genera CSV</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              fullWidth={false}
              disabled={action === '' || selectedUsers.length === 0}
              size="large"
              color="primary"
              style={{ minWidth: 'initial' }}
              onClick={handleAction}
            >
              Esegui
            </Button>
          </Grid>
        </Grid>
      </div>
      {usersLoading && <div>Caricamento degli utenti in corso</div>}
      {!usersLoading && !usersError && usersData && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head" align="center">
                    Azioni
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Nome
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Cognome
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Email
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Verificato
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersData.getUsers.users.map((user) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell align="center">
                        <div style={{ minWidth: 140 }}>
                          <Checkbox
                            color="primary"
                            checked={selectedUsers.includes(user.id)}
                            onChange={(e) => {
                              e.target.checked
                                ? setSelectedUsers([...selectedUsers, user.id])
                                : setSelectedUsers([
                                    ...selectedUsers.filter(
                                      (id) => id !== user.id
                                    ),
                                  ])
                            }}
                          />
                          <Tooltip title="Modifica">
                            <IconButton onClick={() => handleUpdate(user.id)}>
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Elimina">
                            <IconButton onClick={() => handleDelete(user.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: user.blocked ? 'red' : 'inherit' }}
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: user.blocked ? 'red' : 'inherit' }}
                      >
                        {user.lastname}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: user.blocked ? 'red' : 'inherit' }}
                      >
                        {user.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: user.blocked ? 'red' : 'inherit' }}
                      >
                        {user.details?.verified ? 'SI' : 'NO'}{' '}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Totale utenti per pagina"
            labelDisplayedRows={({ from, to, count }) =>
              `Utenti ${from}-${to} di ${count}`
            }
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={usersData ? usersData.getUsers.total : 0}
            rowsPerPage={limit}
            page={Math.floor(offset / limit)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  )
}
