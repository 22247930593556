import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { colors } from 'theme'
import { ReactComponent as CloseSVG } from 'assets/close.svg'

const useStyles = makeStyles((theme) => ({
  headerDesktop: {
    display: 'none',
    backgroundImage: 'url("bg-header.jpeg")',
    backgroundPosition: '50% 0',
    backgroundSize: 'cover',
    boxSizing: 'content-box',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  headerDesktopContainer: {
    maxWidth: 1400,
    height: 266,
    padding: '0 80px',
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto',
    alignItems: 'center',
  },
  headerDesktopContainerLeft: {
    width: '66.67%',
  },
  headerDesktopContainerRight: {
    width: '33.33%',
  },
  whiteLayer: {
    zIndex: 1,
    background:
      'linear-gradient(to right, rgba(62,183,160,1) 0%,rgba(62,183,160,0.5) 100%)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  menuItemDesktop: {
    listStyle: 'none',
    fontSize: 16,
    '& a': {
      letterSpacing: 'normal',
      lineHeight: '26px',
      boxSizing: 'content-box',
      color: 'white',
      display: 'inline-block',
      padding: '8px 0px',
      fontWeight: 500,
      transition: '.1s ease-in-out',
      transitionProperty: 'color,background-color,border-color,box-shadow',
      borderRadius: 2,
      position: 'relative',
      textDecoration: 'none',
      '&:hover::before': {
        width: 0,
      },
      '&::before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        height: 1,
        bottom: 0,
        left: 0,
        backgroundColor: '#ffffff',
        width: '100%',
        transition: 'all 0.3s ease-in-out',
      },
    },
    '& a::before': {
      display: 'block',
      content: '',
      position: 'absolute',
      height: 1,
      bottom: 0,
      left: 0,
      backgroundColor: '#ffffff',
      width: '100%',
      transition: 'all 0.3s ease-in-out',
    },
  },
  headerMobile: {
    height: 102,
    borderBottom: 'solid 2px #e8e8e8',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 15px',
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  openMenu: {
    color: colors.brand,
    appearance: 'none',
    border: 'none',
    background: 'none',
    fontSize: 18,
    fontWeight: 500,
    '&:focus': {
      outline: 'none',
    },
  },
  closeMenu: {
    padding: 0,
    position: 'absolute',
    // color: color.brand,
    appearance: 'none',
    border: 'none',
    background: 'none',
    // fontSize: 18,
    // fontWeight: 500,
    top: 20,
    right: 20,
    '&:focus': {
      outline: 'none',
    },
  },
  spacer: {
    height: 152,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenu: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    zIndex: theme.zIndex.appBar,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity .15s linear',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenuOpen: {
    pointerEvents: 'auto',
    opacity: 1,
  },
  menuItemMobile: {
    backgroundSize: 'cover',
    '& a': {
      display: 'block',
      textAlign: 'right',
      padding: 30,
      fontSize: 36,
      fontWeight: 600,
      color: '#ffffff',
      position: 'relative',
      textDecoration: 'none',
    },
  },
}))

export default function HomeHeader() {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const classes = useStyles()
  return (
    <>
      <header className={classes.headerMobile}>
        <div>
          <a href="https://www.bromatech.it/">
            <img
              width="200"
              height="48"
              src="logo_bromatech_esteso.svg"
              alt="Bromatech"
            />
          </a>
        </div>
        <div>
          <button
            className={classes.openMenu}
            onClick={() => setIsOpenMenu(true)}
          >
            MENU
          </button>
        </div>
      </header>
      <div className={classes.spacer}></div>
      <div
        className={clsx(
          classes.mobileMenu,
          isOpenMenu ? classes.mobileMenuOpen : null
        )}
      >
        <button
          className={classes.closeMenu}
          onClick={() => setIsOpenMenu(false)}
        >
          <CloseSVG />
        </button>
        <nav style={{ marginTop: 60 }}>
          <ul>
            <li
              className={classes.menuItemMobile}
              style={{
                backgroundImage: 'url("001-04.jpg")',
                listStyle: 'none',
              }}
            >
              <a
                style={{
                  background:
                    'linear-gradient(to right, rgba(255,157,55,0.5) 0%,rgba(255,157,55,1) 100%)',
                }}
                href="https://www.bromatech.it/prodotti"
              >
                Prodotti
              </a>
            </li>
            <li
              className={classes.menuItemMobile}
              style={{
                backgroundImage: 'url("001-07.jpg")',
                listStyle: 'none',
              }}
            >
              <a
                style={{
                  background:
                    'linear-gradient(to right, rgba(62,183,160,0.5) 0%,rgba(62,183,160,1) 100%)',
                }}
                href="https://www.bromatech.it/approfondimenti"
              >
                Approfondimenti
              </a>
            </li>
            <li
              className={classes.menuItemMobile}
              style={{
                backgroundImage: 'url("001-02.jpg")',
                listStyle: 'none',
              }}
            >
              <a href="https://www.bromatech.it/contatti">Contatti</a>
            </li>
          </ul>
        </nav>
      </div>
      <header className={classes.headerDesktop}>
        <div className={classes.whiteLayer}></div>
        <div className={classes.headerDesktopContainer}>
          <div className={classes.headerDesktopContainerLeft}>
            <a href="https://www.bromatech.it/">
              <img
                width="450"
                height="108"
                src="logo_bromatech_esteso_white.svg"
                alt="Bromatech"
              />
            </a>
          </div>
          <nav className={classes.headerDesktopContainerRight}>
            <ul>
              <li className={classes.menuItemDesktop}>
                <a href="https://www.bromatech.it/prodotti">PRODOTTI</a>
              </li>
              <li className={classes.menuItemDesktop}>
                <a href="https://www.bromatech.it/approfondimenti">
                  APPROFONDIMENTI
                </a>
              </li>
              <li className={classes.menuItemDesktop}>
                <a href="https://www.bromatech.it/contatti">CONTATTI</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}
