import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  from,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import RouteManager from './routeManager'
import { cache, isLoggedInVar } from './cache'
import { typeDefs } from './typedefs'
import { theme } from './theme'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) console.log(`[Network error]: ${networkError}`)
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (extensions && extensions.code) {
        console.log(
          `[GraphQL error]: Message: ${message} Code: ${extensions.code}`
        )
        // handle errors differently based on its error code
        switch (extensions.code) {
          case 'UNAUTHENTICATED':
            localStorage.removeItem('b_token')
            isLoggedInVar(false)
            break
          default:
            break
        }
      }
    })
  }
})

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_ENDPOINT}/graphql`,
  credentials: 'include',
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('b_token')
  operation.setContext({
    headers: {
      authorization: token ? `Bearer: ${token}` : '',
    },
  })
  return forward(operation)
})

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: from([authMiddleware, errorLink, httpLink]),
  resolvers: {},
  typeDefs,
})

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <RouteManager />
          <CssBaseline />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
