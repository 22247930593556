import React, { useState } from 'react'
import {
  useApolloClient,
  useMutation,
  useQuery,
  useLazyQuery,
} from '@apollo/client'
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  makeStyles,
  useTheme,
  Tooltip,
} from '@material-ui/core'
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Create as CreateIcon,
  Visibility as PreviewIcon,
  Close,
  GetApp as DownloadIcon,
  Link as LinkIcon,
} from '@material-ui/icons'
import { GET_CSV_EVENT, GET_EVENTS, GET_EVENT } from 'query'
import { DELETE_EVENTS } from 'mutation'
import {
  deleteMedicalEvents,
  deleteMedicalEventsVariables,
  getCSVEvent,
  getCSVEventVariables,
  getMedicalEvent,
  getMedicalEvent_getMedicalEvent,
  getMedicalEventVariables,
  getMedicalEvents,
  getMedicalEventsVariables,
  MedicalEventOrderByInput,
  MedicalEventWhereInput,
  MedicalEventStatus,
  MedicalEventType,
} from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  Title,
  DialogWarning,
  DialogDownload,
  EventForm,
  EventItem,
  FiltersWrapper,
} from 'components'
import { utcToZonedTime } from 'date-fns-tz'
// import

const useStyle = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  preview: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        padding: '65px 105px 60px 105px',
      },
    },
  },
}))

export default function GestioneUtenti() {
  const client = useApolloClient()
  const classes = useStyle()
  const theme = useTheme()
  const [
    currentEvent,
    setCurrentEvent,
  ] = useState<null | getMedicalEvent_getMedicalEvent>(null)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [orderBy, setOrderBy] = useState<MedicalEventOrderByInput>(
    MedicalEventOrderByInput.DATE_DESC
  )
  const [status, setStatus] = useState<MedicalEventStatus | 'ALL'>('ALL')
  const [type, setType] = useState<MedicalEventType | 'ALL'>('ALL')
  const [showEventPreview, setShowEventPreview] = useState(false)
  const [showEventForm, setShowEventForm] = useState(false)
  const [actionCallback, setActionCallback] = useState<() => () => void>(
    () => () => {}
  )
  const [downloadUrl, setDownloadUrl] = useState('')
  const [showDownload, setShowDownload] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10))
    setOffset(0)
  }

  const where: MedicalEventWhereInput = {}

  if (status !== 'ALL') {
    where.status = status
  }

  if (type !== 'ALL') {
    where.type = type
  }

  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: refetchEvents,
  } = useQuery<getMedicalEvents, getMedicalEventsVariables>(GET_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderby: orderBy,
      offset,
      limit,
    },
  })

  const [getCSVEventQuery] = useLazyQuery<getCSVEvent, getCSVEventVariables>(
    GET_CSV_EVENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getCSVEvent }) => {
        setDownloadUrl(getCSVEvent || '')
        setShowDownload(true)
      },
    }
  )

  // const [getEventQuery, { data: eventData }] = useLazyQuery<
  //   getMedicalEvent,
  //   getMedicalEventVariables
  // >(GET_EVENT, {
  //   onCompleted: (data) => {
  //     console.log('on completed')
  //     setCurrentEvent(data.getMedicalEvent)
  //     setShowEventForm(true)
  //   },
  //   fetchPolicy: 'cache-only',
  // })

  const [deleteMedicalEventsMutation, { loading: deleteLoading }] = useMutation<
    deleteMedicalEvents,
    deleteMedicalEventsVariables
  >(DELETE_EVENTS, {
    onCompleted: async (data) => {
      setOffset(0)
      await refetchEvents({
        where,
        orderby: orderBy,
        offset,
        limit,
      })
      setShowWarning(false)
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const handleDelete = (id: string) => {
    setShowWarning(true)
    setActionCallback(() => async () => {
      await deleteMedicalEventsMutation({ variables: { ids: [id] } })
    })
  }

  const handleUpdate = async (id: string) => {
    const data = await client.query<getMedicalEvent, getMedicalEventVariables>({
      query: GET_EVENT,
      variables: { id },
      fetchPolicy: 'cache-first',
    })
    setCurrentEvent(data.data.getMedicalEvent)
    setShowEventForm(true)
  }

  const handlePreview = async (id: string) => {
    const data = await client.query<getMedicalEvent, getMedicalEventVariables>({
      query: GET_EVENT,
      variables: { id },
      fetchPolicy: 'cache-first',
    })
    setCurrentEvent(data.data.getMedicalEvent)
    setShowEventPreview(true)
  }

  const handleDownload = async (id: string) => {
    getCSVEventQuery({ variables: { id } })
  }

  const handleLink = async (id: string) => {
    navigator.clipboard
      .writeText(`https://professionisti.bromatech.it/eventi/${id}`)
      .then(
        () => {
          alert('Link copiato negli appunti')
        },
        () => {
          /* clipboard write failed */
        }
      )
  }

  return (
    <>
      <EventForm
        open={showEventForm}
        onClose={() => {
          setShowEventForm(false)
        }}
        onCreate={async () => {
          setShowEventForm(false)
          await refetchEvents({
            where,
            orderby: orderBy,
            offset,
            limit,
          })
        }}
        onUpdate={async () => {
          setShowEventForm(false)
          // await refetchEvents()
        }}
        event={currentEvent}
      />

      {/* <Fab
        color="primary"
        aria-label="add"
        style={{ position: 'fixed', bottom: 20, right: 20 }}
        onClick={() => {
          setCurrentEvent(null)
          setShowEventForm(true)
        }}
      >
        <AddIcon />
      </Fab> */}

      {showEventPreview && currentEvent && (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          className={classes.preview}
          scroll="body"
          open={showEventPreview}
          onClose={() => {
            setShowEventPreview(false)
          }}
        >
          <IconButton
            aria-label="chiudi"
            className={classes.closeButton}
            onClick={() => {
              setShowEventPreview(false)
            }}
          >
            <Close htmlColor={theme.palette.text.primary} />
          </IconButton>
          <EventItem event={currentEvent} preview={true} canRegister={true} />
        </Dialog>
      )}

      <DialogWarning
        onContinue={() => actionCallback()}
        onCancel={() => {
          setShowWarning(false)
        }}
        open={showWarning}
        disabled={deleteLoading}
        title="Attenzione!"
      >
        <Typography variant="h3">Sei sicuro di voler procedere?</Typography>
      </DialogWarning>
      <Title title="Gestione eventi" />
      <Breadcrumb current="Gestione eventi" />
      <FiltersWrapper>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={orderBy}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setOrderBy(e.target.value as MedicalEventOrderByInput)
                }}
              >
                <MenuItem value={MedicalEventOrderByInput.DATE_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={MedicalEventOrderByInput.DATE_ASC}>
                  Data creazione ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="status-label">Stato evento</InputLabel>
              <Select
                name="status"
                value={status}
                labelId="status-label"
                id="status"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setStatus(e.target.value as MedicalEventStatus | 'ALL')
                }}
              >
                <MenuItem value={'ALL'}>Tutti gli stati</MenuItem>
                <MenuItem value={MedicalEventStatus.ARCHIVED}>
                  Eventi archiviati
                </MenuItem>
                <MenuItem value={MedicalEventStatus.DRAFT}>
                  Eventi in bozza
                </MenuItem>
                <MenuItem value={MedicalEventStatus.PUBLISHED}>
                  Eventi pubblicati
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="type-label">Tipologia</InputLabel>
              <Select
                name="type"
                value={type}
                labelId="type-label"
                id="type"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setType(e.target.value as MedicalEventType | 'ALL')
                }}
              >
                <MenuItem value={'ALL'}>Tutti le tipologie</MenuItem>
                <MenuItem value={MedicalEventType.WEBINAR}>Webinar</MenuItem>
                <MenuItem value={MedicalEventType.CLASSROOM}>
                  In presenza
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <Button
              color="primary"
              variant="contained"
              // style={{ position: 'fixed', bottom: 20, right: 20 }}
              onClick={() => {
                setCurrentEvent(null)
                setShowEventForm(true)
              }}
              endIcon={<AddIcon />}
            >
              Crea un nuovo evento
            </Button>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {eventsLoading && <div>Caricamento degli eventi in corso</div>}
      {!eventsLoading && !eventsError && eventsData && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    variant="head"
                    align="center"
                    style={{ minWidth: 230 }}
                  >
                    Azionia
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Titolo
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Data
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Tipologia
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Stato
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Accetta
                    <br />
                    iscrizioni
                  </TableCell>
                  <TableCell
                    variant="head"
                    align="center"
                    style={{ minWidth: 100 }}
                  >
                    Iscritti
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventsData.getMedicalEvents.events.map((event) => {
                  const start = utcToZonedTime(
                    new Date(event.dates[0].start),
                    'Europe/Rome'
                  )
                  return (
                    <TableRow key={event.id}>
                      <TableCell align="center">
                        <Tooltip title="Elimina">
                          <IconButton onClick={() => handleDelete(event.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Modifica">
                          <IconButton onClick={() => handleUpdate(event.id)}>
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Anteprima">
                          <IconButton onClick={() => handlePreview(event.id)}>
                            <PreviewIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Scarica CSV">
                          <IconButton
                            onClick={() => {
                              handleDownload(event.id)
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Link condivisione">
                          <IconButton
                            onClick={() => {
                              handleLink(event.id)
                            }}
                          >
                            <LinkIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={event.title}>
                          <span>
                            {event.title.substring(0, 40)}
                            {event.title.length > 40 && '...'}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{`${start.getDate()}/${
                        start.getMonth() + 1
                      }/${start.getFullYear()}`}</TableCell>
                      <TableCell align="center" style={{ textAlign: 'center' }}>
                        {event.type}
                        {event.type === MedicalEventType.CLASSROOM && (
                          <>
                            {event.city && (
                              <>
                                <br />
                                {event.city}
                              </>
                            )}
                            {event.agent && (
                              <>
                                <br />
                                {event.agent}
                              </>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell align="center">{event.status}</TableCell>
                      <TableCell align="center">
                        {event.open ? 'SI' : 'NO'}
                      </TableCell>
                      <TableCell align="center">
                        ({event.usersTotal} /{' '}
                        {event.type === MedicalEventType.CLASSROOM
                          ? event.usersMax
                          : '∞'}
                        )
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Totale eventi per pagina"
            labelDisplayedRows={({ from, to, count }) =>
              `Utenti ${from}-${to} di ${count}`
            }
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={eventsData ? eventsData.getMedicalEvents.total : 0}
            rowsPerPage={limit}
            page={Math.floor(offset / limit)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}

      <DialogDownload
        open={showDownload}
        onClose={() => setShowDownload(false)}
        title="Download CSV Iscritti"
      >
        <Typography variant="h3">
          Il file csv è stato generato correttamente.{' '}
          <a href={downloadUrl} target="_blank" rel="noreferrer">
            Clicca qui
          </a>{' '}
          per scaricarlo.
        </Typography>
      </DialogDownload>
    </>
  )
}
