import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import {
  AccountCircle as AccountCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  Person as PersonIcon,
} from '@material-ui/icons'
import { ME } from 'query'
import { me as MeType } from 'types/generated/schemaTypes'
import { isLoggedInVar } from 'cache'
import { colors } from 'theme'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  root: {
    background: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  menuLabel: {
    fontSize: 12,
    color: colors.text.secondary,
    fontWeight: 400,
    letterSpacing: '0.13px',
  },
  menuIcon: {
    minWidth: 0,
    marginRight: 15,
  },
}))

const ApplicationBar: React.FC<{ onOpenMenu: () => void }> = ({
  onOpenMenu,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const menuAnchor = useRef(null)
  const { data: meData, loading: loadingMeData } = useQuery<MeType, null>(ME, {
    fetchPolicy: 'network-only',
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(menuAnchor.current)
  }
  const desktopLayout = useMediaQuery(theme.breakpoints.up('lg'))
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    localStorage.removeItem('b_role')
    localStorage.removeItem('b_userId')
    localStorage.removeItem('b_token')
    isLoggedInVar(false)
  }
  return (
    <AppBar elevation={1} position="fixed" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!desktopLayout && (
            <IconButton aria-label="open menu" onClick={onOpenMenu}>
              <MenuIcon htmlColor={colors.brand} />
            </IconButton>
          )}
          <a href="/">
            <img
              alt="Bromatech"
              src="/logo_bromatech_esteso.svg"
              width={150}
              height={28}
            />
          </a>
        </div>

        {loadingMeData && !meData?.me ? null : (
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            ref={menuAnchor}
          >
            <IconButton onClick={handleClick}>
              <AccountCircleIcon color="primary" />
            </IconButton>
            {desktopLayout && (
              <>
                <Typography
                  style={{ cursor: 'pointer', position: 'relative', top: 1 }}
                  variant="caption"
                  color="primary"
                  onClick={handleClick}
                >
                  {meData?.me?.name} {meData?.me?.lastname}
                </Typography>
                <IconButton onClick={handleClick}>
                  <KeyboardArrowDownIcon color="primary" />
                </IconButton>
              </>
            )}
          </div>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => history.push('/profilo')}
            style={{ minWidth: 180 }}
          >
            <ListItemIcon className={classes.menuIcon}>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              disableTypography={true}
              className={classes.menuLabel}
            >
              Profilo
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={logout} style={{ minWidth: 180 }}>
            <ListItemIcon className={classes.menuIcon}>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              disableTypography={true}
              className={classes.menuLabel}
            >
              Esci
            </ListItemText>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default ApplicationBar
