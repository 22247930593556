import { gql } from '@apollo/client'

const ME = gql`
  query me {
    me {
      id
      email
      role
      name
      lastname
    }
  }
`

const GET_PENDING_PROFESSIONALS = gql`
  query getPendingProfessionals(
    $where: UserWhereInput!
    $orderby: UserOrderByInput!
    $offset: Int!
    $limit: Int!
  ) {
    getUsers(where: $where, orderby: $orderby, offset: $offset, limit: $limit) {
      total
      users {
        id
        name
        lastname
        email
        active
        blocked
        details {
          verified
        }
      }
    }
  }
`

const GET_ACTIVE_PROFESSIONALS = gql`
  query getActiveProfessionals(
    $where: UserWhereInput!
    $orderby: UserOrderByInput!
    $offset: Int!
    $limit: Int!
  ) {
    getUsers(where: $where, orderby: $orderby, offset: $offset, limit: $limit) {
      total
      users {
        id
        name
        lastname
        email
        active
        blocked
        details {
          verified
        }
      }
    }
  }
`

const GET_PROFESSIONAL = gql`
  query getProfessional($userId: ID!) {
    getUser(id: $userId) {
      id
      email
      role
      active
      name
      lastname
      details {
        verified
        addresses {
          addressLine
          zip
          city
          province
          region
        }
        profession
        specialization
        customProfession
        customSpecialization
        businessName
        businessRole
        marketing
        extra
        agent
        gender
        birthDate
        phone
        fiscalCode
        registerId
      }
    }
  }
`

const GET_PROFILE = gql`
  query getProfile($userId: ID!) {
    getUser(id: $userId) {
      id
      email
      role
      name
      lastname
      details {
        addresses {
          addressLine
          zip
          city
          province
          region
        }
        profession
        specialization
        customProfession
        customSpecialization
        businessName
        businessRole
        marketing
        extra
        agent
        gender
        birthDate
        phone
        fiscalCode
        registerId
      }
    }
  }
`

const GET_OPERATORS = gql`
  query getOperators(
    $where: UserWhereInput!
    $orderby: UserOrderByInput!
    $offset: Int!
    $limit: Int!
  ) {
    getUsers(where: $where, orderby: $orderby, offset: $offset, limit: $limit) {
      total
      users {
        id
        name
        lastname
        email
      }
    }
  }
`

const GET_DOCUMENTS = gql`
  query getDocuments(
    $where: DocumentWhereInput
    $orderby: DocumentOrderByInput
    $offset: Int
    $limit: Int
  ) {
    getDocuments(
      where: $where
      orderby: $orderby
      offset: $offset
      limit: $limit
    ) {
      total
      documents {
        id
        title
        cover
        url
        highlighted
      }
    }
  }
`

const GET_DOCUMENT = gql`
  query getDocument($id: ID!) {
    getDocument(id: $id) {
      id
      title
      cover
      url
      highlighted
    }
  }
`

const GET_OPERATOR = gql`
  query getOperator($id: ID!) {
    getUser(id: $id) {
      id
      name
      lastname
      email
    }
  }
`

const GET_CSV_PROFESSIONALS = gql`
  query getCSVProfessionals($ids: [ID!]!) {
    getCSVProfessionals(ids: $ids)
  }
`

const GET_CSV_PROFESSIONALS_ALL = gql`
  query getCSVProfessionalsAll {
    getCSVProfessionalsAll
  }
`

const GET_CSV_EVENT = gql`
  query getCSVEvent($id: ID!) {
    getCSVEvent(id: $id)
  }
`

const GET_EVENTS = gql`
  query getMedicalEvents(
    $where: MedicalEventWhereInput!
    $orderby: MedicalEventOrderByInput!
    $offset: Int!
    $limit: Int!
  ) {
    getMedicalEvents(
      where: $where
      orderby: $orderby
      offset: $offset
      limit: $limit
    ) {
      total
      events {
        id
        title
        tagline
        registrationDetails
        registrationDetails2
        registrationConfirmation
        webinarLink
        googleMapsLink
        address
        location
        city
        agent
        cover
        schedule
        curriculum
        attachment1
        attachment2
        attachmentTitle1
        attachmentTitle2
        speakers
        dates {
          start
          end
        }
        usersTotal
        usersMax
        open
        listed
        hideEmail
        type
        status
        deadline
        highlighted
        area
      }
    }
  }
`

const GET_EVENT = gql`
  query getMedicalEvent($id: ID!) {
    getMedicalEvent(id: $id) {
      id
      title
      tagline
      registrationDetails
      registrationDetails2
      registrationConfirmation
      webinarLink
      googleMapsLink
      address
      location
      city
      agent
      cover
      schedule
      curriculum
      attachment1
      attachment2
      attachmentTitle1
      attachmentTitle2
      speakers
      dates {
        start
        end
      }
      usersTotal
      usersMax
      open
      listed
      hideEmail
      type
      area
      status
      deadline
      highlighted
    }
  }
`

const GET_SIGNED_URL = gql`
  query getSignedUrl($media: MediaType!, $filename: String!) {
    getSignedUrl(media: $media, filename: $filename) {
      uploadUrl
      finalUrl
    }
  }
`

const MY_EVENTS = gql`
  query myEvents($id: ID!) {
    getUser(id: $id) {
      events {
        id
        title
        type
        dates {
          start
          end
        }
      }
    }
  }
`

const SEND_REQUEST_INNER = gql`
  query sendContactRequestInner(
    $userId: ID!
    $subject: String!
    $message: String!
  ) {
    sendContactRequestInner(
      userId: $userId
      subject: $subject
      message: $message
    ) {
      message
    }
  }
`

const SEND_REQUEST_OUTER = gql`
  query sendContactRequestOuter($request: RequestInput!) {
    sendContactRequestOuter(request: $request) {
      message
    }
  }
`

export {
  GET_ACTIVE_PROFESSIONALS,
  GET_CSV_EVENT,
  GET_CSV_PROFESSIONALS,
  GET_CSV_PROFESSIONALS_ALL,
  GET_DOCUMENT,
  GET_DOCUMENTS,
  GET_EVENT,
  GET_EVENTS,
  GET_OPERATOR,
  GET_OPERATORS,
  GET_PENDING_PROFESSIONALS,
  GET_PROFESSIONAL,
  GET_PROFILE,
  GET_SIGNED_URL,
  ME,
  MY_EVENTS,
  SEND_REQUEST_INNER,
  SEND_REQUEST_OUTER,
}
