import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons'
import { GET_OPERATORS } from 'query'
import { DELETE_OPERATORS } from 'mutation'
import {
  getOperators,
  getOperatorsVariables,
  deleteOperators,
  deleteOperatorsVariables,
  UserRole,
  UserOrderByInput,
  UserWhereInput,
} from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  Title,
  DialogWarning,
  CreateOperatorForm,
  FiltersWrapper,
} from 'components'

export default function GestioneUtenti() {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [orderBy, setOrderBy] = useState<UserOrderByInput>(
    UserOrderByInput.CREATION_DESC
  )
  const [showCreateOperatorForm, setShowCreateOperatorForm] = useState(false)
  const [actionCallback, setActionCallback] = useState<() => () => void>(
    () => () => {}
  )
  const [showWarning, setShowWarning] = useState(false)
  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10))
    setOffset(0)
  }

  const where: UserWhereInput = { role: UserRole.OPERATOR }

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
    refetch: refetchUsers,
  } = useQuery<getOperators, getOperatorsVariables>(GET_OPERATORS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderby: orderBy,
      offset,
      limit,
    },
  })

  const [deleteOperatorsMutation, { loading: deleteLoading }] = useMutation<
    deleteOperators,
    deleteOperatorsVariables
  >(DELETE_OPERATORS, {
    onCompleted: async (data) => {
      setOffset(0)
      await refetchUsers()
      setShowWarning(false)
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const handleDelete = (id: string) => {
    setShowWarning(true)
    setActionCallback(() => async () => {
      await deleteOperatorsMutation({ variables: { users: [id] } })
    })
  }

  return (
    <>
      <CreateOperatorForm
        open={showCreateOperatorForm}
        onClose={() => {
          setShowCreateOperatorForm(false)
        }}
        onSuccess={async () => {
          setShowCreateOperatorForm(false)
          await refetchUsers()
        }}
      />

      <Fab
        color="primary"
        aria-label="add"
        style={{ position: 'fixed', bottom: 20, right: 20 }}
        onClick={() => {
          setShowCreateOperatorForm(true)
        }}
      >
        <AddIcon />
      </Fab>
      <DialogWarning
        onContinue={() => actionCallback()}
        onCancel={() => {
          setShowWarning(false)
        }}
        open={showWarning}
        disabled={deleteLoading}
        title="Attenzione!"
      >
        <Typography variant="h3">Sei sicuro di voler procedere?</Typography>
      </DialogWarning>
      <Title title="Gestione operatori" />
      <Breadcrumb current="Gestione operatori" />
      <FiltersWrapper>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={orderBy}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setOrderBy(e.target.value as UserOrderByInput)
                }}
              >
                <MenuItem value={UserOrderByInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={UserOrderByInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={UserOrderByInput.LASTNAME_DESC}>
                  Cognome ↓
                </MenuItem>
                <MenuItem value={UserOrderByInput.LASTNAME_ASC}>
                  Cognome ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {usersLoading && <div>Caricamento degli utenti in corso</div>}
      {!usersLoading && !usersError && usersData && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head" align="center">
                    Azioni
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Nome
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Cognome
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersData.getUsers.users.map((user) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell align="center">
                        <Tooltip title="Elimina">
                          <IconButton onClick={() => handleDelete(user.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{user.name}</TableCell>
                      <TableCell align="center">{user.lastname}</TableCell>
                      <TableCell align="center">{user.email}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Totale utenti per pagina"
            labelDisplayedRows={({ from, to, count }) =>
              `Utenti ${from}-${to} di ${count}`
            }
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={usersData ? usersData.getUsers.total : 0}
            rowsPerPage={limit}
            page={Math.floor(offset / limit)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  )
}
