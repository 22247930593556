import React, { ReactNode } from 'react'
import {
  Dialog,
  DialogActions,
  IconButton,
  makeStyles,
  Typography,
  Button,
  Divider,
} from '@material-ui/core'
import { Close, Done } from '@material-ui/icons'
import { colors } from 'theme'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: colors.disabledBackground,
  },
  divider: {
    margin: '35px auto 40px',
  },
  actions: {
    justifyContent: 'center',
    marginTop: 35,
    flexWrap: 'wrap',
  },
}))

interface DialogInfoProps {
  open: boolean
  onClose: () => void
  children: ReactNode
  title?: string
}

const DialogInfo: React.FC<DialogInfoProps> = ({
  open,
  onClose,
  children,
  title,
}) => {
  const classes = useStyles()
  return (
    <Dialog maxWidth="md" fullWidth={true} open={open} scroll="body">
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <Close htmlColor={colors.text.primary} />
      </IconButton>
      {title && (
        <>
          <Typography variant="h1" color="textSecondary" align="center">
            {title}
          </Typography>
          <Divider className={classes.divider} />
        </>
      )}
      {children}
      <DialogActions className={classes.actions} disableSpacing={true}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={onClose}
          startIcon={<Done />}
        >
          CONTINUA
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogInfo
