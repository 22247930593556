import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_PROFILE } from 'query'
import { getProfile, getProfileVariables } from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  Title,
  UpdateProfileProfessional,
  UpdateProfileOperator,
  UpdateProfilePassword,
  ProfileDataTable,
} from 'components'
import { userIdVar } from 'cache'

export default function GestioneUtenti() {
  const [showUpdateUserForm, setShowUpdateUserForm] = useState(false)
  const [showUpdatePasswordForm, setShowUpdatePasswordForm] = useState(false)
  const {
    error: errorProfile,
    data: dataProfile,
    loading: loadingProfile,
  } = useQuery<getProfile, getProfileVariables>(GET_PROFILE, {
    fetchPolicy: 'network-only',
    variables: { userId: (userIdVar() as unknown) as string },
  })

  return (
    <>
      <Title title="Profilo" />
      <Breadcrumb current="Profilo" />
      {loadingProfile && <div>Caricamento del profilo utente in corso</div>}
      {!loadingProfile && !errorProfile && dataProfile?.getUser && (
        <>
          <UpdateProfilePassword
            userId={dataProfile?.getUser.id}
            open={showUpdatePasswordForm}
            onClose={() => {
              setShowUpdatePasswordForm(false)
            }}
            onSuccess={() => {
              setShowUpdatePasswordForm(false)
            }}
          />
          <ProfileDataTable
            userProfile={dataProfile.getUser}
            onChangePassword={() => {
              setShowUpdatePasswordForm(true)
            }}
            onChangeProfile={() => {
              setShowUpdateUserForm(true)
            }}
          />
          {dataProfile?.getUser?.details ? (
            <UpdateProfileProfessional
              user={dataProfile?.getUser}
              open={showUpdateUserForm}
              onClose={() => {
                setShowUpdateUserForm(false)
              }}
              onSuccess={() => {
                setShowUpdateUserForm(false)
              }}
            />
          ) : (
            <UpdateProfileOperator
              user={dataProfile?.getUser}
              open={showUpdateUserForm}
              onClose={() => {
                setShowUpdateUserForm(false)
              }}
              onSuccess={() => {
                setShowUpdateUserForm(false)
              }}
            />
          )}
        </>
      )}
    </>
  )
}
