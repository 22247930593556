import React, { ReactNode } from 'react'
import {
  Dialog,
  DialogActions,
  IconButton,
  makeStyles,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close, Save, Send } from '@material-ui/icons'
import { colors } from 'theme'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: colors.disabledBackground,
  },
  divider: {
    margin: '35px auto 40px',
  },
  actions: {
    marginTop: 0,
    // justifyContent: 'space-between',
    flexDirection: 'column-reverse',
    // gap: '30px',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      marginTop: 20,
      justifyContent: 'center',
      flexDirection: 'row',
    },
    '& > *': {
      margin: 15,
    },
  },
}))

export enum ActionType {
  CANCEL,
  EXIT,
  SAVE,
  SEND,
}

interface Action {
  type: ActionType
  label: string
  disabled?: boolean
  callback: () => void
}

interface DialogFormProps {
  open: boolean
  onClose: () => void
  children: ReactNode
  title?: string
  actions: Action[]
  alignment?: 'left' | 'center' | 'right'
}

const DialogForm: React.FC<DialogFormProps> = ({
  alignment = 'left',
  open,
  onClose,
  children,
  title,
  actions,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const useTextButton = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      scroll="body"
      // onClose={onClose}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <Close htmlColor={colors.text.primary} />
      </IconButton>
      {title && (
        <Typography
          variant="h2"
          color="textPrimary"
          align={alignment}
          style={{ marginBottom: '0.75em' }}
        >
          {title}
        </Typography>
      )}
      {children}
      <DialogActions className={classes.actions} disableSpacing={true}>
        {actions.map((action, index) => {
          switch (action.type) {
            case ActionType.SEND:
              return (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  size="medium"
                  endIcon={<Send />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              )
            case ActionType.EXIT:
              return useTextButton ? (
                <Button
                  key={index}
                  variant="text"
                  color="secondary"
                  size="medium"
                  startIcon={<Close />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              ) : (
                <Button
                  key={index}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  startIcon={<Close />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              )
            case ActionType.SAVE:
              return (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<Save />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              )
            case ActionType.CANCEL:
              return useTextButton ? (
                <Button
                  key={index}
                  variant="text"
                  color="primary"
                  size="medium"
                  startIcon={<Close />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              ) : (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<Close />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              )
            default:
              return null
          }
        })}
      </DialogActions>
    </Dialog>
  )
}

export default DialogForm
