import React from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { format } from 'date-fns'
import {
  AddressInput,
  UpdateUserInput,
  Gender,
  Profession,
  Specialization,
  updateProfessional,
  updateProfessionalVariables,
  getProfile_getUser as UserType,
  UserRole,
} from 'types/generated/schemaTypes'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { CalendarToday } from '@material-ui/icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UPDATE_PROFESSIONAL } from 'mutation'
import { ActionType, DialogForm, GDPR } from 'components'
import { formatError, isValidFiscalCode, professionsMap } from 'utils'
import { provinces } from 'provinces'
import { roleVar } from 'cache'

export interface UpdateProfileProfessionalValues {
  name: string
  lastname: string
  gender: Gender | String
  birthday: Date | undefined | null
  fiscalCode: string
  profession: Profession | string
  specialization: Specialization | string
  customProfession: string | null
  customSpecialization: string | null
  businessName: string | null
  businessRole: string | null
  registerId: string
  phone: string
  addressLine: string
  zip: string
  city: string
  province: string
  region: string
  addressLineSecondary: string
  zipSecondary: string
  citySecondary: string
  provinceSecondary: string
  regionSecondary: string
  addSecondaryAddress: boolean
  agent: string
}

const defaultInitialValues = {
  name: '',
  lastname: '',
  gender: '',
  birthday: null,
  fiscalCode: '',
  profession: '',
  specialization: '',
  phone: '',
  agent: '',
  registerId: '',
  addressLine: '',
  zip: '',
  city: '',
  province: '',
  region: '',
  addressLineSecondary: '',
  zipSecondary: '',
  citySecondary: '',
  provinceSecondary: '',
  regionSecondary: '',
  addSecondaryAddress: false,
  customProfession: '',
  customSpecialization: '',
  businessName: '',
  businessRole: '',
}

const parseInitialValue = (
  input: UserType
): UpdateProfileProfessionalValues => {
  const hasSecondaryAddress =
    input.details && input.details.addresses.length > 1
  const values: UpdateProfileProfessionalValues = {
    name: input.name,
    lastname: input.lastname,
    gender: input.details?.gender as Gender,
    birthday: new Date(input.details?.birthDate),
    fiscalCode: input.details?.fiscalCode as string,
    profession: input.details?.profession as string,
    specialization: input.details?.specialization as string,
    phone: input.details?.phone as string,
    agent: input.details?.agent as string,
    registerId: input.details?.registerId as string,
    addressLine: input.details?.addresses[0].addressLine as string,
    zip: input.details?.addresses[0].zip as string,
    city: input.details?.addresses[0].city as string,
    province: input.details?.addresses[0].province as string,
    region: input.details?.addresses[0].region as string,
    addressLineSecondary: hasSecondaryAddress
      ? (input.details?.addresses[1].addressLine as string)
      : '',
    zipSecondary: hasSecondaryAddress
      ? (input.details?.addresses[1].zip as string)
      : '',
    citySecondary: hasSecondaryAddress
      ? (input.details?.addresses[1].city as string)
      : '',
    provinceSecondary: hasSecondaryAddress
      ? (input.details?.addresses[1].province as string)
      : '',
    regionSecondary: hasSecondaryAddress
      ? (input.details?.addresses[1].region as string)
      : '',
    addSecondaryAddress: !!hasSecondaryAddress,
    customProfession: input.details?.customProfession || '',
    customSpecialization: input.details?.customSpecialization || '',
    businessName: input.details?.businessName || '',
    businessRole: input.details?.businessRole || '',
  }
  return values
}

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obbligatorio'),
  lastname: Yup.string().required('Campo obbligatorio'),
  gender: Yup.string().required('Campo obbligatorio'),
  // agent: Yup.string().required('Campo obbligatorio'),
  registerId: Yup.string().required('Campo obbligatorio'),
  birthday: Yup.mixed().required('Campo obbligatorio'),
  fiscalCode: Yup.string()
    .required('Campo obbligatorio')
    .matches(/^[A-Za-z0-9]+$/, 'Sono ammessi solo numeri e caratteri')
    .min(16, 'Il codice fiscale deve contenere 16 caratteri')
    .max(16, 'Il codice fiscale deve contenere 16 caratteri')
    .test({
      name: 'fiscalCode',
      exclusive: true,
      message: 'Codice fiscale non valido',
      test: (value) => {
        if (!value) return false
        if (value.length !== 16) return false
        return isValidFiscalCode(value)
      },
    }),
  phone: Yup.string().required('Campo obbligatorio'),
  addressLine: Yup.string().required('Campo obbligatorio'),
  zip: Yup.string().required('Campo obbligatorio'),
  city: Yup.string().required('Campo obbligatorio'),
  province: Yup.string().required('Campo obbligatorio'),
  profession: Yup.mixed().required('Campo obbligatorio'),
  region: Yup.mixed().required('Campo obbligatorio'),
  specialization: Yup.mixed().required('Campo obbligatorio'),
  customProfession: Yup.string().when('profession', {
    is: Profession.ALTRO,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  customSpecialization: Yup.string().when('specialization', {
    is: Specialization.ALTRO,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  businessName: Yup.string().when('specialization', {
    is: (val) =>
      [Specialization.FARMACISTA, Specialization.ERBORISTA].includes(val),
    then: Yup.string().required('Campo obbligatorio'),
  }),
  businessRole: Yup.string().when('specialization', {
    is: (val) =>
      [Specialization.FARMACISTA, Specialization.ERBORISTA].includes(val),
    then: Yup.string().required('Campo obbligatorio'),
  }),
  addressLineSecondary: Yup.string().when('addSecondaryAddress', {
    is: true,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  zipSecondary: Yup.string().when('addSecondaryAddress', {
    is: true,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  citySecondary: Yup.string().when('addSecondaryAddress', {
    is: true,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  provinceSecondary: Yup.string().when('addSecondaryAddress', {
    is: true,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  regionSecondary: Yup.string().when('addSecondaryAddress', {
    is: true,
    then: Yup.string().required('Campo obbligatorio'),
  }),
})

interface UpdateProfileProfessionalProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  user: UserType | null
}

const UpdateProfileProfessional: React.FC<UpdateProfileProfessionalProps> = ({
  open,
  onClose,
  onSuccess,
  user,
}) => {
  const roleCache = useReactiveVar(roleVar)
  const [updateProfessionalMutation, { error: formError }] = useMutation<
    updateProfessional,
    updateProfessionalVariables
  >(UPDATE_PROFESSIONAL, {
    onCompleted: async (data) => {
      formik.resetForm()
      onSuccess()
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const formik = useFormik<UpdateProfileProfessionalValues>({
    initialValues: user
      ? parseInitialValue(user as UserType)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const addresses: AddressInput[] = [
        {
          addressLine: values.addressLine,
          city: values.city,
          zip: values.zip,
          province: values.province,
          region: values.region,
        },
      ]
      if (values.addSecondaryAddress)
        addresses.push({
          addressLine: values.addressLineSecondary,
          city: values.citySecondary,
          zip: values.zipSecondary,
          province: values.provinceSecondary,
          region: values.regionSecondary,
        })
      const variables: { userData: UpdateUserInput; userId: string } = {
        userData: {
          name: values.name,
          lastname: values.lastname,
          details: {
            addresses,
            profession: values.profession as Profession,
            specialization: values.specialization as Specialization,
            customProfession:
              values.customProfession === '' ? null : values.customProfession,
            customSpecialization:
              values.customSpecialization === ''
                ? null
                : values.customSpecialization,
            businessName:
              values.businessName === '' ? null : values.businessName,
            businessRole:
              values.businessRole === '' ? null : values.businessRole,
            agent: values.agent || '',
            gender: values.gender as Gender,
            birthDate: format(values.birthday as Date, 'yyyy-MM-dd'),
            phone: values.phone,
            fiscalCode: values.fiscalCode,
            registerId: values.registerId,
          },
        },
        userId: user?.id as string,
      }
      await updateProfessionalMutation({ variables })
    },
  })

  return (
    <DialogForm
      open={open}
      title="Modifica profilo"
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: 'ESCI SENZA SALVARE',
          callback: () => {
            formik.resetForm()
            onClose()
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: 'SALVA E CHIUDI',
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginBottom: '1em' }}
        >
          Di seguito le informazioni relative al suo profilo. Per salvare le
          modifiche cliccare sul pulsante "Salva e chiudi" in fondo alla pagina.
        </Typography>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Dati personali
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* NOME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="outlined"
                id="name"
                size="small"
                label="Nome*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* COGNOME */}
            <FormControl fullWidth={true}>
              <TextField
                name="lastname"
                error={formik.touched.lastname && !!formik.errors.lastname}
                helperText={
                  formik.touched.lastname &&
                  !!formik.errors.lastname &&
                  formik.errors.lastname
                }
                variant="outlined"
                id="lastname"
                size="small"
                label="Cognome*"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* SESSO */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={formik.touched.gender && !!formik.errors.gender}
                id="gender-label"
              >
                Sesso
              </InputLabel>
              <Select
                name="gender"
                value={formik.values.gender}
                labelId="gender-label"
                id="gender"
                label="Sesso*"
                error={formik.touched.gender && !!formik.errors.gender}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                // style={{ marginBottom: 20 }}
              >
                <MenuItem value={Gender.M}>Maschio</MenuItem>
                <MenuItem value={Gender.F}>Femmina</MenuItem>
              </Select>
              {formik.touched.gender && !!formik.errors.gender && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.gender}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* DATA DI NASCITA */}
            <FormControl fullWidth={true}>
              <KeyboardDatePicker
                size="small"
                keyboardIcon={<CalendarToday />}
                autoOk
                variant="inline"
                error={formik.touched.birthday && !!formik.errors.birthday}
                inputVariant="outlined"
                label={formik.values.birthday ? 'Data nascita*' : null}
                format="dd/MM/yyyy"
                placeholder="Data nascita*"
                name="birthday"
                value={formik.values.birthday}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date) =>
                  formik.setFieldValue('birthday', date as Date)
                }
              />
              {formik.touched.birthday && !!formik.errors.birthday && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.birthday}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* CODICE FISCALE */}
            <FormControl fullWidth={true}>
              <TextField
                name="fiscalCode"
                error={formik.touched.fiscalCode && !!formik.errors.fiscalCode}
                helperText={
                  formik.touched.fiscalCode &&
                  !!formik.errors.fiscalCode &&
                  formik.errors.fiscalCode
                }
                variant="outlined"
                id="fiscalCode"
                size="small"
                label="Codice fiscale*"
                value={formik.values.fiscalCode}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 16 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={9}>
            {/* INDIRIZZO */}
            <FormControl fullWidth={true}>
              <TextField
                name="addressLine"
                error={
                  formik.touched.addressLine && !!formik.errors.addressLine
                }
                helperText={
                  formik.touched.addressLine &&
                  !!formik.errors.addressLine &&
                  formik.errors.addressLine
                }
                variant="outlined"
                id="addressLine"
                size="small"
                label="Indirizzo professionale (Via, Piazza... e n. civico)*"
                value={formik.values.addressLine}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* CAP */}
            <FormControl fullWidth={true}>
              <TextField
                name="zip"
                error={formik.touched.zip && !!formik.errors.zip}
                helperText={
                  formik.touched.zip && !!formik.errors.zip && formik.errors.zip
                }
                variant="outlined"
                id="zip"
                size="small"
                label="CAP*"
                value={formik.values.zip}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 5 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* CITTÀ */}
            <FormControl fullWidth={true}>
              <TextField
                name="city"
                error={formik.touched.city && !!formik.errors.city}
                helperText={
                  formik.touched.city &&
                  !!formik.errors.city &&
                  formik.errors.city
                }
                variant="outlined"
                id="city"
                size="small"
                label="Città*"
                value={formik.values.city}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* PROVINCIA */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={formik.touched.province && !!formik.errors.province}
                id="province-label"
              >
                Provincia*
              </InputLabel>
              <Select
                name="province"
                value={formik.values.province}
                labelId="province-label"
                id="province"
                label="Provincia*"
                error={formik.touched.province && !!formik.errors.province}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              >
                {provinces.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.province && !!formik.errors.province && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.province}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* REGIONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="region"
                error={formik.touched.region && !!formik.errors.region}
                helperText={
                  formik.touched.region &&
                  !!formik.errors.region &&
                  formik.errors.region
                }
                variant="outlined"
                id="region"
                size="small"
                label="Regione*"
                value={formik.values.region}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlLabel
                label="Aggiungi l'indirizzo di un altro studio"
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.addSecondaryAddress}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    name="addSecondaryAddress"
                  />
                }
              />
            </FormControl>
          </Grid>
          {formik.values.addSecondaryAddress && (
            <>
              <Grid item xs={12} md={9}>
                {/* INDIRIZZO SECONDARIO */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="addressLineSecondary"
                    error={
                      formik.touched.addressLineSecondary &&
                      !!formik.errors.addressLineSecondary
                    }
                    helperText={
                      formik.touched.addressLineSecondary &&
                      !!formik.errors.addressLineSecondary &&
                      formik.errors.addressLineSecondary
                    }
                    variant="outlined"
                    id="addressLineSecondary"
                    size="small"
                    label="Indirizzo professionale (Via, Piazza... e n. civico)*"
                    value={formik.values.addressLineSecondary}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                {/* CAP SECONDARY */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="zipSecondary"
                    error={
                      formik.touched.zipSecondary &&
                      !!formik.errors.zipSecondary
                    }
                    helperText={
                      formik.touched.zipSecondary &&
                      !!formik.errors.zipSecondary &&
                      formik.errors.zipSecondary
                    }
                    variant="outlined"
                    id="zipSecondary"
                    size="small"
                    label="CAP*"
                    value={formik.values.zipSecondary}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    inputProps={{ maxLength: 5 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* CITTÀ SECONDARY */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="citySecondary"
                    error={
                      formik.touched.citySecondary &&
                      !!formik.errors.citySecondary
                    }
                    helperText={
                      formik.touched.citySecondary &&
                      !!formik.errors.citySecondary &&
                      formik.errors.citySecondary
                    }
                    variant="outlined"
                    id="citySecondary"
                    size="small"
                    label="Città*"
                    value={formik.values.citySecondary}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* PROVINCIA SECONDARIA */}
                <FormControl fullWidth={true} variant="outlined" size="small">
                  <InputLabel
                    error={
                      formik.touched.provinceSecondary &&
                      !!formik.errors.provinceSecondary
                    }
                    id="provinceSecondary-label"
                  >
                    Provincia*
                  </InputLabel>
                  <Select
                    name="provinceSecondary"
                    value={formik.values.provinceSecondary}
                    labelId="provinceSecondary-label"
                    id="provinceSecondary"
                    label="Provincia*"
                    error={
                      formik.touched.provinceSecondary &&
                      !!formik.errors.provinceSecondary
                    }
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  >
                    {provinces.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.provinceSecondary &&
                    !!formik.errors.provinceSecondary && (
                      <FormHelperText error={true} variant="standard">
                        {formik.errors.provinceSecondary}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* REGIONE SECONDARIA */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="regionSecondary"
                    error={
                      formik.touched.regionSecondary &&
                      !!formik.errors.regionSecondary
                    }
                    helperText={
                      formik.touched.regionSecondary &&
                      !!formik.errors.regionSecondary &&
                      formik.errors.regionSecondary
                    }
                    variant="outlined"
                    id="regionSecondary"
                    size="small"
                    label="Regione*"
                    value={formik.values.regionSecondary}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Dati professionali
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* PROFESSIONE */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={formik.touched.profession && !!formik.errors.profession}
                id="profession-label"
              >
                Professione*
              </InputLabel>
              <Select
                name="profession"
                value={formik.values.profession}
                labelId="profession-label"
                id="profession"
                label="Professione*"
                error={formik.touched.profession && !!formik.errors.profession}
                onChange={(e) => {
                  formik.setFieldValue('profession', e.target.value)
                  if (e.target.value === Profession.ALTRO) {
                    formik.setFieldValue('specialization', Specialization.ALTRO)
                    formik.setFieldValue('customSpecialization', '-')
                  } else {
                    formik.setFieldValue('specialization', '')
                  }
                }}
                disabled={formik.isSubmitting}
              >
                {[
                  Profession.BIOLOGO,
                  // Profession.DIETISTA,
                  Profession.DISCIPLINE_OLISTICHE,
                  Profession.FARMACISTA,
                  // Profession.FISIOTERAPISTA,
                  // Profession.IGIENISTA_DENTALE,
                  Profession.MEDICO_CHIRURGO,
                  Profession.ODONTOIATRA,
                  // Profession.OSTEOPATA,
                  Profession.PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE,
                  Profession.PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE,
                  Profession.PROFESSIONI_TECNICO_SANITARIE,
                  Profession.PSICOLOGO,
                  Profession.ALTRO,
                ].map((item) => (
                  <MenuItem key={item} value={item}>
                    {professionsMap[item].label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.profession && !!formik.errors.profession && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.profession}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {formik.values.profession !== Profession.ALTRO && (
            <Grid item xs={12} md={6}>
              {/* SPECIALIZZAZIONE */}
              <FormControl fullWidth={true} variant="outlined" size="small">
                <InputLabel
                  error={
                    formik.touched.specialization &&
                    !!formik.errors.specialization
                  }
                  id="specialization-label"
                >
                  Specializzazione*
                </InputLabel>
                <Select
                  name="specialization"
                  value={formik.values.specialization}
                  labelId="specialization-label"
                  id="specialization"
                  label="Specializzazione*"
                  error={
                    formik.touched.specialization &&
                    !!formik.errors.specialization
                  }
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                >
                  {formik.values.profession &&
                    professionsMap[
                      formik.values.profession as Profession
                    ].specializations.map(
                      (item: {
                        specialization: Specialization
                        label: string
                      }) => {
                        return (
                          <MenuItem
                            key={item.specialization}
                            value={item.specialization}
                          >
                            {item.label}
                          </MenuItem>
                        )
                      }
                    )}
                </Select>
                {formik.touched.specialization &&
                  !!formik.errors.specialization && (
                    <FormHelperText error={true} variant="standard">
                      {formik.errors.specialization}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          )}
          {formik.values.profession === Profession.ALTRO && (
            <Grid item xs={12} md={6}>
              {/* PROFESSIONE CUSTOM */}
              <FormControl fullWidth={true}>
                <TextField
                  name="customProfession"
                  error={
                    formik.touched.customProfession &&
                    !!formik.errors.customProfession
                  }
                  helperText={
                    formik.touched.customProfession &&
                    !!formik.errors.customProfession &&
                    formik.errors.customProfession
                  }
                  variant="outlined"
                  id="customProfession"
                  size="small"
                  label="Indicare la professione*"
                  value={formik.values.customProfession}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            </Grid>
          )}
          {formik.values.specialization === Specialization.ALTRO &&
            formik.values.profession !== Profession.ALTRO && (
              <Grid item xs={12} md={6}>
                {/* SPECIALIZZAZIONE CUSTOM */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="customSpecialization"
                    error={
                      formik.touched.customSpecialization &&
                      !!formik.errors.customSpecialization
                    }
                    helperText={
                      formik.touched.customSpecialization &&
                      !!formik.errors.customSpecialization &&
                      formik.errors.customSpecialization
                    }
                    variant="outlined"
                    id="customSpecialization"
                    size="small"
                    label="Indicare la specializzazione*"
                    value={formik.values.customSpecialization}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
            )}

          {(formik.values.profession === Profession.FARMACISTA ||
            formik.values.specialization === Specialization.ERBORISTA) && (
            <>
              <Grid item xs={12} md={6}>
                {/* BUSINESS NAME */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="businessName"
                    error={
                      formik.touched.businessName &&
                      !!formik.errors.businessName
                    }
                    helperText={
                      formik.touched.businessName &&
                      !!formik.errors.businessName &&
                      formik.errors.businessName
                    }
                    variant="outlined"
                    id="businessName"
                    size="small"
                    label={`Nome ${
                      formik.values.profession === Profession.FARMACISTA
                        ? 'farmacia'
                        : 'erboristeria'
                    }${
                      [
                        Specialization.ERBORISTA,
                        Specialization.FARMACISTA,
                      ].includes(formik.values.specialization as Specialization)
                        ? '*'
                        : ''
                    }`}
                    value={formik.values.businessName}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* BUSINESS ROLE */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="businessRole"
                    error={
                      formik.touched.businessRole &&
                      !!formik.errors.businessRole
                    }
                    helperText={
                      formik.touched.businessRole &&
                      !!formik.errors.businessRole &&
                      formik.errors.businessRole
                    }
                    variant="outlined"
                    id="businessRole"
                    size="small"
                    label={`Ruolo${
                      [
                        Specialization.ERBORISTA,
                        Specialization.FARMACISTA,
                      ].includes(formik.values.specialization as Specialization)
                        ? '*'
                        : ''
                    }`}
                    value={formik.values.businessRole}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item xs={12} md={6}>
            {/* Iscrizione al collegio/ordine/ASS */}
            <FormControl fullWidth={true}>
              <TextField
                name="registerId"
                error={formik.touched.registerId && !!formik.errors.registerId}
                helperText={
                  formik.touched.registerId &&
                  !!formik.errors.registerId &&
                  formik.errors.registerId
                }
                variant="outlined"
                id="registerId"
                size="small"
                placeholder="Indicare ordine di iscrizione all'Albo  professionale e numero*"
                label="Indicare ordine di iscrizione all'Albo  professionale e numero*"
                value={formik.values.registerId}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* AGENTE */}
            {roleCache !== UserRole.PROFESSIONAL && (
              <FormControl fullWidth={true}>
                <TextField
                  name="agent"
                  error={formik.touched.agent && !!formik.errors.agent}
                  helperText={
                    formik.touched.agent &&
                    !!formik.errors.agent &&
                    formik.errors.agent
                  }
                  variant="outlined"
                  id="agent"
                  size="small"
                  label="Agente di zona (Cognome e Nome) / Contatto di riferimento"
                  value={formik.values.agent}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Contatti
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* TELEFONO */}
            <FormControl fullWidth={true}>
              <TextField
                name="phone"
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={
                  formik.touched.phone &&
                  !!formik.errors.phone &&
                  formik.errors.phone
                }
                variant="outlined"
                id="phone"
                size="small"
                label="Telefono*"
                value={formik.values.phone}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                inputProps={{ pattern: '[0-9]' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <GDPR />

            <Typography
              variant="caption"
              style={{ fontSize: 12 }}
              color="textSecondary"
            >
              Avendo letto e compreso le informazioni presenti nella{' '}
              <Link
                href="https://www.bromatech.it/privacy-policy"
                rel="noopener"
                target="_blank"
              >
                Privacy Policy
              </Link>{' '}
              di questo sito e sapendo che il Trattamento dei dati richiesti è
              funzionale all’iscrizione all’Area Riservata e/o al modulo di
              Richiesta Informazioni, dichiaro quanto segue:
            </Typography>
            <Typography
              variant="caption"
              style={{ fontSize: 12 }}
              color="textSecondary"
            >
              a) *di prestare il consenso informato al trattamento dei
              summenzionati dati al fine di poter partecipare a corsi di
              aggiornamento medico-scientifico.
            </Typography>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlLabel
                label={<div>Acconsento</div>}
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={true}
                    disabled={true}
                    name="privacy"
                  />
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        {formError && (
          <Typography color="error" variant="body1">
            {formatError(formError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  )
}

export default UpdateProfileProfessional
