import { ApolloError } from '@apollo/client'
import { Profession, Specialization } from 'types/generated/schemaTypes'

const isValidFiscalCode = (code: string): boolean => {
  if (code.length !== 16) return false
  let valid = false
  let sum = 0
  for (let i = 0; i < 15; i++) {
    const test = code[i].toUpperCase()
    const isEven = (i + 1) % 2 === 0
    switch (test) {
      case '0':
      case 'A':
        sum += isEven ? 0 : 1
        break
      case '1':
      case 'B':
        sum += isEven ? 1 : 0
        break
      case '2':
      case 'C':
        sum += isEven ? 2 : 5
        break
      case '3':
      case 'D':
        sum += isEven ? 3 : 7
        break
      case '4':
      case 'E':
        sum += isEven ? 4 : 9
        break
      case '5':
      case 'F':
        sum += isEven ? 5 : 13
        break
      case '6':
      case 'G':
        sum += isEven ? 6 : 15
        break
      case '7':
      case 'H':
        sum += isEven ? 7 : 17
        break
      case '8':
      case 'I':
        sum += isEven ? 8 : 19
        break
      case '9':
      case 'J':
        sum += isEven ? 9 : 21
        break
      case 'K':
        sum += isEven ? 10 : 2
        break
      case 'L':
        sum += isEven ? 11 : 4
        break
      case 'M':
        sum += isEven ? 12 : 18
        break
      case 'N':
        sum += isEven ? 13 : 20
        break
      case 'O':
        sum += isEven ? 14 : 11
        break
      case 'P':
        sum += isEven ? 15 : 3
        break
      case 'Q':
        sum += isEven ? 16 : 6
        break
      case 'R':
        sum += isEven ? 17 : 8
        break
      case 'S':
        sum += isEven ? 18 : 12
        break
      case 'T':
        sum += isEven ? 19 : 14
        break
      case 'U':
        sum += isEven ? 20 : 16
        break
      case 'V':
        sum += isEven ? 21 : 10
        break
      case 'W':
        sum += isEven ? 22 : 22
        break
      case 'X':
        sum += isEven ? 23 : 25
        break
      case 'Y':
        sum += isEven ? 24 : 24
        break
      case 'Z':
        sum += isEven ? 25 : 23
        break
      default:
        sum -= 10000
    }
  }
  if (sum < 0) return false
  const rest = sum % 26
  const alfabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (Number.isNaN(parseInt(`${code[6]}${code[7]}`))) return false
  valid = code[15].toUpperCase() === alfabet[rest]
  return valid
}

const professionsMap = {
  [Profession.BIOLOGO]: {
    label: 'Biologo',
    specializations: [
      {
        specialization: Specialization.BIOLOGO_NUTRIZIONISTA,
        label: 'Biologo nutrizionista',
      },
      {
        specialization: Specialization.ALTRO,
        label: 'Altro',
      },
    ],
  },
  // [Profession.DIETISTA]: {
  //   label: 'Dietista',
  //   specializations: [
  //     {
  //       specialization: Specialization.DIETISTA,
  //       label: 'Dietista',
  //     },
  //   ],
  // },
  // [Profession.OSTEOPATA]: {
  //   label: 'Osteopata',
  //   specializations: [
  //     {
  //       specialization: Specialization.OSTEOPATA,
  //       label: 'Osteopata',
  //     },
  //   ],
  // },
  [Profession.DISCIPLINE_OLISTICHE]: {
    label: 'Discipline olistiche',
    specializations: [
      {
        specialization: Specialization.ERBORISTA,
        label: 'Erborista',
      },
      {
        specialization: Specialization.FITOTERAPIA,
        label: 'Fitoterapista',
      },
      {
        specialization: Specialization.IRIDOLOGIA,
        label: 'Iridologia',
      },
      {
        specialization: Specialization.KINESIOLOGIA,
        label: 'Kinesiologia',
      },
      {
        specialization: Specialization.MASSOKINESITERAPIA,
        label: 'Massokinesiterapia',
      },
      {
        specialization: Specialization.NATUROPATA,
        label: 'Naturopata',
      },
      {
        specialization: Specialization.OMEOPATA,
        label: 'Omeopata',
      },
      {
        specialization: Specialization.RIFLESSOLOGIA,
        label: 'Riflessologia',
      },
      {
        specialization: Specialization.ALTRO,
        label: 'Altro',
      },
    ],
  },
  [Profession.FARMACISTA]: {
    label: 'Farmacista',
    specializations: [
      {
        specialization: Specialization.FARMACISTA,
        label: 'Farmacista',
      },
      {
        specialization: Specialization.ALTRO,
        label: 'Altro',
      },
    ],
  },
  // [Profession.FISIOTERAPISTA]: {
  //   label: 'Fisioterapista',
  //   specializations: [
  //     {
  //       specialization: Specialization.FISIOTERAPIA,
  //       label: 'Fisioterapia',
  //     },
  //   ],
  // },
  // [Profession.IGIENISTA_DENTALE]: {
  //   label: 'Igienista dentale',
  //   specializations: [
  //     {
  //       specialization: Specialization.IGIENISTA,
  //       label: 'Igienista dentale',
  //     },
  //   ],
  // },
  [Profession.MEDICO_CHIRURGO]: {
    label: 'Medico chirurgo',
    specializations: [
      {
        specialization: Specialization.ALLERGOLOGIA_IMMUNOLOGIA_CLINICA,
        label: 'Allergologia e immunologia clinica',
      },
      {
        specialization: Specialization.ANESTESIA_RIANIMAZIONE,
        label: 'Anestesia e rianimazione',
      },
      {
        specialization: Specialization.CARDIOLOGIA,
        label: 'Cardiologia',
      },
      {
        specialization: Specialization.CHIRURGIA_GENERALE,
        label: 'Chirurgia ',
      },
      {
        specialization: Specialization.DERMATOLOGIA_VENEREOLOGIA,
        label: 'Dermatologia e venereologia',
      },
      {
        specialization: Specialization.EMATOLOGIA,
        label: 'Ematologia',
      },
      {
        specialization: Specialization.ENDOCRINOLOGIA,
        label: 'Endocrinologia',
      },
      {
        specialization: Specialization.FISIATRIA,
        label: 'Fisiatria',
      },
      {
        specialization: Specialization.GASTROENTEROLOGIA,
        label: 'Gastroenterologia',
      },
      {
        specialization: Specialization.GENETICA_MEDICA,
        label: 'Genetica medica',
      },
      {
        specialization: Specialization.GERIATRIA,
        label: 'Geriatria',
      },
      {
        specialization: Specialization.GINECOLOGIA_OSTETRICIA,
        label: 'Ginecologia e ostetricia',
      },
      {
        specialization: Specialization.IGIENE_E_MEDICINA_PREVENTIVA,
        label: 'Igiene e medicina preventiva',
      },
      {
        specialization: Specialization.MALATTIE_APPARATO_RESPIRATORIO,
        label: "Malattie dell'apparato respiratorio",
      },
      {
        specialization: Specialization.MALATTIE_INFETTIVE,
        label: 'Malattie infettive',
      },
      {
        specialization: Specialization.MEDICINA_LAVORO,
        label: 'Medicina del lavoro e sicurezza degli ambienti di lavoro',
      },
      {
        specialization: Specialization.MEDICINA_SPORT,
        label: 'Medicina dello sport',
      },
      {
        specialization: Specialization.MEDICINA_GENERALE,
        label: 'Medicina generale (medici di famiglia)',
      },
      {
        specialization: Specialization.MEDICINA_INTERNA,
        label: 'Medicina interna',
      },
      {
        specialization: Specialization.MEDICINA_LEGALE,
        label: 'Medicina legale',
      },
      {
        specialization: Specialization.MICROBIOLOGIA_VIROLOGIA,
        label: 'Microbiologia e virologia',
      },
      {
        specialization: Specialization.NEFROLOGIA,
        label: 'Nefrologia',
      },
      // {
      //   specialization: Specialization.NEUROCHIRURGIA,
      //   label: 'Neurochirurgia',
      // },
      {
        specialization: Specialization.NEUROLOGIA,
        label: 'Neurologia',
      },
      {
        specialization: Specialization.NEUROPSICHIATRIA_INFANTILE,
        label: 'Neuropsichiatria infantile',
      },
      {
        specialization: Specialization.OFTALMOLOGIA,
        label: 'Oculistica (Oftalmologia)',
      },
      {
        specialization: Specialization.ONCOLOGIA,
        label: 'Oncologia',
      },
      {
        specialization: Specialization.ORTOPEDIA_TRAUMATOLOGIA,
        label: 'Ortopedia e traumatologia',
      },
      {
        specialization: Specialization.OTORINOLARINGOIATRIA,
        label: 'Otorinolaringoiatria',
      },
      {
        specialization: Specialization.PATOLOGIA_CLINICA,
        label:
          'Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)',
      },
      {
        specialization: Specialization.PEDIATRIA,
        label: 'Pediatria',
      },
      {
        specialization: Specialization.PRIVO_DI_SPECIALIZZAZIONE,
        label: 'Privo di specializzazione',
      },
      {
        specialization: Specialization.PSICHIATRIA,
        label: 'Psichiatria',
      },
      {
        specialization: Specialization.PSICOTERAPIA,
        label: 'Psicoterapia',
      },
      {
        specialization: Specialization.REUMATOLOGIA,
        label: 'Reumatologia',
      },
      {
        specialization: Specialization.SCIENZA_ALIMENTAZIONE_DIETETICA,
        label: "Scienza dell'alimentazione e dietetica",
      },
      {
        specialization: Specialization.UROLOGIA,
        label: 'Urologia',
      },
      {
        specialization: Specialization.ALTRO,
        label: 'Altro',
      },
    ],
  },
  [Profession.ODONTOIATRA]: {
    label: 'Odontoiatra',
    specializations: [
      {
        specialization: Specialization.ODONTOIATRA,
        label: 'Odontoiatria',
      },
    ],
  },
  [Profession.PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE]: {
    label: 'Professioni sanitarie della riabilitazione',
    specializations: [
      {
        specialization: Specialization.FISIOTERAPIA,
        label: 'Fisioterapista',
      },
      {
        specialization: Specialization.FISIOTERAPIA_DEL_PAVIMENTO_PELVICO,
        label: 'Fisioterapista del pavimento pelvico',
      },
      {
        specialization: Specialization.LOGOPEDISTA,
        label: 'Logopedista',
      },
      {
        specialization: Specialization.ORTOTTISTA_ASSISTENTE_DI_OFTALMOLOGIA,
        label: 'Ortottista - assistente di oftalmologia',
      },
      {
        specialization: Specialization.OSTEOPATA,
        label: 'Osteopata',
      },
      {
        specialization: Specialization.PODOLOGO,
        label: 'Podologo',
      },
      {
        specialization: Specialization.ALTRO,
        label: 'Altro',
      },
    ],
  },
  [Profession.PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE]: {
    label: 'Professioni sanitarie infermieristiche e ostetriche',
    specializations: [
      {
        specialization: Specialization.INFERMIERE,
        label: 'Infermiere',
      },
      {
        specialization: Specialization.INFERMIERE_PEDIATRICO,
        label: 'Infermiere pediatrico',
      },
      {
        specialization: Specialization.OSTETRICA,
        label: 'Ostetrica',
      },
    ],
  },
  [Profession.PROFESSIONI_TECNICO_SANITARIE]: {
    label: 'Professioni tecnico sanitarie',
    specializations: [
      {
        specialization: Specialization.DIETISTA,
        label: 'Dietista',
      },
      {
        specialization: Specialization.IGIENISTA,
        label: 'Igienista dentale',
      },
      {
        specialization: Specialization.TECNICO_ORTOPEDICO,
        label: 'Tecnico ortopedico',
      },
      {
        specialization: Specialization.TECNICO_SANITARIO_DI_LABORATORIO_MEDICO,
        label: 'Tecnico sanitario di laboratorio biomedico',
      },
      {
        specialization: Specialization.TECNICO_SANITARIO_DI_RADIOLOGIA_MEDICA,
        label: 'Tecnico sanitario di radiologia medica',
      },
      {
        specialization: Specialization.ALTRO,
        label: 'Altro',
      },
    ],
  },
  [Profession.PSICOLOGO]: {
    label: 'Psicologo',
    specializations: [
      {
        specialization: Specialization.PSICOLOGIA,
        label: 'Psicologia',
      },
      {
        specialization: Specialization.PSICOTERAPIA,
        label: 'Psicoterapia',
      },
    ],
  },
  [Profession.ALTRO]: {
    label: 'Altro',
    specializations: [
      {
        specialization: Specialization.ALTRO,
        label: 'Altra professione',
      },
    ],
  },
}

const formatError = (err: ApolloError): string => {
  return err.graphQLErrors[0].message
}

export { formatError, isValidFiscalCode, professionsMap }
