import React from 'react'

import { Typography, makeStyles, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0,0,0,1)',
    padding: 15,
    color: '#ffffff',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  button: {
    position: 'fixed',
    bottom: 0,
    left: 40,
    background: 'rgba(0,0,0,1)',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: '5px 10px 2px',
    cursor: 'pointer',
    color: '#ffffff',
  },
  buttonOk: {
    marginTop: 20,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
}))

const Privacy: React.FC<{
  onDismiss?: () => void
  onShow?: () => void
  show: boolean
}> = ({ onDismiss, show, onShow }) => {
  const classes = useStyles()

  return (
    <>
      {show ? (
        <div className={classes.root}>
          <Typography variant="body1" color="inherit">
            Questo sito fa uso di cookies. Continuando la navigazione se ne
            autorizza l'uso.{' '}
            <a
              href="https://www.bromatech.it/cookie-policy"
              style={{ color: 'inherit' }}
            >
              Cookie policy
            </a>
          </Typography>
          <Button
            className={classes.buttonOk}
            onClick={onDismiss}
            variant="contained"
            size="small"
          >
            Ho capito!
          </Button>
        </div>
      ) : (
        <div className={classes.button} onClick={onShow}>
          <Typography variant="body1" color="inherit">
            Privacy policy
          </Typography>
        </div>
      )}
    </>
  )
}

export default Privacy
