import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  createMedicalEvent,
  createMedicalEventVariables,
  MedicalEventDateInput,
  updateMedicalEvent,
  updateMedicalEventVariables,
  MedicalEventType,
  MedicalEventStatus,
  MedicalEventArea,
  MediaType,
  getMedicalEvent_getMedicalEvent as EventType,
} from 'types/generated/schemaTypes'
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import {
  CalendarToday,
  Add as AddIcon,
  Alarm as WatchIcon,
} from '@material-ui/icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CREATE_EVENT, UPDATE_EVENT } from 'mutation'
import { ActionType, DialogForm, FileUpload } from 'components'
import { formatError } from 'utils'
import { format } from 'date-fns'

function isValidDate(d: any) {
  return d instanceof Date && d.toString() !== 'Invalid Date'
}

export interface EventFormValues {
  title: string
  tagline: string
  registrationDetails: string
  registrationDetails2: string
  registrationConfirmation: string
  webinarLink: string
  googleMapsLink: string
  address: string
  location: string
  city: string
  agent: string
  cover: string
  speakers: string[]
  type: MedicalEventType | ''
  status: MedicalEventStatus | ''
  deadline: Date | undefined | null
  dates: MedicalEventDateInput[]
  schedule: string | null
  curriculum: string | null
  attachment1: string | null
  attachment2: string | null
  attachmentTitle1: string
  attachmentTitle2: string
  usersMax: number | undefined
  open: boolean
  listed: boolean
  hideEmail: boolean
  area: MedicalEventArea | ''
}

const defaultInitialValues = {
  title: '',
  tagline: '',
  registrationDetails: '',
  registrationDetails2: '',
  registrationConfirmation: '',
  webinarLink: '',
  googleMapsLink: '',
  address: '',
  location: '',
  city: '',
  agent: '',
  cover: '',
  schedule: null,
  curriculum: null,
  attachment1: null,
  attachment2: null,
  attachmentTitle1: '',
  attachmentTitle2: '',
  speakers: [],
  type: '' as const,
  area: '' as const,
  status: MedicalEventStatus.DRAFT,
  deadline: null,
  dates: [],
  open: true,
  listed: true,
  hideEmail: false,
  usersMax: undefined,
}

const parseInitialValue = (input: EventType): EventFormValues => {
  const values: EventFormValues = {
    title: input.title,
    tagline: input.tagline,
    registrationDetails: input.registrationDetails,
    registrationDetails2: input.registrationDetails2,
    registrationConfirmation: input.registrationConfirmation,
    webinarLink: input.webinarLink || '',
    googleMapsLink: input.googleMapsLink || '',
    address: input.address || '',
    location: input.location || '',
    city: input.city || '',
    agent: input.agent || '',
    cover: input.cover || '',
    schedule: input.schedule,
    curriculum: input.curriculum,
    attachment1: input.attachment1,
    attachment2: input.attachment2,
    attachmentTitle1: input.attachmentTitle1 || '',
    attachmentTitle2: input.attachmentTitle2 || '',
    speakers: input.speakers,
    type: input.type,
    status: input.status,
    area: input.area,
    deadline: new Date(input.deadline),
    dates: input.dates.map((date) => {
      return { start: new Date(date.start), end: new Date(date.end) }
    }),
    open: input.open,
    listed: input.listed,
    hideEmail: input.hideEmail,
    usersMax:
      input.type === MedicalEventType.CLASSROOM ? input.usersMax : undefined,
  }
  return values
}

const useStyle = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.primary.main,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}))

const validationSchema = Yup.object({
  title: Yup.string().required('Campo obbligatorio'),
  cover: Yup.string().required('Campo obbligatorio'),
  tagline: Yup.string().required('Campo obbligatorio'),
  registrationDetails: Yup.mixed().required('Campo obbligatorio'),
  registrationDetails2: Yup.mixed().required('Campo obbligatorio'),
  registrationConfirmation: Yup.mixed().required('Campo obbligatorio'),
  type: Yup.string().required('Campo obbligatorio'),
  area: Yup.string().when('type', {
    is: MedicalEventType.CLASSROOM,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  // googleMapsLink: Yup.string().when('type', {
  //   is: MedicalEventType.CLASSROOM,
  //   then: Yup.string().required('Campo obbligatorio'),
  // }),
  address: Yup.string().when('type', {
    is: MedicalEventType.CLASSROOM,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  location: Yup.string().when('type', {
    is: MedicalEventType.CLASSROOM,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  deadline: Yup.mixed()
    .test({
      message: 'Formato non valido',
      test: (date) => {
        return isValidDate(date)
      },
    })
    .required('Campo obbligatorio'),
  dates: Yup.mixed().test({
    message: 'Devi inserire almeno una data',
    test: (arr) => arr.length !== 0,
  }),
  speakers: Yup.mixed().test({
    message: 'Devi inserire almeno un relatore',
    test: (arr) => arr.length !== 0,
  }),
  usersMax: Yup.number().when('type', {
    is: MedicalEventType.CLASSROOM,
    then: Yup.number().required('Campo obbligatorio'),
  }),
})

interface EventFormProps {
  open: boolean
  onClose: () => void
  onCreate: () => void
  onUpdate: () => void
  event: EventType | null
}

const EventForm: React.FC<EventFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  event,
}) => {
  const classes = useStyle()
  const [currentDate, setCurrentDate] = useState<Date | null | undefined>(null)
  const [currentTimeStart, setCurrentTimeStart] = useState<
    Date | null | undefined
  >(null)
  const [currentTimeEnd, setCurrentTimeEnd] = useState<Date | null | undefined>(
    null
  )
  const [currentSpeaker, setCurrentSpeaker] = useState('')

  // const formError = false
  const [createEventMutation, { error: createError }] = useMutation<
    createMedicalEvent,
    createMedicalEventVariables
  >(CREATE_EVENT, {
    onCompleted: async (data) => {
      formik.resetForm()
      onCreate()
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })
  const [updateEventMutation, { error: updateError }] = useMutation<
    updateMedicalEvent,
    updateMedicalEventVariables
  >(UPDATE_EVENT, {
    onCompleted: async (data) => {
      formik.resetForm()
      onCreate()
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const update = !!event

  // const [initialValues] = useState(
  //   event ? parseInitialValue(event) : defaultInitialValues
  // )
  // const initialValues = update ? parseInitialValue(event) : defaultInitialValues

  // useEffect()

  const formik = useFormik<EventFormValues>({
    initialValues: event
      ? parseInitialValue(event as EventType)
      : defaultInitialValues,
    // initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: createMedicalEventVariables | updateMedicalEventVariables
      if (!update) {
        // create
        variables = {
          eventData: {
            title: values.title,
            tagline: values.tagline,
            registrationDetails: values.registrationDetails,
            registrationDetails2: values.registrationDetails2,
            registrationConfirmation: values.registrationConfirmation,
            webinarLink: values.webinarLink !== '' ? values.webinarLink : null,
            googleMapsLink:
              values.googleMapsLink !== '' ? values.googleMapsLink : null,
            address: values.address !== '' ? values.address : null,
            location: values.location !== '' ? values.location : null,
            city: values.city !== '' ? values.city : null,
            agent: values.agent !== '' ? values.agent : null,
            cover: values.cover !== '' ? values.cover : null,
            speakers: values.speakers,
            type: values.type as MedicalEventType,
            status: values.status as MedicalEventStatus,
            deadline: (values.deadline as Date).toISOString(),
            dates: values.dates.map((date) => {
              return {
                start: (date.start as Date).toISOString(),
                end: (date.end as Date).toISOString(),
              }
            }),
            open: values.open,
            listed: values.listed,
            hideEmail: values.hideEmail,
            schedule: values.schedule !== '' ? values.schedule : null,
            curriculum: values.curriculum !== '' ? values.curriculum : null,
            attachment1: values.attachment1 !== '' ? values.attachment1 : null,
            attachment2: values.attachment2 !== '' ? values.attachment2 : null,
            attachmentTitle1:
              values.attachmentTitle1 !== '' ? values.attachmentTitle1 : null,
            attachmentTitle2:
              values.attachmentTitle2 !== '' ? values.attachmentTitle2 : null,
            area:
              values.area !== ''
                ? (values.area as MedicalEventArea)
                : MedicalEventArea.NO_AREA,
          },
        }
        if (values.type === MedicalEventType.CLASSROOM) {
          variables.eventData.usersMax = values.usersMax
        }
        createEventMutation({ variables })
      } else {
        // update
        variables = {
          eventData: {
            title: values.title,
            tagline: values.tagline,
            registrationDetails: values.registrationDetails,
            registrationDetails2: values.registrationDetails2,
            registrationConfirmation: values.registrationConfirmation,
            webinarLink: values.webinarLink,
            googleMapsLink: values.googleMapsLink,
            address: values.address,
            location: values.location,
            city: values.city,
            agent: values.agent,
            attachmentTitle1: values.attachmentTitle1,
            attachmentTitle2: values.attachmentTitle2,
            cover: values.cover !== '' ? values.cover : null,
            attachment1: values.attachment1,
            attachment2: values.attachment2,
            speakers: values.speakers,
            type: values.type as MedicalEventType,
            status: values.status as MedicalEventStatus,
            area:
              values.area !== ''
                ? (values.area as MedicalEventArea)
                : MedicalEventArea.NO_AREA,
            deadline: (values.deadline as Date).toISOString(),
            dates: values.dates.map((date) => {
              return {
                start: (date.start as Date).toISOString(),
                end: (date.end as Date).toISOString(),
              }
            }),
            open: values.open,
            listed: values.listed,
            hideEmail: values.hideEmail,
            schedule: values.schedule,
            curriculum: values.curriculum,
          },
          eventId: (event as EventType).id,
        }
        if (values.type === MedicalEventType.CLASSROOM) {
          variables.eventData.usersMax = values.usersMax
        }
        updateEventMutation({ variables })
      }

      // const variables: { userData: UpdateUserInput; userId: string } = {
      //   userData: {
      //     name: values.name,
      //     lastname: values.lastname,
      //     email: values.email,
      //   },
      //   userId: user?.id as string,
      // }
      // await updateProfessionalMutation({ variables })
    },
  })

  return (
    <DialogForm
      open={open}
      title={update ? 'Modifica evento' : 'Crea evento'}
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: 'ESCI SENZA SALVARE',
          callback: () => {
            formik.resetForm()
            onClose()
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: 'SALVA E CHIUDI',
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: 'hidden', paddingTop: 10 }}>
        {/* <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Dati dell'evento
        </Typography> */}

        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            {/* TIPOLOGIA EVENTO */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={formik.touched.type && !!formik.errors.type}
                id="type-label"
              >
                Tipologia*
              </InputLabel>
              <Select
                name="type"
                value={formik.values.type}
                labelId="type-label"
                id="type"
                label="Tipologia*"
                error={formik.touched.type && !!formik.errors.type}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                // style={{ marginBottom: 20 }}
              >
                <MenuItem value={MedicalEventType.WEBINAR}>Webinar</MenuItem>
                <MenuItem value={MedicalEventType.CLASSROOM}>
                  In presenza
                </MenuItem>
              </Select>
              {formik.touched.type && !!formik.errors.type && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.type}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* STATO EVENTO */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={formik.touched.status && !!formik.errors.status}
                id="status-label"
              >
                Stato*
              </InputLabel>
              <Select
                name="status"
                value={formik.values.status}
                labelId="status-label"
                id="status"
                label="Stato*"
                error={formik.touched.status && !!formik.errors.status}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                // style={{ marginBottom: 20 }}
              >
                <MenuItem value={MedicalEventStatus.DRAFT}>Bozza</MenuItem>
                <MenuItem value={MedicalEventStatus.PUBLISHED}>
                  Pubblicato
                </MenuItem>
                <MenuItem value={MedicalEventStatus.ARCHIVED}>
                  Archiviato
                </MenuItem>
              </Select>
              {formik.touched.status && !!formik.errors.status && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.status}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlLabel
                label="Accetta iscrizioni"
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.open}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    name="open"
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlLabel
                label="Evento privato"
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={!formik.values.listed}
                    onChange={(val) =>
                      formik.setFieldValue('listed', !val.target.checked)
                    }
                    disabled={formik.isSubmitting}
                    name="listed"
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlLabel
                label="Disabilita email conferma"
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.hideEmail}
                    onChange={(val) =>
                      formik.setFieldValue('hideEmail', val.target.checked)
                    }
                    disabled={formik.isSubmitting}
                    name="hideEmail"
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* TITOLO */}
            <FormControl fullWidth={true}>
              <TextField
                name="title"
                error={formik.touched.title && !!formik.errors.title}
                helperText={
                  formik.touched.title &&
                  !!formik.errors.title &&
                  formik.errors.title
                }
                multiline={true}
                variant="outlined"
                id="title"
                size="small"
                label="Titolo*"
                value={formik.values.title}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* Testo evento 1 */}
            <FormControl fullWidth={true}>
              <TextField
                name="registrationDetails"
                error={
                  formik.touched.registrationDetails &&
                  !!formik.errors.registrationDetails
                }
                helperText={
                  formik.touched.registrationDetails &&
                  !!formik.errors.registrationDetails &&
                  formik.errors.registrationDetails
                }
                multiline={true}
                variant="outlined"
                id="registrationDetails"
                size="small"
                label="Testo informativo iniziale*"
                value={formik.values.registrationDetails}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* Testo evento 2 */}
            <FormControl fullWidth={true}>
              <TextField
                name="registrationDetails2"
                error={
                  formik.touched.registrationDetails2 &&
                  !!formik.errors.registrationDetails2
                }
                helperText={
                  formik.touched.registrationDetails2 &&
                  !!formik.errors.registrationDetails2 &&
                  formik.errors.registrationDetails2
                }
                multiline={true}
                variant="outlined"
                id="registrationDetails2"
                size="small"
                label="Testo informativo finale*"
                value={formik.values.registrationDetails2}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* MESSAGGIO ISCRIZIONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="registrationConfirmation"
                error={
                  formik.touched.registrationConfirmation &&
                  !!formik.errors.registrationConfirmation
                }
                helperText={
                  formik.touched.registrationConfirmation &&
                  !!formik.errors.registrationConfirmation &&
                  formik.errors.registrationConfirmation
                }
                multiline={true}
                variant="outlined"
                id="registrationConfirmation"
                size="small"
                label="Messaggio di conferma*"
                value={formik.values.registrationConfirmation}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* TAGLINE */}
            <FormControl fullWidth={true}>
              <TextField
                name="tagline"
                error={formik.touched.tagline && !!formik.errors.tagline}
                helperText={
                  formik.touched.tagline &&
                  !!formik.errors.tagline &&
                  formik.errors.tagline
                }
                multiline={true}
                variant="outlined"
                id="tagline"
                size="small"
                label="Tagline*"
                value={formik.values.tagline}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FileUpload
              id="event-cover"
              onChange={(filename) => {
                formik.setFieldValue('cover', filename)
              }}
              label="Carica la cover (formato 946x294)"
              labelSmall="Cover"
              url={formik.values.cover}
              media={MediaType.EVENT_COVER}
              error={formik.touched.cover && !!formik.errors.cover}
            />
            {formik.touched.cover && !!formik.errors.cover && (
              <FormHelperText error={true} variant="standard">
                {formik.errors.cover}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginBottom: '1em' }}
        >
          Inserisci una o più date
        </Typography>
        <Grid container spacing={4}>
          {/* DATE EVENTO */}
          <Grid item xs={6} md={3}>
            {/* Data */}
            <FormControl fullWidth={true}>
              <KeyboardDatePicker
                size="small"
                keyboardIcon={<CalendarToday />}
                autoOk
                variant="inline"
                error={formik.touched.dates && !!formik.errors.dates}
                inputVariant="outlined"
                label={currentDate ? 'Data evento' : null}
                format="dd/MM/yyyy"
                placeholder="Data evento"
                invalidDateMessage={'Formato non valido'}
                name="dates"
                value={currentDate}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date) => setCurrentDate(date as Date)}
              />
              {formik.touched.dates && !!formik.errors.dates && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.dates}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            {/* Ora inizio */}
            <FormControl fullWidth={true}>
              <KeyboardTimePicker
                size="small"
                keyboardIcon={<WatchIcon />}
                autoOk
                ampm={false}
                minutesStep={5}
                variant="inline"
                invalidDateMessage={'Formato non valido'}
                error={formik.touched.dates && !!formik.errors.dates}
                inputVariant="outlined"
                label={currentTimeStart ? 'Orario di inizio' : null}
                // format="hh:mm"
                placeholder="Ora di inizio"
                name="dates"
                value={currentTimeStart}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date) => setCurrentTimeStart(date as Date)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            {/* Ora fine */}
            <FormControl fullWidth={true}>
              <KeyboardTimePicker
                size="small"
                keyboardIcon={<WatchIcon />}
                autoOk
                variant="inline"
                ampm={false}
                minutesStep={5}
                invalidDateMessage={'Formato non valido'}
                error={formik.touched.dates && !!formik.errors.dates}
                inputVariant="outlined"
                label={currentTimeEnd ? 'Orario di fine' : null}
                // format="hh:mm"
                placeholder="Ora di fine"
                name="dates"
                value={currentTimeEnd}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date) => {
                  setCurrentTimeEnd(date as Date)
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            // alignContent="center"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton
              className={classes.addButton}
              // variant=""
              color="primary"
              size="small"
              disabled={
                !(
                  currentDate &&
                  currentTimeStart &&
                  currentTimeEnd &&
                  isValidDate(currentTimeStart) &&
                  isValidDate(currentDate) &&
                  isValidDate(currentTimeEnd)
                )
              }
              onClick={() => {
                const start = new Date(
                  (currentDate as Date)?.getFullYear(),
                  (currentDate as Date)?.getMonth(),
                  (currentDate as Date)?.getDate(),
                  (currentTimeStart as Date).getHours(),
                  (currentTimeStart as Date).getUTCMinutes()
                )
                const end = new Date(
                  (currentDate as Date)?.getFullYear(),
                  (currentDate as Date)?.getMonth(),
                  (currentDate as Date)?.getDate(),
                  (currentTimeEnd as Date).getHours(),
                  (currentTimeEnd as Date).getUTCMinutes()
                )
                formik.setFieldValue('dates', [
                  ...formik.values.dates,
                  { start, end },
                ])
                setCurrentDate(null)
                setCurrentTimeStart(null)
                setCurrentTimeEnd(null)
              }}
            >
              <AddIcon htmlColor="#ffffff" />
            </IconButton>
          </Grid>
          {formik.values.dates.length > 0 && (
            <Grid item xs={12} md={12}>
              {formik.values.dates.map((item, index) => {
                const patternStart = 'dd/MM/yyyy (HH:mm - '
                const patternEnd = 'HH:mm) (z)'
                return (
                  <Chip
                    style={{ marginRight: 10, marginBottom: 10 }}
                    key={`${index}_${(item.start as Date).toISOString()}:${(item.end as Date).toISOString()}`}
                    color="primary"
                    label={
                      format(item.start, patternStart) +
                      format(item.end, patternEnd)
                    }
                    onDelete={() => {
                      formik.setFieldValue('dates', [
                        ...formik.values.dates.filter((_item) => {
                          return (
                            _item.start !== item.start && _item.end !== item.end
                          )
                        }),
                      ])
                    }}
                  />
                )
              })}
            </Grid>
          )}
        </Grid>
        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginBottom: '1em' }}
        >
          Inserisci uno o più relatori
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={10} md={6}>
            {/* Relatori */}
            <FormControl fullWidth={true}>
              <TextField
                name="speakers"
                error={formik.touched.speakers && !!formik.errors.speakers}
                helperText={
                  formik.touched.speakers &&
                  !!formik.errors.speakers &&
                  formik.errors.speakers
                }
                variant="outlined"
                id="speakers"
                size="small"
                label="Relatore"
                value={currentSpeaker}
                onChange={(e) => setCurrentSpeaker(e.target.value as string)}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            // alignContent="center"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton
              // variant=""
              className={classes.addButton}
              color="primary"
              size="small"
              disabled={currentSpeaker.length === 0}
              onClick={() => {
                // Aggiungi ai values
                formik.setFieldValue('speakers', [
                  ...formik.values.speakers,
                  currentSpeaker,
                ])
                setCurrentSpeaker('')
              }}
            >
              <AddIcon htmlColor="#ffffff" />
            </IconButton>
          </Grid>
          {formik.values.speakers.length > 0 && (
            <Grid item xs={12} md={12}>
              {formik.values.speakers.map((item) => {
                return (
                  <Chip
                    style={{ marginRight: 10, marginBottom: 10 }}
                    key={item}
                    color="primary"
                    label={item}
                    onDelete={() => {
                      formik.setFieldValue('speakers', [
                        ...formik.values.speakers.filter((_item) => {
                          return _item !== item
                        }),
                      ])
                    }}
                  />
                )
              })}
            </Grid>
          )}
        </Grid>

        {formik.values.type === MedicalEventType.CLASSROOM && (
          <>
            <Typography
              variant="h3"
              color="textPrimary"
              style={{ marginBottom: '1em' }}
            >
              Informazioni aggiuntive
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                {/* AREA GEOGRAFICA EVENTO */}
                <FormControl fullWidth={true} variant="outlined" size="small">
                  <InputLabel
                    error={formik.touched.area && !!formik.errors.area}
                    id="area-label"
                  >
                    Area geografica*
                  </InputLabel>
                  <Select
                    name="area"
                    value={formik.values.area}
                    labelId="area-label"
                    id="area"
                    label="Area geografica*"
                    error={formik.touched.area && !!formik.errors.area}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    // style={{ marginBottom: 20 }}
                  >
                    <MenuItem value={MedicalEventArea.ABRUZZO_E_MOLISE}>
                      Abruzzo e Molise
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.EMILIA_ROMAGNA}>
                      Emilia Romagna
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.FRIULI_VENEZIA_GIULIA}>
                      Friuli Venezia Giulia
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.LAZIO}>Lazio</MenuItem>
                    <MenuItem value={MedicalEventArea.LIGURIA}>
                      Liguria
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.LOMBARDIA}>
                      Lombardia
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.MARCHE}>Marche</MenuItem>
                    <MenuItem value={MedicalEventArea.PIEMONTE_VALLE_D_AOSTA}>
                      Piemonte e Valle d'Aosta
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.SARDEGNA}>
                      Sardegna
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.SUD_ITALIA}>
                      Sud Italia
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.TOSCANA}>
                      Toscana
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.TRENTINO_ALTO_ADIGE}>
                      Trentino ALto Adige
                    </MenuItem>
                    <MenuItem value={MedicalEventArea.UMBRIA}>Umbria</MenuItem>
                    <MenuItem value={MedicalEventArea.VENETO}>Veneto</MenuItem>
                  </Select>
                  {formik.touched.area && !!formik.errors.area && (
                    <FormHelperText error={true} variant="standard">
                      {formik.errors.area}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Link Google Maps */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="googleMapsLink"
                    error={
                      formik.touched.googleMapsLink &&
                      !!formik.errors.googleMapsLink
                    }
                    helperText={
                      formik.touched.googleMapsLink &&
                      !!formik.errors.googleMapsLink &&
                      formik.errors.googleMapsLink
                    }
                    variant="outlined"
                    id="googleMapsLink"
                    size="small"
                    label="Link Google Maps"
                    value={formik.values.googleMapsLink}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Addresss */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="address"
                    error={formik.touched.address && !!formik.errors.address}
                    helperText={
                      formik.touched.address &&
                      !!formik.errors.address &&
                      formik.errors.address
                    }
                    variant="outlined"
                    id="address"
                    size="small"
                    label="Indirizzo*"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Location */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="location"
                    error={formik.touched.location && !!formik.errors.location}
                    helperText={
                      formik.touched.location &&
                      !!formik.errors.location &&
                      formik.errors.location
                    }
                    variant="outlined"
                    id="location"
                    size="small"
                    label="Location*"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Città */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="city"
                    error={formik.touched.city && !!formik.errors.city}
                    helperText={
                      formik.touched.city &&
                      !!formik.errors.city &&
                      formik.errors.city
                    }
                    variant="outlined"
                    id="city"
                    size="small"
                    label="Città (uso interno)"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Agente */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="agent"
                    error={formik.touched.agent && !!formik.errors.agent}
                    helperText={
                      formik.touched.agent &&
                      !!formik.errors.agent &&
                      formik.errors.agent
                    }
                    variant="outlined"
                    id="agent"
                    size="small"
                    label="Agente (uso interno)"
                    value={formik.values.agent}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginBottom: '1em' }}
        >
          Vincoli iscrizione
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* Deadline */}
            <FormControl fullWidth={true}>
              <KeyboardDateTimePicker
                size="small"
                keyboardIcon={<CalendarToday />}
                autoOk
                variant="inline"
                error={
                  formik.touched.deadline && !!formik.errors.deadline
                  // !isValidDate(formik.values.deadline)
                }
                inputVariant="outlined"
                label={currentDate ? 'Deadline' : null}
                format="dd/MM/yyyy HH:mm"
                placeholder="Deadline"
                name="deadline"
                value={formik.values.deadline}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date) => formik.setFieldValue('deadline', date)}
                invalidDateMessage={''}
              />
              {formik.touched.deadline && !!formik.errors.deadline && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.deadline}
                </FormHelperText>
              )}
              {/* {!isValidDate(formik.values.deadline) && (
                <FormHelperText error={true} variant="standard">
                  Invalid format
                </FormHelperText>
              )} */}
            </FormControl>
          </Grid>
          {formik.values.type === MedicalEventType.CLASSROOM && (
            <Grid item xs={12} md={6}>
              {/* Numero massimo di iscritti */}
              <FormControl fullWidth={true}>
                <TextField
                  name="maxUsers"
                  type="number"
                  // inputProps={{ type: 'number' }}
                  error={formik.touched.usersMax && !!formik.errors.usersMax}
                  helperText={
                    formik.touched.usersMax &&
                    !!formik.errors.usersMax &&
                    formik.errors.usersMax
                  }
                  variant="outlined"
                  id="usersMax"
                  size="small"
                  label="Numero massimo di partecipanti*"
                  value={formik.values.usersMax}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'usersMax',
                      parseInt(e.target.value, 10)
                    )
                  }}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            </Grid>
          )}
          {formik.values.type === MedicalEventType.WEBINAR && (
            <Grid item xs={12} md={6}>
              {/* Link webinar */}
              <FormControl fullWidth={true}>
                <TextField
                  name="webinarLink"
                  error={
                    formik.touched.webinarLink && !!formik.errors.webinarLink
                  }
                  helperText={
                    formik.touched.webinarLink &&
                    !!formik.errors.webinarLink &&
                    formik.errors.webinarLink
                  }
                  variant="outlined"
                  id="webinarLink"
                  size="small"
                  label="Link webinar"
                  value={formik.values.webinarLink}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>

        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginBottom: '1em' }}
        >
          Documenti associati
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FileUpload
              id="curriculum"
              onChange={(filename) => {
                formik.setFieldValue('curriculum', filename)
              }}
              label="Carica il curriculum dei partecipanti"
              labelSmall="Curriculum"
              url={formik.values.curriculum}
              media={MediaType.EVENT_CURRICULUM}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FileUpload
              id="schedule"
              onChange={(filename) => {
                formik.setFieldValue('schedule', filename)
              }}
              label="Carica il programma dell'evento"
              labelSmall="Programma"
              url={formik.values.schedule}
              media={MediaType.EVENT_SCHEDULE}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginBottom: '1em' }}
        >
          Documenti aggiuntivi
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FileUpload
              id="attachment1"
              onChange={(filename) => {
                formik.setFieldValue('attachment1', filename)
              }}
              label="Carica il primo allegato"
              labelSmall="Allegato"
              url={formik.values.attachment1}
              media={MediaType.EVENT_ATTACHMENT}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth={true}>
              <TextField
                name="attachmentTitle1"
                error={
                  formik.touched.attachmentTitle1 &&
                  !!formik.errors.attachmentTitle1
                }
                helperText={
                  formik.touched.attachmentTitle1 &&
                  !!formik.errors.attachmentTitle1 &&
                  formik.errors.attachmentTitle1
                }
                variant="outlined"
                id="attachmentTitle1"
                size="small"
                label="Titolo primo allegato"
                value={formik.values.attachmentTitle1}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FileUpload
              id="attachment2"
              onChange={(filename) => {
                formik.setFieldValue('attachment2', filename)
              }}
              label="Carica il secondo allegato"
              labelSmall="Allegato"
              url={formik.values.attachment2}
              media={MediaType.EVENT_ATTACHMENT}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth={true}>
              <TextField
                name="attachmentTitle2"
                error={
                  formik.touched.attachmentTitle2 &&
                  !!formik.errors.attachmentTitle2
                }
                helperText={
                  formik.touched.attachmentTitle2 &&
                  !!formik.errors.attachmentTitle2 &&
                  formik.errors.attachmentTitle2
                }
                variant="outlined"
                id="attachmentTitle2"
                size="small"
                label="Titolo secondo allegato"
                value={formik.values.attachmentTitle2}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>

        {(createError || updateError) && (
          <Typography color="error" variant="body1">
            {createError && formatError(createError)}
            {updateError && formatError(updateError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  )
}

export default EventForm
