import React from 'react'
import { Breadcrumbs, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    margin: '20px auto 20px',
    [theme.breakpoints.up('md')]: {
      margin: '15px auto 25px',
    },
  },
}))

const Breadcrumb: React.FC<{ current: string }> = ({ current }) => {
  const classes = useStyles()
  return (
    <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
      <Typography
        color="textSecondary"
        variant="inherit"
        component="span"
        style={{ marginTop: '0.75em' }}
      >
        Home
      </Typography>
      <Typography
        color="textPrimary"
        variant="inherit"
        component="span"
        style={{ marginTop: '0.75em' }}
      >
        {current}
      </Typography>
    </Breadcrumbs>
  )
}

export default Breadcrumb
