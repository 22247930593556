import React, { useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  Button,
  Dialog,
  IconButton,
  Divider,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { GET_EVENT, MY_EVENTS } from 'query'
import { userIdVar } from 'cache'
import {
  getMedicalEventVariables,
  getMedicalEvent_getMedicalEvent,
  getMedicalEvent,
  myEvents,
  myEventsVariables,
} from 'types/generated/schemaTypes'
import { Breadcrumb, EventItem, Title, NoResults } from 'components'
import { format, isAfter, isBefore } from 'date-fns'

const useStyle = makeStyles((theme) => ({
  block: {
    margin: '0 auto 30px',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto 50px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  preview: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        padding: '65px 105px 60px 105px',
      },
    },
  },
}))

const EventListItem: React.FC<{
  event: Partial<getMedicalEvent_getMedicalEvent>
  onClick: () => void
}> = ({ event, onClick }) => {
  return (
    <div>
      <Typography variant="h3" style={{ fontWeight: 500 }}>
        {event.title} (
        {format(new Date(event.dates && event.dates[0].start), 'dd/MM/yyyy')})
      </Typography>
      <Button variant="outlined" size="small" onClick={onClick}>
        VEDI DETTAGLI
      </Button>
    </div>
  )
}

export default function Eventi() {
  const classes = useStyle()
  const [
    currentEvent,
    setCurrentEvent,
  ] = useState<null | getMedicalEvent_getMedicalEvent>(null)

  const theme = useTheme()

  const [showEventDetail, setShowEventDetail] = useState(false)

  const {
    data: myEventsData,
    loading: myEventsLoading,
    error: myEventsError,
  } = useQuery<myEvents, myEventsVariables>(MY_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      id: userIdVar() as string,
    },
  })

  const [getEvent] = useLazyQuery<getMedicalEvent, getMedicalEventVariables>(
    GET_EVENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setCurrentEvent(data.getMedicalEvent)
        setShowEventDetail(true)
      },
    }
  )

  const pastEvents =
    myEventsData && myEventsData.getUser && myEventsData.getUser.events
      ? myEventsData?.getUser?.events?.filter((event) => {
          const now = new Date()
          if (event.dates.length === 1) {
            return isAfter(now, new Date(event.dates[0].start))
          } else {
            return isAfter(
              now,
              new Date(event.dates[event.dates.length - 1].start)
            )
          }
        })
      : []

  const currentEvents =
    myEventsData && myEventsData.getUser && myEventsData.getUser.events
      ? myEventsData.getUser?.events?.filter((event) => {
          const now = new Date()
          if (event.dates.length === 1) {
            return isBefore(now, new Date(event.dates[0].start))
          } else {
            return isBefore(
              now,
              new Date(event.dates[event.dates.length - 1].start)
            )
          }
        })
      : []

  return (
    <>
      <Title title="I miei eventi" />
      <Breadcrumb current="I miei eventi" />

      {showEventDetail && currentEvent && (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          className={classes.preview}
          scroll="body"
          open={showEventDetail}
          onClose={() => {
            setShowEventDetail(false)
          }}
        >
          <IconButton
            aria-label="chiudi"
            className={classes.closeButton}
            onClick={() => {
              setShowEventDetail(false)
            }}
          >
            <Close htmlColor={theme.palette.text.primary} />
          </IconButton>
          <EventItem
            event={currentEvent}
            preview={true}
            canRegister={false}
            infoMode={true}
            userRegistered={true}
          />
        </Dialog>
      )}
      {myEventsLoading && <div>Caricamento degli eventi in corso</div>}
      {!myEventsLoading && !myEventsError && myEventsData && (
        <>
          <Typography variant="h2" style={{ marginBottom: '0.5em' }}>
            Eventi in corso
          </Typography>
          {currentEvents.length === 0 ? (
            <div className={classes.block}>
              <NoResults>Nessun evento in programma.</NoResults>
            </div>
          ) : (
            <>
              <Divider style={{ margin: '20px auto 15px' }} />
              <ul className={classes.block}>
                {currentEvents?.map((event) => {
                  return (
                    <li style={{ listStyle: 'none' }} key={event.id}>
                      <EventListItem
                        event={event}
                        onClick={() =>
                          getEvent({ variables: { id: event.id } })
                        }
                      />
                      <Divider style={{ margin: '20px auto' }} />
                    </li>
                  )
                })}
              </ul>
            </>
          )}
          <Typography variant="h2" style={{ marginBottom: '0.5em' }}>
            Eventi passati
          </Typography>
          {pastEvents.length === 0 ? (
            <div className={classes.block}>
              <NoResults>Non hai partecipato a nessun evento.</NoResults>
            </div>
          ) : (
            <>
              <Divider style={{ margin: '20px auto 15px' }} />
              <ul className={classes.block}>
                {pastEvents?.map((event) => {
                  return (
                    <li style={{ listStyle: 'none' }} key={event.id}>
                      <EventListItem
                        event={event}
                        onClick={() =>
                          getEvent({ variables: { id: event.id } })
                        }
                      />
                      <Divider style={{ margin: '20px auto' }} />
                    </li>
                  )
                })}
              </ul>
            </>
          )}
        </>
      )}
    </>
  )
}
