import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { userIdVar } from 'cache'
import { SEND_REQUEST_INNER } from 'query'
import {
  sendContactRequestInner,
  sendContactRequestInnerVariables,
} from 'types/generated/schemaTypes'
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Send } from '@material-ui/icons'
import { formatError } from 'utils'

export interface ContactFormValues {
  subject: string
  message: string
  privacy: boolean
}

const defaultInitialValues = {
  subject: '',
  message: '',
  privacy: false,
}

const validationSchema = Yup.object({
  subject: Yup.string().required('Campo obbligatorio'),
  message: Yup.string().required('Campo obbligatorio'),
})

interface ContactFormProps {
  onSuccess: () => void
}

const ContactForm: React.FC<ContactFormProps> = ({ onSuccess }) => {
  const [formError, setFormError] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [sendRequestQuery] = useLazyQuery<
    sendContactRequestInner,
    sendContactRequestInnerVariables
  >(SEND_REQUEST_INNER, {
    onError: (err) => {
      setFormError(formatError(err))
      setIsSending(false)
    },
    onCompleted: () => {
      setIsSending(false)
      formik.resetForm()
      onSuccess()
    },
  })
  const formik = useFormik<ContactFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSending(true)
      sendRequestQuery({
        variables: {
          userId: userIdVar() as string,
          subject: values.subject,
          message: values.message,
        },
      })
    },
  })

  return (
    <div>
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Utilizza il form sottostante per inviarci la tua richiesta
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* OGGETTO */}
            <FormControl fullWidth={true}>
              <TextField
                inputProps={{ maxLength: 50 }}
                name="subject"
                error={formik.touched.subject && !!formik.errors.subject}
                helperText={
                  (formik.touched.subject &&
                    !!formik.errors.subject &&
                    formik.errors.subject) ||
                  "Inserisci l'oggetto della richiesta"
                }
                variant="outlined"
                id="subject"
                size="small"
                label="Oggetto*"
                value={formik.values.subject}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* MESSAGGIO */}
            <FormControl fullWidth={true}>
              <TextField
                name="message"
                inputProps={{ maxLength: 500 }}
                error={formik.touched.message && !!formik.errors.message}
                helperText={
                  (formik.touched.message &&
                    !!formik.errors.message &&
                    formik.errors.message) ||
                  'Inserisci il tuo messaggio (max 500 caratteri)'
                }
                variant="outlined"
                id="message"
                size="small"
                label="Messaggio*"
                value={formik.values.message}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                multiline={true}
                rows={4}
              />
            </FormControl>
          </Grid>
        </Grid>

        {formError && (
          <Typography color="error" variant="body1">
            {formError}
          </Typography>
        )}

        <Button
          startIcon={<Send />}
          onClick={formik.submitForm}
          disabled={isSending}
          color="primary"
          size="small"
          variant="contained"
        >
          INVIA
        </Button>
      </div>
    </div>
  )
}

export default ContactForm
