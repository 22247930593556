import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto 25px',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto 0',
    },
  },
}))

const FiltersWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default FiltersWrapper
