import React, { ReactNode, useState } from 'react'

import {
  Drawer,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Close as CloseIcon } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import { ApplicationBar, Footer, VerticalNav } from 'components'

interface StyleProps {
  // showFilters: boolean
  large: boolean
}

const MAX_WIDTH = 920
const MAX_WIDTH_LARGE = 1120

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    width: '100%',
    overflowX: 'hidden',
    minHeight: '100vh',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
  },

  navigationWrapper: (props: StyleProps) => ({
    position: 'relative',
    zIndex: 1,
  }),
  contentWrapper: (props: StyleProps) => ({
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 56,
    width: 'calc(100% - 30px)',
    // paddingBottom: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
      // paddingBottom: 64,
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: props.large ? MAX_WIDTH_LARGE : MAX_WIDTH,
      marginLeft: `calc(50vw + 140px - ${
        (props.large ? MAX_WIDTH_LARGE : MAX_WIDTH) / 2
      }px)`,
      marginRight: 'auto',
    },
  }),
  spacer: {
    minHeight: `${theme.mixins.toolbar.minHeight}px`,
  },
}))

const PageLayout: React.FC<{ children?: ReactNode; large?: boolean }> = ({
  children = null,
  large = false,
}) => {
  const theme = useTheme()
  const desktopLayout = useMediaQuery(theme.breakpoints.up('lg'))
  const [isDrowerOpen, setIsDrawerOpen] = useState(false)
  const classes = useStyles({ large })
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <ApplicationBar onOpenMenu={() => setIsDrawerOpen(true)} />
        <main className={classes.main}>
          <div className={classes.navigationWrapper}>
            <Drawer
              open={desktopLayout ? true : isDrowerOpen}
              variant={desktopLayout ? 'persistent' : 'temporary'}
              onClose={() => setIsDrawerOpen(false)}
              PaperProps={{
                style: {
                  backgroundColor: 'rgba(250,250,250,1)',
                  zIndex: theme.zIndex.drawer - 1,
                },
              }}
            >
              {!desktopLayout && (
                <div style={{ textAlign: 'right' }}>
                  <IconButton onClick={() => setIsDrawerOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              {desktopLayout && <div className={classes.spacer}></div>}
              <VerticalNav />
            </Drawer>
          </div>
          <div className={classes.contentWrapper}>{children}</div>
          <Footer />
        </main>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default PageLayout
