import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { colors } from 'theme'
import { isLoggedInVar } from 'cache'
import {
  Description as DescriptionIcon,
  EventNote as EventNoteIcon,
  EventAvailable as EventAvailableIcon,
  Person as PersonIcon,
  HelpOutline as HelpOutlineIcon,
  SvgIconComponent,
  GroupAdd as GroupAddIcon,
  People as PeopleIcon,
  PeopleOutline as PeopleOutlineIcon,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons'
import { roleVar } from 'cache'
import { UserRole } from 'types/generated/schemaTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 2),
    },
  },
  itemMenu: {
    paddingTop: 8,
    paddingBottom: 8,
    '&.Mui-selected': {
      backgroundColor: 'rgba(240,240,240,1)',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgba(240,240,240,1)',
    },
  },
}))

interface MenuItemProps {
  icon: React.ReactElement<SvgIconComponent>
  label: string
  to: string
  indent?: boolean
}

function CustomMenuItem(props: MenuItemProps) {
  const { label, to, icon, indent = false } = props
  const classes = useStyles()
  const match = useRouteMatch({
    path: to,
  })
  return (
    <RouterLink to={to} style={{ textDecoration: 'none' }}>
      <MenuItem className={classes.itemMenu} selected={!!match}>
        <ListItemIcon
          color={colors.text.primary}
          style={{ minWidth: 0, marginRight: 15, marginLeft: indent ? 15 : 0 }}
        >
          <icon.type {...icon.props} {...{ htmlColor: colors.text.primary }} />
        </ListItemIcon>
        <ListItemText
          disableTypography={false}
          primaryTypographyProps={{
            variant: 'body2',
            color: 'textPrimary',
            style: { position: 'relative', top: 1 },
          }}
        >
          {label}
        </ListItemText>
      </MenuItem>
    </RouterLink>
  )
}

function LogoutItem() {
  const classes = useStyles()
  return (
    <MenuItem
      className={classes.itemMenu}
      onClick={() => {
        localStorage.removeItem('b_role')
        localStorage.removeItem('b_userId')
        localStorage.removeItem('b_token')
        isLoggedInVar(false)
      }}
    >
      <ListItemIcon
        color={colors.text.primary}
        style={{ minWidth: 0, marginRight: 15 }}
      >
        <ExitToAppIcon htmlColor={colors.text.primary} />
      </ListItemIcon>
      <ListItemText
        disableTypography={false}
        primaryTypographyProps={{
          variant: 'body2',
          color: 'textPrimary',
          style: { position: 'relative', top: 1 },
        }}
      >
        Esci
      </ListItemText>
    </MenuItem>
  )
}

const VerticalNav: React.FC = () => {
  const roleCache = useReactiveVar(roleVar)
  const theme = useTheme()
  const desktopLayout = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <nav style={{ position: 'relative', top: desktopLayout ? 0 : -20 }}>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ padding: `${desktopLayout ? '2em' : 0} 20px 3px 20px` }}
      >
        MENU
      </Typography>
      <div style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
        {roleCache === UserRole.PROFESSIONAL && (
          <>
            <CustomMenuItem
              to="/eventi"
              label="Tutti gli eventi"
              icon={<EventNoteIcon />}
            />
            <CustomMenuItem
              to="/eventi-webinar"
              label="Eventi webinar"
              icon={<EventNoteIcon />}
              indent={true}
            />
            <CustomMenuItem
              to="/eventi-area-geografica"
              label="Eventi in presenza"
              icon={<EventNoteIcon />}
              indent={true}
            />
            <CustomMenuItem
              to="/eventi-archiviati"
              label="Archivio eventi"
              icon={<EventNoteIcon />}
            />
            <CustomMenuItem
              to="/eventi-personali"
              label="I miei eventi"
              icon={<EventAvailableIcon />}
            />
            {/* <CustomMenuItem
              to="/documenti"
              label="Documenti scientifici"
              icon={<VerticalSplitIcon />}
            /> */}
          </>
        )}
        {[UserRole.OPERATOR, UserRole.ADMIN].includes(roleCache) && (
          <>
            <CustomMenuItem
              to="/gestione-eventi"
              label="Gestione eventi"
              icon={<EventNoteIcon />}
            />
            <CustomMenuItem
              to="/gestione-documenti"
              label="Gestione documenti"
              icon={<DescriptionIcon />}
            />
            {/* <CustomMenuItem
              to="/gestione-eventi"
              label="Gestione eventi"
              icon={<EventNoteIcon />}
            /> */}
            <CustomMenuItem
              to="/attivazione-utenti"
              label="Utenti inattivi"
              icon={<GroupAddIcon />}
            />
            <CustomMenuItem
              to="/gestione-utenti"
              label="Gestione utenti"
              icon={<PeopleIcon />}
            />

            {/* <CustomMenuItem
              to="/gestione-operatori"
              label="Gestione operatori"
              icon={<EventNoteIcon />}
            />
            <CustomMenuItem
              to="/gestione-documenti"
              label="Gestione documenti"
              icon={<EventNoteIcon />}
            /> */}
          </>
        )}
        {[UserRole.ADMIN].includes(roleCache) && (
          <CustomMenuItem
            to="/gestione-operatori"
            label="Gestione operatori"
            icon={<PeopleOutlineIcon />}
          />
        )}
      </div>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ padding: '2em 20px 3px 20px' }}
      >
        HELP
      </Typography>
      <div style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
        {roleCache === UserRole.PROFESSIONAL && (
          <CustomMenuItem
            to="/contatti"
            label="Contattaci"
            icon={<HelpOutlineIcon />}
          />
        )}
        <CustomMenuItem to="/profilo" label="Profilo" icon={<PersonIcon />} />
        <LogoutItem />
      </div>
    </nav>
  )
}

export default VerticalNav
