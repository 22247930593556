import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Banner, Breadcrumb, Title } from 'components'

export default function Eventi() {
  return (
    <>
      <Banner />
      <Title title="Eventi residenziali (in presenza)" />
      <Breadcrumb current="Eventi residenziali (in presenza)" />
      <Typography variant="h2">Seleziona un'area geografica</Typography>
      <br />
      <Link
        to={'eventi-in-presenza?area=ABRUZZO_E_MOLISE'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Abruzzo e Molise</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=EMILIA_ROMAGNA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Emilia Romagna</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=FRIULI_VENEZIA_GIULIA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Friuli Venezia Giulia</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=LAZIO'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Lazio</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=LIGURIA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Liguria</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=LOMBARDIA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Lombardia</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=MARCHE'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Marche</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=PIEMONTE_VALLE_D_AOSTA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Piemonte e Valle d'Aosta</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=SARDEGNA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Sardegna</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=SUD_ITALIA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Sud Italia</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=TOSCANA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Toscana</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=TRENTINO_ALTO_ADIGE'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Trentino Alto Adige</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=UMBRIA'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Umbria</Typography>
      </Link>
      <br />
      <Link
        to={'eventi-in-presenza?area=VENETO'}
        style={{ textDecoration: 'none' }}
      >
        <Typography variant="h4">Veneto</Typography>
      </Link>
      <br />
    </>
  )
}
