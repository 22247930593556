import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Banner, Breadcrumb, Title } from 'components'

export default function Eventi() {
  return (
    <>
      <Banner />
      <Title title="Eventi" />
      <Breadcrumb current="Eventi" />
      <Typography variant="h2">Seleziona una tipologia di eventi</Typography>
      <br />
      <Link to={'eventi-area-geografica'} style={{ textDecoration: 'none' }}>
        <Typography variant="h4">
          - Eventi residenziali (in presenza)
        </Typography>
      </Link>
      <br />
      <Link to={'eventi-webinar'} style={{ textDecoration: 'none' }}>
        <Typography variant="h4">- Eventi in modalità Webinar</Typography>
      </Link>
    </>
  )
}
