import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  makeStyles,
  useTheme,
  Tooltip,
} from '@material-ui/core'
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Create as CreateIcon,
  Visibility as PreviewIcon,
  Close,
} from '@material-ui/icons'
import { GET_DOCUMENTS } from 'query'
import { DELETE_DOCUMENTS } from 'mutation'
import {
  deleteDocuments,
  deleteDocumentsVariables,
  getDocuments,
  getDocumentsVariables,
  getDocuments_getDocuments_documents as DocumentType,
  DocumentWhereInput,
  DocumentOrderByInput,
} from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  Title,
  DialogWarning,
  DocumentForm,
  FiltersWrapper,
  DocumentItem,
} from 'components'
// import

const useStyle = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  preview: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        padding: '65px 105px 60px 105px',
      },
    },
  },
}))

export default function GestioneDocumenti() {
  const classes = useStyle()
  const theme = useTheme()
  const [currentDocument, setCurrentDocument] = useState<null | DocumentType>(
    null
  )
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [orderBy, setOrderBy] = useState<DocumentOrderByInput>(
    DocumentOrderByInput.TITLE_ASC
  )
  const [showDocumentPreview, setShowDocumentPreview] = useState(false)
  const [showDocument, setShowDocumentForm] = useState(false)
  const [actionCallback, setActionCallback] = useState<() => () => void>(
    () => () => {}
  )
  const [showWarning, setShowWarning] = useState(false)
  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10))
    setOffset(0)
  }

  const where: DocumentWhereInput = {}

  const {
    data: documentsData,
    loading: documentsLoading,
    error: documentsError,
    refetch: refetchDocuments,
  } = useQuery<getDocuments, getDocumentsVariables>(GET_DOCUMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderby: orderBy,
      offset,
      limit,
    },
  })

  const [deleteDocumentsMutation, { loading: deleteLoading }] = useMutation<
    deleteDocuments,
    deleteDocumentsVariables
  >(DELETE_DOCUMENTS, {
    onCompleted: async (data) => {
      setOffset(0)
      await refetchDocuments({
        where,
        orderby: orderBy,
        offset,
        limit,
      })
      setShowWarning(false)
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const handleDelete = (id: string) => {
    setShowWarning(true)
    setActionCallback(() => async () => {
      await deleteDocumentsMutation({ variables: { ids: [id] } })
    })
  }

  const handleUpdate = async (doc: DocumentType) => {
    setCurrentDocument(doc)
    setShowDocumentForm(true)
  }

  const handlePreview = async (doc: DocumentType) => {
    setCurrentDocument(doc)
    setShowDocumentPreview(true)
  }

  return (
    <>
      <DocumentForm
        open={showDocument}
        onClose={() => {
          setShowDocumentForm(false)
        }}
        onCreate={async () => {
          setShowDocumentForm(false)
          await refetchDocuments({
            where,
            orderby: orderBy,
            offset,
            limit,
          })
        }}
        onUpdate={async () => {
          setShowDocumentForm(false)
          // await refetchDocuments()
        }}
        document={currentDocument}
      />

      {showDocumentPreview && currentDocument && (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          className={classes.preview}
          scroll="body"
          open={showDocumentPreview}
          onClose={() => {
            setShowDocumentPreview(false)
          }}
        >
          <IconButton
            aria-label="chiudi"
            className={classes.closeButton}
            onClick={() => {
              setShowDocumentPreview(false)
            }}
          >
            <Close htmlColor={theme.palette.text.primary} />
          </IconButton>
          <div style={{ maxWidth: 400, margin: '0 auto' }}>
            <DocumentItem document={currentDocument} />
          </div>
        </Dialog>
      )}

      <DialogWarning
        onContinue={() => actionCallback()}
        onCancel={() => {
          setShowWarning(false)
        }}
        open={showWarning}
        disabled={deleteLoading}
        title="Attenzione!"
      >
        <Typography variant="h3">Sei sicuro di voler procedere?</Typography>
      </DialogWarning>
      <Title title="Gestione documenti" />
      <Breadcrumb current="Gestione documenti" />
      <FiltersWrapper>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={orderBy}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setOrderBy(e.target.value as DocumentOrderByInput)
                }}
              >
                <MenuItem value={DocumentOrderByInput.TITLE_DESC}>
                  Titolo ↓
                </MenuItem>
                <MenuItem value={DocumentOrderByInput.TITLE_ASC}>
                  Titolo ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}></Grid>
          <Grid item xs={6} md={3}></Grid>

          <Grid item xs={12} md={12}>
            <Button
              color="primary"
              variant="contained"
              // style={{ position: 'fixed', bottom: 20, right: 20 }}
              onClick={() => {
                setCurrentDocument(null)
                setShowDocumentForm(true)
              }}
              endIcon={<AddIcon />}
            >
              Crea un nuovo documento
            </Button>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {documentsLoading && <div>Caricamento dei documenti in corso</div>}
      {!documentsLoading && !documentsError && documentsData && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head" align="center">
                    Azioni
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Titolo
                  </TableCell>
                  <TableCell variant="head" align="center">
                    In evidenza
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentsData.getDocuments.documents.map((document) => {
                  return (
                    <TableRow key={document.id}>
                      <TableCell align="center">
                        <Tooltip title="Elimina">
                          <IconButton onClick={() => handleDelete(document.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Modifica">
                          <IconButton onClick={() => handleUpdate(document)}>
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Anteprima">
                          <IconButton onClick={() => handlePreview(document)}>
                            <PreviewIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={document.title}>
                          <span>
                            {document.title.substring(0, 40)}
                            {document.title.length > 40 && '...'}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {document.highlighted ? 'SI' : 'NO'}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage="Totale documenti per pagina"
            labelDisplayedRows={({ from, to, count }) =>
              `Utenti ${from}-${to} di ${count}`
            }
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={documentsData ? documentsData.getDocuments.total : 0}
            rowsPerPage={limit}
            page={Math.floor(offset / limit)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  )
}
