import React, { useState } from 'react'
import { Breadcrumb, Title, InnerContactForm, DialogInfo } from 'components'
import { Typography } from '@material-ui/core'

export default function Contattaci() {
  const [showMessageSent, setShowMessageSent] = useState(false)
  return (
    <>
      <Title title="Contattaci" />
      <Breadcrumb current="Contattaci" />
      <DialogInfo
        open={showMessageSent}
        title="Grazie per la sua richiesta"
        onClose={() => setShowMessageSent(false)}
      >
        <Typography variant="h3">
          La sua richiesta di informazione è stata inviata correttamente. Verrà
          contattato dall'agente di zona.
        </Typography>
      </DialogInfo>
      <InnerContactForm onSuccess={() => setShowMessageSent(true)} />
    </>
  )
}
