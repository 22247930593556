import React, { ReactNode } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close, Done } from '@material-ui/icons'
import { colors } from 'theme'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: colors.disabledBackground,
  },
  divider: {
    margin: '35px auto 40px',
  },
  actions: {
    marginTop: 0,
    // justifyContent: 'space-between',
    flexDirection: 'column-reverse',
    // gap: '30px',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      marginTop: 20,
      justifyContent: 'center',
      flexDirection: 'row',
    },
    '& > *': {
      margin: 15,
    },
  },
}))

interface DialogWarningProps {
  open: boolean
  onContinue: () => void
  onCancel: () => void
  children: ReactNode
  title?: string
  disabled: boolean
}

const DialogWarning: React.FC<DialogWarningProps> = ({
  open,
  onCancel,
  onContinue,
  children,
  title,
  disabled,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const useTextButton = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      scroll="body"
      onClose={onCancel}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onCancel}
      >
        <Close htmlColor={colors.text.primary} />
      </IconButton>
      {title && (
        <>
          <Typography variant="h1" color="textSecondary" align="center">
            {title}
          </Typography>
          <Divider className={classes.divider} />
        </>
      )}
      {children}
      <DialogActions className={classes.actions} disableSpacing={true}>
        {useTextButton ? (
          <Button
            variant="text"
            color="primary"
            size="medium"
            startIcon={<Close />}
            onClick={onCancel}
            disabled={disabled}
          >
            ANNULLA
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            startIcon={<Close />}
            onClick={onCancel}
            disabled={disabled}
          >
            ANNULLA
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={onContinue}
          startIcon={<Done />}
        >
          CONTINUA
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogWarning
