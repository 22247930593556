import React, { ReactNode } from 'react'

import { Typography } from '@material-ui/core'

const NoResults: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Typography variant="h3" color="textPrimary">
      {children || 'Nessun risultato'}
    </Typography>
  )
}

export default NoResults
