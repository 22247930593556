import { InMemoryCache, makeVar } from '@apollo/client'
import { UserRole } from 'types/generated/schemaTypes'
// import { } from 'generated/schemaTypes'

export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem('b_token'))

export const roleVar = makeVar<UserRole>(
  localStorage.getItem('b_role') as UserRole
)
export const userIdVar = makeVar<string | null>(
  localStorage.getItem('b_userId') || null
)
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar()
        },
        userIdVar() {
          return userIdVar()
        },
        roleVar() {
          return roleVar()
        },
      },
    },
  },
})
