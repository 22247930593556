import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  CreateUserInput,
  UserRole,
  createOperator,
  createOperatorVariables,
} from 'types/generated/schemaTypes'
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CREATE_OPERATOR } from 'mutation'
import { ActionType, DialogForm } from 'components'
import { formatError } from 'utils'

export interface OperatorFormValues {
  name: string
  lastname: string
  email: string
  emailCheck: string
  password: string
  passwordCheck: string
}

const defaultInitialValues = {
  name: '',
  lastname: '',
  email: '',
  emailCheck: '',
  password: '',
  passwordCheck: '',
}

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obbligatorio'),
  lastname: Yup.string().required('Campo obbligatorio'),
  email: Yup.string()
    .email('Indirizzo email non valido')
    .required('Campo obbligatorio'),
  emailCheck: Yup.string()
    .email('Indirizzo email non valido')
    .test('equal', 'Le email non combaciano', function (v) {
      const ref = Yup.ref('email')
      return v === this.resolve(ref)
    })
    .required('Campo obbligatorio'),
})

interface OperatorFormProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

const OperatorForm: React.FC<OperatorFormProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordCheck, setShowPasswordCheck] = useState(false)
  const [createOperatorMutation, { error: formError }] = useMutation<
    createOperator,
    createOperatorVariables
  >(CREATE_OPERATOR, {
    onCompleted: async (data) => {
      formik.resetForm()
      onSuccess()
    },
    onError: (err) => {
      console.log('Errore!', err)
    },
  })

  const formik = useFormik<OperatorFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables: { userData: CreateUserInput } = {
        userData: {
          email: values.email,
          password: values.password,
          name: values.name,
          lastname: values.lastname,
          role: UserRole.OPERATOR,
        },
      }
      await createOperatorMutation({ variables })
    },
  })

  return (
    <DialogForm
      open={open}
      title="Creazione nuovo operatore"
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      actions={[
        {
          type: ActionType.CANCEL,
          label: 'ANNULLA',
          callback: () => {
            formik.resetForm()
            onClose()
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: 'SALVE E CHIUDI',
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Dati personali
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* NOME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="outlined"
                id="name"
                size="small"
                label="Nome*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* COGNOME */}
            <FormControl fullWidth={true}>
              <TextField
                name="lastname"
                error={formik.touched.lastname && !!formik.errors.lastname}
                helperText={
                  formik.touched.lastname &&
                  !!formik.errors.lastname &&
                  formik.errors.lastname
                }
                variant="outlined"
                id="lastname"
                size="small"
                label="Cognome*"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="outlined"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* VERIFICA EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="emailCheck"
                error={formik.touched.emailCheck && !!formik.errors.emailCheck}
                helperText={
                  formik.touched.emailCheck &&
                  !!formik.errors.emailCheck &&
                  formik.errors.emailCheck
                }
                variant="outlined"
                id="emailCheck"
                size="small"
                label="Verifica email*"
                value={formik.values.emailCheck}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* PASSWORD */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel htmlFor="password">Password*</InputLabel>
              <OutlinedInput
                name="password"
                error={formik.touched.password && !!formik.errors.password}
                id="password"
                label="Password*"
                value={formik.values.password}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={formik.touched.password && !!formik.errors.password}
                variant="standard"
              >
                {(formik.touched.password &&
                  !!formik.errors.password &&
                  formik.errors.password) ||
                  'La password deve essere lunga almeno 8 caratteri'}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* VERIFICA PASSWORD */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel htmlFor="passwordCheck">
                Verifica password*
              </InputLabel>
              <OutlinedInput
                name="passwordCheck"
                error={
                  formik.touched.passwordCheck && !!formik.errors.passwordCheck
                }
                id="passwordCheck"
                label="Verifica password*"
                value={formik.values.passwordCheck}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                type={showPasswordCheck ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle passwordCheck visibility"
                      onClick={() => setShowPasswordCheck(!showPasswordCheck)}
                      edge="end"
                    >
                      {showPasswordCheck ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={
                  formik.touched.passwordCheck && !!formik.errors.passwordCheck
                }
                variant="standard"
              >
                {formik.touched.passwordCheck &&
                  !!formik.errors.passwordCheck &&
                  formik.errors.passwordCheck}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {formError && (
          <Typography color="error" variant="body1">
            {formatError(formError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  )
}

export default OperatorForm
