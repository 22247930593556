/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login {
  __typename: "AuthPayload";
  token: string;
}

export interface login {
  login: login_login;
}

export interface loginVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createProfessional
// ====================================================

export interface createProfessional_createUser {
  __typename: "User";
  id: string;
}

export interface createProfessional {
  createUser: createProfessional_createUser;
}

export interface createProfessionalVariables {
  userData: CreateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOperator
// ====================================================

export interface createOperator_createUser {
  __typename: "User";
  id: string;
}

export interface createOperator {
  createUser: createOperator_createUser;
}

export interface createOperatorVariables {
  userData: CreateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteProfessionals
// ====================================================

export interface deleteProfessionals {
  deleteUsers: string[];
}

export interface deleteProfessionalsVariables {
  users: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOperators
// ====================================================

export interface deleteOperators {
  deleteUsers: string[];
}

export interface deleteOperatorsVariables {
  users: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setUsersStatus
// ====================================================

export interface setUsersStatus {
  setUsersStatus: string[];
}

export interface setUsersStatusVariables {
  users: string[];
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProfessional
// ====================================================

export interface updateProfessional_updateUser_details_addresses {
  __typename: "Address";
  addressLine: string;
  zip: string;
  city: string;
  province: string;
  region: string;
}

export interface updateProfessional_updateUser_details {
  __typename: "ProfessionalDetails";
  verified: boolean;
  addresses: updateProfessional_updateUser_details_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  businessName: string | null;
  businessRole: string | null;
  marketing: boolean;
  agent: string | null;
  gender: Gender;
  birthDate: any;
  phone: string;
  fiscalCode: string;
  registerId: string;
}

export interface updateProfessional_updateUser {
  __typename: "User";
  id: string;
  email: string;
  role: UserRole;
  active: boolean;
  name: string;
  lastname: string;
  details: updateProfessional_updateUser_details | null;
}

export interface updateProfessional {
  updateUser: updateProfessional_updateUser;
}

export interface updateProfessionalVariables {
  userId: string;
  userData: UpdateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePassword
// ====================================================

export interface updatePassword_updatePassword {
  __typename: "SuccessMessage";
  message: string;
}

export interface updatePassword {
  updatePassword: updatePassword_updatePassword;
}

export interface updatePasswordVariables {
  userId: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMedicalEvent
// ====================================================

export interface updateMedicalEvent_updateMedicalEvent_dates {
  __typename: "MedicalEventDate";
  start: any;
  end: any;
}

export interface updateMedicalEvent_updateMedicalEvent {
  __typename: "MedicalEvent";
  id: string;
  title: string;
  tagline: string;
  registrationDetails: string;
  registrationDetails2: string;
  registrationConfirmation: string;
  webinarLink: string | null;
  googleMapsLink: string | null;
  address: string | null;
  location: string | null;
  city: string | null;
  agent: string | null;
  cover: string | null;
  schedule: string | null;
  curriculum: string | null;
  attachment1: string | null;
  attachment2: string | null;
  attachmentTitle1: string | null;
  attachmentTitle2: string | null;
  speakers: string[];
  dates: updateMedicalEvent_updateMedicalEvent_dates[];
  usersTotal: number;
  usersMax: number;
  open: boolean;
  hideEmail: boolean;
  type: MedicalEventType;
  status: MedicalEventStatus;
  deadline: any;
  area: MedicalEventArea;
}

export interface updateMedicalEvent {
  updateMedicalEvent: updateMedicalEvent_updateMedicalEvent;
}

export interface updateMedicalEventVariables {
  eventId: string;
  eventData: UpdateMedicalEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMedicalEvent
// ====================================================

export interface createMedicalEvent_createMedicalEvent_dates {
  __typename: "MedicalEventDate";
  start: any;
  end: any;
}

export interface createMedicalEvent_createMedicalEvent {
  __typename: "MedicalEvent";
  id: string;
  title: string;
  tagline: string;
  registrationDetails: string;
  registrationDetails2: string;
  registrationConfirmation: string;
  webinarLink: string | null;
  googleMapsLink: string | null;
  address: string | null;
  location: string | null;
  city: string | null;
  agent: string | null;
  cover: string | null;
  schedule: string | null;
  curriculum: string | null;
  attachment1: string | null;
  attachment2: string | null;
  attachmentTitle1: string | null;
  attachmentTitle2: string | null;
  speakers: string[];
  dates: createMedicalEvent_createMedicalEvent_dates[];
  usersTotal: number;
  usersMax: number;
  open: boolean;
  listed: boolean;
  hideEmail: boolean;
  type: MedicalEventType;
  status: MedicalEventStatus;
  deadline: any;
  area: MedicalEventArea;
}

export interface createMedicalEvent {
  createMedicalEvent: createMedicalEvent_createMedicalEvent;
}

export interface createMedicalEventVariables {
  eventData: CreateMedicalEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMedicalEvents
// ====================================================

export interface deleteMedicalEvents {
  deleteMedicalEvents: string[];
}

export interface deleteMedicalEventsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFile
// ====================================================

export interface deleteFile_deleteFile {
  __typename: "SuccessMessage";
  message: string;
}

export interface deleteFile {
  deleteFile: deleteFile_deleteFile;
}

export interface deleteFileVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: subscribeEvent
// ====================================================

export interface subscribeEvent_subscribeEvent {
  __typename: "SuccessMessage";
  message: string;
}

export interface subscribeEvent {
  subscribeEvent: subscribeEvent_subscribeEvent;
}

export interface subscribeEventVariables {
  userId: string;
  eventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unsubscribeEvent
// ====================================================

export interface unsubscribeEvent_unsubscribeEvent {
  __typename: "SuccessMessage";
  message: string;
}

export interface unsubscribeEvent {
  unsubscribeEvent: unsubscribeEvent_unsubscribeEvent;
}

export interface unsubscribeEventVariables {
  userId: string;
  eventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDocument
// ====================================================

export interface createDocument_createDocument {
  __typename: "MedicalDocument";
  id: string;
  title: string;
  cover: string | null;
  url: string;
  highlighted: boolean;
}

export interface createDocument {
  createDocument: createDocument_createDocument;
}

export interface createDocumentVariables {
  documentData: CreateDocumentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDocument
// ====================================================

export interface updateDocument_updateDocument {
  __typename: "MedicalDocument";
  id: string;
  title: string;
  cover: string | null;
  url: string;
  highlighted: boolean;
}

export interface updateDocument {
  updateDocument: updateDocument_updateDocument;
}

export interface updateDocumentVariables {
  documentId: string;
  documentData: UpdateDocumentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDocuments
// ====================================================

export interface deleteDocuments {
  deleteDocuments: string[];
}

export interface deleteDocumentsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: requestResetPassword
// ====================================================

export interface requestResetPassword_requestResetPassword {
  __typename: "SuccessMessage";
  message: string;
}

export interface requestResetPassword {
  requestResetPassword: requestResetPassword_requestResetPassword;
}

export interface requestResetPasswordVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPassword
// ====================================================

export interface resetPassword_resetPassword {
  __typename: "SuccessMessage";
  message: string;
}

export interface resetPassword {
  resetPassword: resetPassword_resetPassword;
}

export interface resetPasswordVariables {
  resetToken: string;
  password: string;
  confirmPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: me
// ====================================================

export interface me_me {
  __typename: "User";
  id: string;
  email: string;
  role: UserRole;
  name: string;
  lastname: string;
}

export interface me {
  me: me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPendingProfessionals
// ====================================================

export interface getPendingProfessionals_getUsers_users_details {
  __typename: "ProfessionalDetails";
  verified: boolean;
}

export interface getPendingProfessionals_getUsers_users {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  active: boolean;
  blocked: boolean;
  details: getPendingProfessionals_getUsers_users_details | null;
}

export interface getPendingProfessionals_getUsers {
  __typename: "GetUsersResults";
  total: number;
  users: getPendingProfessionals_getUsers_users[];
}

export interface getPendingProfessionals {
  getUsers: getPendingProfessionals_getUsers;
}

export interface getPendingProfessionalsVariables {
  where: UserWhereInput;
  orderby: UserOrderByInput;
  offset: number;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getActiveProfessionals
// ====================================================

export interface getActiveProfessionals_getUsers_users_details {
  __typename: "ProfessionalDetails";
  verified: boolean;
}

export interface getActiveProfessionals_getUsers_users {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
  active: boolean;
  blocked: boolean;
  details: getActiveProfessionals_getUsers_users_details | null;
}

export interface getActiveProfessionals_getUsers {
  __typename: "GetUsersResults";
  total: number;
  users: getActiveProfessionals_getUsers_users[];
}

export interface getActiveProfessionals {
  getUsers: getActiveProfessionals_getUsers;
}

export interface getActiveProfessionalsVariables {
  where: UserWhereInput;
  orderby: UserOrderByInput;
  offset: number;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProfessional
// ====================================================

export interface getProfessional_getUser_details_addresses {
  __typename: "Address";
  addressLine: string;
  zip: string;
  city: string;
  province: string;
  region: string;
}

export interface getProfessional_getUser_details {
  __typename: "ProfessionalDetails";
  verified: boolean;
  addresses: getProfessional_getUser_details_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  businessName: string | null;
  businessRole: string | null;
  marketing: boolean;
  extra: string | null;
  agent: string | null;
  gender: Gender;
  birthDate: any;
  phone: string;
  fiscalCode: string;
  registerId: string;
}

export interface getProfessional_getUser {
  __typename: "User";
  id: string;
  email: string;
  role: UserRole;
  active: boolean;
  name: string;
  lastname: string;
  details: getProfessional_getUser_details | null;
}

export interface getProfessional {
  getUser: getProfessional_getUser | null;
}

export interface getProfessionalVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProfile
// ====================================================

export interface getProfile_getUser_details_addresses {
  __typename: "Address";
  addressLine: string;
  zip: string;
  city: string;
  province: string;
  region: string;
}

export interface getProfile_getUser_details {
  __typename: "ProfessionalDetails";
  addresses: getProfile_getUser_details_addresses[];
  profession: Profession;
  specialization: Specialization;
  customProfession: string | null;
  customSpecialization: string | null;
  businessName: string | null;
  businessRole: string | null;
  marketing: boolean;
  extra: string | null;
  agent: string | null;
  gender: Gender;
  birthDate: any;
  phone: string;
  fiscalCode: string;
  registerId: string;
}

export interface getProfile_getUser {
  __typename: "User";
  id: string;
  email: string;
  role: UserRole;
  name: string;
  lastname: string;
  details: getProfile_getUser_details | null;
}

export interface getProfile {
  getUser: getProfile_getUser | null;
}

export interface getProfileVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOperators
// ====================================================

export interface getOperators_getUsers_users {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface getOperators_getUsers {
  __typename: "GetUsersResults";
  total: number;
  users: getOperators_getUsers_users[];
}

export interface getOperators {
  getUsers: getOperators_getUsers;
}

export interface getOperatorsVariables {
  where: UserWhereInput;
  orderby: UserOrderByInput;
  offset: number;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDocuments
// ====================================================

export interface getDocuments_getDocuments_documents {
  __typename: "MedicalDocument";
  id: string;
  title: string;
  cover: string | null;
  url: string;
  highlighted: boolean;
}

export interface getDocuments_getDocuments {
  __typename: "GetDocumentsResults";
  total: number;
  documents: getDocuments_getDocuments_documents[];
}

export interface getDocuments {
  getDocuments: getDocuments_getDocuments;
}

export interface getDocumentsVariables {
  where?: DocumentWhereInput | null;
  orderby?: DocumentOrderByInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDocument
// ====================================================

export interface getDocument_getDocument {
  __typename: "MedicalDocument";
  id: string;
  title: string;
  cover: string | null;
  url: string;
  highlighted: boolean;
}

export interface getDocument {
  getDocument: getDocument_getDocument | null;
}

export interface getDocumentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOperator
// ====================================================

export interface getOperator_getUser {
  __typename: "User";
  id: string;
  name: string;
  lastname: string;
  email: string;
}

export interface getOperator {
  getUser: getOperator_getUser | null;
}

export interface getOperatorVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCSVProfessionals
// ====================================================

export interface getCSVProfessionals {
  getCSVProfessionals: string | null;
}

export interface getCSVProfessionalsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCSVProfessionalsAll
// ====================================================

export interface getCSVProfessionalsAll {
  getCSVProfessionalsAll: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCSVEvent
// ====================================================

export interface getCSVEvent {
  getCSVEvent: string | null;
}

export interface getCSVEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMedicalEvents
// ====================================================

export interface getMedicalEvents_getMedicalEvents_events_dates {
  __typename: "MedicalEventDate";
  start: any;
  end: any;
}

export interface getMedicalEvents_getMedicalEvents_events {
  __typename: "MedicalEvent";
  id: string;
  title: string;
  tagline: string;
  registrationDetails: string;
  registrationDetails2: string;
  registrationConfirmation: string;
  webinarLink: string | null;
  googleMapsLink: string | null;
  address: string | null;
  location: string | null;
  city: string | null;
  agent: string | null;
  cover: string | null;
  schedule: string | null;
  curriculum: string | null;
  attachment1: string | null;
  attachment2: string | null;
  attachmentTitle1: string | null;
  attachmentTitle2: string | null;
  speakers: string[];
  dates: getMedicalEvents_getMedicalEvents_events_dates[];
  usersTotal: number;
  usersMax: number;
  open: boolean;
  listed: boolean;
  hideEmail: boolean;
  type: MedicalEventType;
  status: MedicalEventStatus;
  deadline: any;
  highlighted: boolean;
  area: MedicalEventArea;
}

export interface getMedicalEvents_getMedicalEvents {
  __typename: "GetMedicalEventsResults";
  total: number;
  events: getMedicalEvents_getMedicalEvents_events[];
}

export interface getMedicalEvents {
  getMedicalEvents: getMedicalEvents_getMedicalEvents;
}

export interface getMedicalEventsVariables {
  where: MedicalEventWhereInput;
  orderby: MedicalEventOrderByInput;
  offset: number;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMedicalEvent
// ====================================================

export interface getMedicalEvent_getMedicalEvent_dates {
  __typename: "MedicalEventDate";
  start: any;
  end: any;
}

export interface getMedicalEvent_getMedicalEvent {
  __typename: "MedicalEvent";
  id: string;
  title: string;
  tagline: string;
  registrationDetails: string;
  registrationDetails2: string;
  registrationConfirmation: string;
  webinarLink: string | null;
  googleMapsLink: string | null;
  address: string | null;
  location: string | null;
  city: string | null;
  agent: string | null;
  cover: string | null;
  schedule: string | null;
  curriculum: string | null;
  attachment1: string | null;
  attachment2: string | null;
  attachmentTitle1: string | null;
  attachmentTitle2: string | null;
  speakers: string[];
  dates: getMedicalEvent_getMedicalEvent_dates[];
  usersTotal: number;
  usersMax: number;
  open: boolean;
  listed: boolean;
  hideEmail: boolean;
  type: MedicalEventType;
  area: MedicalEventArea;
  status: MedicalEventStatus;
  deadline: any;
  highlighted: boolean;
}

export interface getMedicalEvent {
  getMedicalEvent: getMedicalEvent_getMedicalEvent | null;
}

export interface getMedicalEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSignedUrl
// ====================================================

export interface getSignedUrl_getSignedUrl {
  __typename: "GetSignedUrlResults";
  uploadUrl: string;
  finalUrl: string;
}

export interface getSignedUrl {
  getSignedUrl: getSignedUrl_getSignedUrl;
}

export interface getSignedUrlVariables {
  media: MediaType;
  filename: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: myEvents
// ====================================================

export interface myEvents_getUser_events_dates {
  __typename: "MedicalEventDate";
  start: any;
  end: any;
}

export interface myEvents_getUser_events {
  __typename: "MedicalEvent";
  id: string;
  title: string;
  type: MedicalEventType;
  dates: myEvents_getUser_events_dates[];
}

export interface myEvents_getUser {
  __typename: "User";
  events: myEvents_getUser_events[] | null;
}

export interface myEvents {
  getUser: myEvents_getUser | null;
}

export interface myEventsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendContactRequestInner
// ====================================================

export interface sendContactRequestInner_sendContactRequestInner {
  __typename: "SuccessMessage";
  message: string;
}

export interface sendContactRequestInner {
  sendContactRequestInner: sendContactRequestInner_sendContactRequestInner;
}

export interface sendContactRequestInnerVariables {
  userId: string;
  subject: string;
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sendContactRequestOuter
// ====================================================

export interface sendContactRequestOuter_sendContactRequestOuter {
  __typename: "SuccessMessage";
  message: string;
}

export interface sendContactRequestOuter {
  sendContactRequestOuter: sendContactRequestOuter_sendContactRequestOuter;
}

export interface sendContactRequestOuterVariables {
  request: RequestInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DocumentOrderByInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
  TITLE_ASC = "TITLE_ASC",
  TITLE_DESC = "TITLE_DESC",
}

export enum Gender {
  F = "F",
  M = "M",
}

export enum MediaType {
  DOCUMENT_COVER = "DOCUMENT_COVER",
  DOCUMENT_FILE = "DOCUMENT_FILE",
  EVENT_ATTACHMENT = "EVENT_ATTACHMENT",
  EVENT_COVER = "EVENT_COVER",
  EVENT_CURRICULUM = "EVENT_CURRICULUM",
  EVENT_SCHEDULE = "EVENT_SCHEDULE",
}

export enum MedicalEventArea {
  ABRUZZO_E_MOLISE = "ABRUZZO_E_MOLISE",
  EMILIA_ROMAGNA = "EMILIA_ROMAGNA",
  FRIULI_VENEZIA_GIULIA = "FRIULI_VENEZIA_GIULIA",
  LAZIO = "LAZIO",
  LIGURIA = "LIGURIA",
  LOMBARDIA = "LOMBARDIA",
  MARCHE = "MARCHE",
  NO_AREA = "NO_AREA",
  PIEMONTE_VALLE_D_AOSTA = "PIEMONTE_VALLE_D_AOSTA",
  SARDEGNA = "SARDEGNA",
  SUD_ITALIA = "SUD_ITALIA",
  TOSCANA = "TOSCANA",
  TRENTINO_ALTO_ADIGE = "TRENTINO_ALTO_ADIGE",
  UMBRIA = "UMBRIA",
  VENETO = "VENETO",
}

export enum MedicalEventOrderByInput {
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
}

export enum MedicalEventStatus {
  ARCHIVED = "ARCHIVED",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export enum MedicalEventType {
  CLASSROOM = "CLASSROOM",
  WEBINAR = "WEBINAR",
}

export enum Profession {
  ALTRO = "ALTRO",
  BIOLOGO = "BIOLOGO",
  DISCIPLINE_OLISTICHE = "DISCIPLINE_OLISTICHE",
  FARMACISTA = "FARMACISTA",
  MEDICO_CHIRURGO = "MEDICO_CHIRURGO",
  ODONTOIATRA = "ODONTOIATRA",
  PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE = "PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE",
  PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE = "PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE",
  PROFESSIONI_TECNICO_SANITARIE = "PROFESSIONI_TECNICO_SANITARIE",
  PSICOLOGO = "PSICOLOGO",
}

export enum Specialization {
  ALLERGOLOGIA_IMMUNOLOGIA_CLINICA = "ALLERGOLOGIA_IMMUNOLOGIA_CLINICA",
  ALTRO = "ALTRO",
  ANESTESIA_RIANIMAZIONE = "ANESTESIA_RIANIMAZIONE",
  BIOLOGO_NUTRIZIONISTA = "BIOLOGO_NUTRIZIONISTA",
  CARDIOLOGIA = "CARDIOLOGIA",
  CHIRURGIA_GENERALE = "CHIRURGIA_GENERALE",
  DENTALE = "DENTALE",
  DERMATOLOGIA_VENEREOLOGIA = "DERMATOLOGIA_VENEREOLOGIA",
  DIETISTA = "DIETISTA",
  EMATOLOGIA = "EMATOLOGIA",
  ENDOCRINOLOGIA = "ENDOCRINOLOGIA",
  EPIDEMIOLOGIA = "EPIDEMIOLOGIA",
  ERBORISTA = "ERBORISTA",
  FARMACISTA = "FARMACISTA",
  FISIATRIA = "FISIATRIA",
  FISIOTERAPIA = "FISIOTERAPIA",
  FISIOTERAPIA_DEL_PAVIMENTO_PELVICO = "FISIOTERAPIA_DEL_PAVIMENTO_PELVICO",
  FITOTERAPIA = "FITOTERAPIA",
  GASTROENTEROLOGIA = "GASTROENTEROLOGIA",
  GENETICA_MEDICA = "GENETICA_MEDICA",
  GERIATRIA = "GERIATRIA",
  GINECOLOGIA_OSTETRICIA = "GINECOLOGIA_OSTETRICIA",
  IGIENE_E_MEDICINA_PREVENTIVA = "IGIENE_E_MEDICINA_PREVENTIVA",
  IGIENISTA = "IGIENISTA",
  INFERMIERE = "INFERMIERE",
  INFERMIERE_PEDIATRICO = "INFERMIERE_PEDIATRICO",
  IRIDOLOGIA = "IRIDOLOGIA",
  KINESIOLOGIA = "KINESIOLOGIA",
  LOGOPEDISTA = "LOGOPEDISTA",
  MALATTIE_APPARATO_RESPIRATORIO = "MALATTIE_APPARATO_RESPIRATORIO",
  MALATTIE_INFETTIVE = "MALATTIE_INFETTIVE",
  MASSOKINESITERAPIA = "MASSOKINESITERAPIA",
  MEDICINA_GENERALE = "MEDICINA_GENERALE",
  MEDICINA_INTERNA = "MEDICINA_INTERNA",
  MEDICINA_LAVORO = "MEDICINA_LAVORO",
  MEDICINA_LEGALE = "MEDICINA_LEGALE",
  MEDICINA_SPORT = "MEDICINA_SPORT",
  MICROBIOLOGIA_VIROLOGIA = "MICROBIOLOGIA_VIROLOGIA",
  NATUROPATA = "NATUROPATA",
  NEFROLOGIA = "NEFROLOGIA",
  NEUROCHIRURGIA = "NEUROCHIRURGIA",
  NEUROLOGIA = "NEUROLOGIA",
  NEUROPSICHIATRIA_INFANTILE = "NEUROPSICHIATRIA_INFANTILE",
  ODONTOIATRA = "ODONTOIATRA",
  OFTALMOLOGIA = "OFTALMOLOGIA",
  OMEOPATA = "OMEOPATA",
  ONCOLOGIA = "ONCOLOGIA",
  ORTOPEDIA_TRAUMATOLOGIA = "ORTOPEDIA_TRAUMATOLOGIA",
  ORTOTTISTA_ASSISTENTE_DI_OFTALMOLOGIA = "ORTOTTISTA_ASSISTENTE_DI_OFTALMOLOGIA",
  OSTEOPATA = "OSTEOPATA",
  OSTETRICA = "OSTETRICA",
  OTORINOLARINGOIATRIA = "OTORINOLARINGOIATRIA",
  PATOLOGIA_CLINICA = "PATOLOGIA_CLINICA",
  PEDIATRIA = "PEDIATRIA",
  PODOLOGO = "PODOLOGO",
  PRIVO_DI_SPECIALIZZAZIONE = "PRIVO_DI_SPECIALIZZAZIONE",
  PSICHIATRIA = "PSICHIATRIA",
  PSICOLOGIA = "PSICOLOGIA",
  PSICOTERAPIA = "PSICOTERAPIA",
  REUMATOLOGIA = "REUMATOLOGIA",
  RIFLESSOLOGIA = "RIFLESSOLOGIA",
  SCIENZA_ALIMENTAZIONE_DIETETICA = "SCIENZA_ALIMENTAZIONE_DIETETICA",
  TECNICO_ORTOPEDICO = "TECNICO_ORTOPEDICO",
  TECNICO_SANITARIO_DI_LABORATORIO_MEDICO = "TECNICO_SANITARIO_DI_LABORATORIO_MEDICO",
  TECNICO_SANITARIO_DI_RADIOLOGIA_MEDICA = "TECNICO_SANITARIO_DI_RADIOLOGIA_MEDICA",
  UROLOGIA = "UROLOGIA",
}

export enum UserOrderByInput {
  CREATION_ASC = "CREATION_ASC",
  CREATION_DESC = "CREATION_DESC",
  LASTNAME_ASC = "LASTNAME_ASC",
  LASTNAME_DESC = "LASTNAME_DESC",
}

export enum UserRole {
  ADMIN = "ADMIN",
  OPERATOR = "OPERATOR",
  PROFESSIONAL = "PROFESSIONAL",
}

export interface AddressInput {
  addressLine: string;
  zip: string;
  city: string;
  province: string;
  region: string;
}

export interface CreateDocumentInput {
  title: string;
  cover?: string | null;
  url: string;
  highlighted?: boolean | null;
}

export interface CreateMedicalEventInput {
  title: string;
  tagline: string;
  registrationDetails: string;
  registrationDetails2: string;
  registrationConfirmation: string;
  webinarLink?: string | null;
  cover?: string | null;
  schedule?: string | null;
  curriculum?: string | null;
  attachment1?: string | null;
  attachment2?: string | null;
  attachmentTitle1?: string | null;
  attachmentTitle2?: string | null;
  speakers: string[];
  dates: MedicalEventDateInput[];
  type: MedicalEventType;
  usersMax?: number | null;
  deadline: any;
  status: MedicalEventStatus;
  open: boolean;
  listed: boolean;
  hideEmail: boolean;
  area: MedicalEventArea;
  googleMapsLink?: string | null;
  address?: string | null;
  location?: string | null;
  city?: string | null;
  agent?: string | null;
}

export interface CreateUserInput {
  email: string;
  password: string;
  name: string;
  lastname: string;
  role: UserRole;
  details?: ProfessionalDetailsCreateInput | null;
}

export interface DocumentWhereInput {
  searchPattern?: string | null;
}

export interface MedicalEventDateInput {
  start: any;
  end: any;
}

export interface MedicalEventWhereInput {
  searchPattern?: string | null;
  status?: MedicalEventStatus | null;
  type?: MedicalEventType | null;
  area?: MedicalEventArea | null;
  onlyPublic?: boolean | null;
}

export interface ProfessionalDetailsCreateInput {
  addresses: AddressInput[];
  profession: Profession;
  specialization: Specialization;
  customProfession?: string | null;
  customSpecialization?: string | null;
  agent?: string | null;
  gender: Gender;
  birthDate: any;
  phone: string;
  fiscalCode: string;
  registerId: string;
  businessName?: string | null;
  businessRole?: string | null;
  marketing: boolean;
}

export interface ProfessionalDetailsUpdateInput {
  addresses?: AddressInput[] | null;
  profession?: Profession | null;
  specialization?: Specialization | null;
  customProfession?: string | null;
  customSpecialization?: string | null;
  agent?: string | null;
  gender?: Gender | null;
  birthDate?: any | null;
  phone?: string | null;
  fiscalCode?: string | null;
  registerId?: string | null;
  verified?: boolean | null;
  businessName?: string | null;
  businessRole?: string | null;
  extra?: string | null;
  marketing?: boolean | null;
}

export interface RequestInput {
  name: string;
  lastname: string;
  email: string;
  address: AddressInput;
  profession: Profession;
  specialization: Specialization;
  customProfession?: string | null;
  customSpecialization?: string | null;
  phone: string;
  fiscalCode: string;
  registerId: string;
  message: string;
  subject: string;
  businessName?: string | null;
  businessRole?: string | null;
  marketing: boolean;
}

export interface UpdateDocumentInput {
  title?: string | null;
  cover?: string | null;
  url?: string | null;
  highlighted?: boolean | null;
}

export interface UpdateMedicalEventInput {
  title?: string | null;
  tagline?: string | null;
  registrationDetails?: string | null;
  registrationDetails2?: string | null;
  registrationConfirmation?: string | null;
  webinarLink?: string | null;
  cover?: string | null;
  schedule?: string | null;
  attachment1?: string | null;
  attachment2?: string | null;
  attachmentTitle1?: string | null;
  attachmentTitle2?: string | null;
  curriculum?: string | null;
  speakers?: string[] | null;
  dates?: MedicalEventDateInput[] | null;
  type?: MedicalEventType | null;
  status?: MedicalEventStatus | null;
  open?: boolean | null;
  listed?: boolean | null;
  hideEmail?: boolean | null;
  usersMax?: number | null;
  deadline?: any | null;
  area?: MedicalEventArea | null;
  googleMapsLink?: string | null;
  address?: string | null;
  location?: string | null;
  city?: string | null;
  agent?: string | null;
}

export interface UpdateUserInput {
  email?: string | null;
  name?: string | null;
  lastname?: string | null;
  details?: ProfessionalDetailsUpdateInput | null;
}

export interface UserWhereInput {
  searchPattern?: string | null;
  role?: UserRole | null;
  active?: boolean | null;
  verified?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
