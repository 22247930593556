import React from 'react'
import {
  Button,
  Typography,
  Divider,
  Paper,
  makeStyles,
} from '@material-ui/core'
import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  DesktopMac as DesktopMacIcon,
  People as PeopleIcon,
  GetApp as GetAppIcon,
  LocationOn,
} from '@material-ui/icons'
import { colors } from 'theme'
import {
  getMedicalEvent_getMedicalEvent,
  MedicalEventType,
} from 'types/generated/schemaTypes'
import { ReactComponent as RegisteredSVG } from 'assets/registered.svg'
import { utcToZonedTime, format as formatTZ } from 'date-fns-tz'

const daysWeek = [
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
  'Domenica',
]

const months = [
  'gennaio',
  'febbraio',
  'marzo',
  'aprile',
  'maggio',
  'giugno',
  'luglio',
  'agosto',
  'settembre',
  'ottobre',
  'novembre',
  'dicembre',
]

interface StyleProps {
  preview: boolean
  higlighted: boolean
}

const useStyles = makeStyles((theme) => ({
  root: (props: StyleProps) => ({
    padding: '0px 0px 10px',
    width: 'calc(100% + 30px)',
    margin: '0 -15px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: '20px 20px 30px',
      margin: '0 auto 30px',
    },
    borderStyle: 'solid',
    borderColor: props.higlighted ? theme.palette.primary.main : '#cccccc',
    borderWidth:
      props.preview || props.higlighted
        ? props.higlighted
          ? '3px'
          : '1px'
        : '0px',
  }),
  imageWrapper: {
    '& img': {
      width: '100%',
    },
  },
  eventDataWrapper: {
    padding: '0 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0px',
    },
  },
  rightCol: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  eventHeader: (props: StyleProps) => ({
    backgroundColor: props.higlighted
      ? theme.palette.primary.main
      : colors.brand,
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    justifyContent: 'space-between',
    minHeight: 55,
    [theme.breakpoints.up('md')]: {
      minHeight: 35,
      marginBottom: 20,
    },
  }),
  eventTagline: {
    color: '#ffffff',
    fontWeight: 600,
    textTransform: 'uppercase',
    position: 'relative',
    top: 1,
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  eventType: {
    display: 'flex',
    alignItems: 'center',
  },
  eventTypeIcon: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  eventTypeLetter: {
    color: '#ffffff',
    fontWeight: 600,
    textTransform: 'uppercase',
    position: 'relative',
    top: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  speakers: {
    columns: 2,
    lineHeight: '1em',
  },
  afterSpeakers: {
    margin: '1em 0',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  speakersHeader: {
    position: 'relative',
    textAlign: 'left',
    margin: '0.5em 0',
    '& .MuiDivider-root': {
      position: 'absolute',
      width: '100%',
      top: 8,
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  speakersTitle: {
    display: 'inline-block',
    backgroundColor: '#ffffff',
    padding: '0 1.5em 0 0',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: '0 1.5em',
    },
  },
  eventPdf: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '10px 0 10px',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  info: {},
  eventSubscribe: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 20px',
  },
  buttonSeparator: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridLeft: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'calc(55.8% - 20px)',
      marginRight: 20,
    },
  },
  gridRight: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'calc(44.2% - 20px)',
      marginLeft: 20,
    },
  },
}))

interface EventItemProps {
  preview?: boolean
  infoMode?: boolean
  canRegister: boolean
  event: getMedicalEvent_getMedicalEvent
  userRegistered?: boolean
  onRegister?: () => void
}

const EventItem: React.FC<EventItemProps> = ({
  event,
  preview = false,
  infoMode = false,
  canRegister,
  userRegistered = false,
  onRegister = () => {},
}) => {
  const classes = useStyles({ preview, higlighted: event.highlighted })
  return (
    <Paper className={classes.root}>
      {userRegistered && (
        <RegisteredSVG
          style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
        />
      )}
      <div className={classes.eventHeader}>
        <Typography className={classes.eventTagline} variant="body2">
          {event.tagline}
        </Typography>
        <div className={classes.eventType}>
          <div className={classes.eventTypeLetter}>
            {event.type === MedicalEventType.WEBINAR
              ? 'MODALITÀ WEBINAR'
              : 'MODALITÀ IN PRESENZA'}
          </div>
          {event.type === MedicalEventType.WEBINAR ? (
            <DesktopMacIcon
              className={classes.eventTypeIcon}
              htmlColor="#ffffff"
            />
          ) : (
            <PeopleIcon className={classes.eventTypeIcon} htmlColor="#ffffff" />
          )}
        </div>
      </div>

      <div className={classes.gridContainer}>
        <div className={classes.gridLeft}>
          <div className={classes.imageWrapper}>
            <img src={event.cover || 'eventPlaceholder.jpg'} alt="" />
          </div>
          <div className={classes.eventDataWrapper}>
            <Typography variant="h3" style={{ fontWeight: 600 }}>
              {event.title}
            </Typography>
            <div className={classes.speakersHeader}>
              <Divider />
              <Typography
                variant="body2"
                color="textPrimary"
                component="h3"
                className={classes.speakersTitle}
              >
                RELATORI
              </Typography>
            </div>
            <div className={classes.speakers}>
              {event.speakers.map((speaker, i) => {
                return (
                  <Typography
                    key={i}
                    variant="h5"
                    style={{ color: colors.brand }}
                  >
                    {speaker}
                  </Typography>
                )
              })}
            </div>
            <Divider className={classes.afterSpeakers} />
          </div>
        </div>
        <div className={classes.gridRight}>
          <div className={classes.rightCol}>
            <div>
              {event.dates.map((date, i) => {
                const end = utcToZonedTime(new Date(date.end), 'Europe/Rome')
                const start = utcToZonedTime(
                  new Date(date.start),
                  'Europe/Rome'
                )
                return (
                  <div key={i}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      display="inline"
                      style={{ fontWeight: 600 }}
                    >
                      {daysWeek[start.getDay() - 1]} {start.getDate()}{' '}
                      {months[start.getMonth()]} {start.getFullYear()}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: '#444444' }}
                      display="inline"
                    >
                      {' - '}
                      {`${formatTZ(start, 'HH:mm', {
                        timeZone: 'Europe/Rome',
                      })} -  ${formatTZ(end, 'HH:mm (zzz)', {
                        timeZone: 'Europe/Rome',
                      })}`}
                    </Typography>
                  </div>
                )
              })}
            </div>
            {event.location && (
              <div style={{ marginTop: 6 }}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  display="inline"
                  style={{ fontWeight: 600 }}
                >
                  Location: {event.location}
                </Typography>
              </div>
            )}
            {event.address && event.googleMapsLink && (
              <div>
                <a
                  href={event.googleMapsLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    marginLeft: -6,
                  }}
                >
                  <LocationOn />
                  {event.address}
                </a>
              </div>
            )}
            {event.address && !event.googleMapsLink && (
              <div>{event.address}</div>
            )}
            <div className={classes.eventPdf}>
              {event.schedule && (
                <>
                  <Button
                    variant="text"
                    startIcon={<GetAppIcon />}
                    color="primary"
                    target="_blank"
                    href={event.schedule}
                  >
                    PROGRAMMA
                  </Button>
                  <br className={classes.buttonSeparator} />
                </>
              )}
              {event.curriculum && (
                <Button
                  variant="text"
                  startIcon={<GetAppIcon />}
                  color="primary"
                  target="_blank"
                  href={event.curriculum}
                >
                  CV RELATORI
                </Button>
              )}
              {event.attachment1 && event.attachmentTitle1 && (
                <Button
                  variant="text"
                  startIcon={<GetAppIcon />}
                  color="primary"
                  target="_blank"
                  href={event.attachment1}
                >
                  {event.attachmentTitle1?.toUpperCase()}
                </Button>
              )}
              {event.attachment2 && event.attachmentTitle2 && (
                <Button
                  variant="text"
                  startIcon={<GetAppIcon />}
                  color="primary"
                  target="_blank"
                  href={event.attachment2}
                >
                  {event.attachmentTitle2?.toUpperCase()}
                </Button>
              )}
            </div>
            <div className={classes.eventSubscribe}>
              {/* {preview && (
                <Button
                  variant="contained"
                  startIcon={<AssignmentTurnedInIcon />}
                  color="primary"
                  disabled={false}
                >
                  ISCRIZIONE ALL'EVENTO
                </Button>
              )} */}
              {!preview && (
                <Button
                  variant="contained"
                  startIcon={<AssignmentTurnedInIcon />}
                  color="primary"
                  disabled={userRegistered || !canRegister}
                  onClick={onRegister}
                >
                  ISCRIZIONE ALL'EVENTO
                </Button>
              )}
            </div>
            <div className={classes.info}>
              {userRegistered ? (
                <>
                  <Typography
                    variant="caption"
                    component="p"
                    paragraph={true}
                    color="textPrimary"
                    align="center"
                  >
                    {!infoMode && <>L'iscrizione è già stata effettuata.</>}
                    {event.webinarLink && (
                      <>
                        <br />
                        Ecco il link per partecipare all'evento:{' '}
                        <a
                          style={{ userSelect: 'none' }}
                          onContextMenu={(e) => e.preventDefault()}
                          href={event.webinarLink}
                        >
                          {event.webinarLink}
                        </a>
                      </>
                    )}
                  </Typography>
                </>
              ) : (
                <>
                  {canRegister
                    ? !infoMode && (
                        <Typography
                          variant="caption"
                          component="p"
                          paragraph={true}
                          color="textPrimary"
                        >
                          {event.registrationDetails}
                        </Typography>
                      )
                    : !infoMode && (
                        <Typography
                          variant="caption"
                          component="p"
                          paragraph={true}
                          color="textPrimary"
                        >
                          Non è più possibile iscriversi
                        </Typography>
                      )}
                </>
              )}
              <Typography
                variant="caption"
                component="p"
                paragraph={true}
                style={{ fontWeight: 600 }}
                color="textPrimary"
              >
                RISERVATO ESCLUSIVAMENTE SU INVITO AL PERSONALE SANITARIO
              </Typography>
              <Typography
                variant="caption"
                component="p"
                paragraph={true}
                dangerouslySetInnerHTML={{ __html: event.registrationDetails2 }}
                color="textPrimary"
              />
            </div>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default EventItem
