import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  Grid,
  FormControl,
  Hidden,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Search as SearchIcon } from '@material-ui/icons'
import { GET_EVENTS, MY_EVENTS } from 'query'
import { SUBSCRIBE_EVENT } from 'mutation'
import { userIdVar } from 'cache'
import {
  getMedicalEvent_getMedicalEvent,
  getMedicalEvents,
  getMedicalEventsVariables,
  myEvents,
  myEventsVariables,
  subscribeEvent,
  subscribeEventVariables,
  MedicalEventOrderByInput,
  MedicalEventWhereInput,
  MedicalEventType,
  MedicalEventStatus,
  MedicalEventArea,
} from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  EventItem,
  Title,
  NoResults,
  FiltersWrapper,
  DialogInfo,
  DialogError,
  Banner,
} from 'components'
import { isBefore } from 'date-fns'
import { useLocation } from 'react-router-dom'

function useQueryString() {
  return new URLSearchParams(useLocation().search)
}

function isValidArea(area: string): boolean {
  const values = Object.values(MedicalEventArea)
  return values.includes((area as unknown) as MedicalEventArea)
}

const useStyle = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  preview: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        padding: '65px 105px 60px 105px',
      },
    },
  },
  search: {
    '& .MuiInputBase-root': {
      borderRadius: 20,
      backgroundColor: '#ffffff',
    },
  },
  banner: {
    boxSizing: 'border-box',
    border: 'solid 2px rgb(33, 150, 243)',
    padding: 16,
    marginTop: 16,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {},
  },
  bannerImage: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    maxWidth: 200,
  },
}))

export default function Eventi() {
  const classes = useStyle()
  const [
    currentEvent,
    setCurrentEvent,
  ] = useState<null | getMedicalEvent_getMedicalEvent>(null)
  // const [searchParams, setSearchParams] = useSearchParams()
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState<MedicalEventOrderByInput>(
    MedicalEventOrderByInput.DATE_DESC
  )
  const query = useQueryString()
  const _area = query.get('area') || 'ALL'

  const [area, setArea] = useState<MedicalEventArea | 'ALL'>(
    isValidArea(_area) ? (_area as MedicalEventArea) : 'ALL'
  )
  const [searchString, setSearchString] = useState('')
  const [showSubscribedMessage, setShowSubscribedMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const where: MedicalEventWhereInput = {
    type: MedicalEventType.CLASSROOM,
  }
  if (searchString.length > 2) where.searchPattern = searchString
  where.status = MedicalEventStatus.PUBLISHED

  if (area !== 'ALL') where.area = area

  where.onlyPublic = true

  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useQuery<getMedicalEvents, getMedicalEventsVariables>(GET_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderby: orderBy,
      offset,
      limit: 5,
    },
  })

  const {
    data: myEventsData,
    loading: myEventsLoading,
    error: myEventsError,
  } = useQuery<myEvents, myEventsVariables>(MY_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      id: userIdVar() as string,
    },
  })

  const [subscribeEventMutation] = useMutation<
    subscribeEvent,
    subscribeEventVariables
  >(SUBSCRIBE_EVENT, {
    onCompleted: () => {
      setShowSubscribedMessage(true)
    },
    onError: () => {},
    refetchQueries: [
      {
        query: GET_EVENTS,
        variables: {
          where,
          orderby: orderBy,
          offset,
          limit: 5,
        },
      },
      {
        query: MY_EVENTS,
        variables: {
          id: userIdVar() as string,
        },
      },
    ],
  })

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * 5)
    setPage(value)
  }

  return (
    <>
      <Banner />
      <Title title="Eventi residenziali (in presenza)" />
      <Breadcrumb current="Eventi residenziali (in presenza)" />
      <FiltersWrapper>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={orderBy}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setPage(1)
                  setOrderBy(e.target.value as MedicalEventOrderByInput)
                }}
              >
                <MenuItem value={MedicalEventOrderByInput.DATE_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={MedicalEventOrderByInput.DATE_ASC}>
                  Data creazione ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="type-label">Visualizza</InputLabel>
              <Select
                name="area"
                value={area}
                labelId="area-label"
                id="area"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setPage(1)
                  setArea(e.target.value as MedicalEventArea | 'ALL')
                }}
              >
                <MenuItem value={'ALL'}>Area geografica</MenuItem>
                <MenuItem value={MedicalEventArea.ABRUZZO_E_MOLISE}>
                  Abruzzo e Molise
                </MenuItem>
                <MenuItem value={MedicalEventArea.EMILIA_ROMAGNA}>
                  Emilia Romagna
                </MenuItem>
                <MenuItem value={MedicalEventArea.FRIULI_VENEZIA_GIULIA}>
                  Friuli Venezia Giulia
                </MenuItem>
                <MenuItem value={MedicalEventArea.LAZIO}>Lazio</MenuItem>
                <MenuItem value={MedicalEventArea.LIGURIA}>Liguria</MenuItem>
                <MenuItem value={MedicalEventArea.LOMBARDIA}>
                  Lombardia
                </MenuItem>
                <MenuItem value={MedicalEventArea.MARCHE}>Marche</MenuItem>
                <MenuItem value={MedicalEventArea.PIEMONTE_VALLE_D_AOSTA}>
                  Piemonte e Valle d'Aosta
                </MenuItem>
                <MenuItem value={MedicalEventArea.SARDEGNA}>Sardegna</MenuItem>
                <MenuItem value={MedicalEventArea.SUD_ITALIA}>
                  Sud Italia
                </MenuItem>
                <MenuItem value={MedicalEventArea.TOSCANA}>Toscana</MenuItem>
                <MenuItem value={MedicalEventArea.TRENTINO_ALTO_ADIGE}>
                  Trentino ALto Adige
                </MenuItem>
                <MenuItem value={MedicalEventArea.UMBRIA}>Umbria</MenuItem>
                <MenuItem value={MedicalEventArea.VENETO}>Veneto</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Hidden only={['xs', 'sm']}>
            <Grid item xs={12} md={2}>
              &nbsp;
            </Grid>
          </Hidden>
          <Grid item xs={12} md={4}>
            <FormControl
              fullWidth={true}
              variant="outlined"
              size="small"
              className={classes.search}
            >
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <OutlinedInput
                name="searchString"
                id="searchString"
                label="Cerca"
                autoFocus={searchString.length > 2}
                // type="search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                // disabled={usersLoading}
                endAdornment={
                  <InputAdornment position="end">
                    {/* <IconButton
                      aria-label="search"
                      onClick={() => {}}
                      edge="end"
                    > */}
                    <SearchIcon />
                    {/* </IconButton> */}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {(eventsLoading || myEventsLoading) && (
        <div>Caricamento degli eventi in corso</div>
      )}
      {!eventsLoading &&
        !myEventsLoading &&
        !eventsError &&
        !myEventsError &&
        eventsData &&
        myEventsData && (
          <>
            {eventsData.getMedicalEvents.events.map((event) => {
              return (
                <EventItem
                  key={event.id}
                  event={event}
                  preview={false}
                  canRegister={
                    isBefore(new Date(), new Date(event.deadline)) &&
                    // isBefore(new Date(), new Date(event.dates[0].start)) &&
                    event.open
                  }
                  userRegistered={myEventsData.getUser?.events
                    ?.map((_event) => _event.id)
                    .includes(event.id)}
                  onRegister={async () => {
                    setCurrentEvent(event)
                    await subscribeEventMutation({
                      variables: {
                        userId: userIdVar() as string,
                        eventId: event.id,
                      },
                    })
                  }}
                />
              )
            })}

            {eventsData.getMedicalEvents.events.length > 0 && (
              <div
                style={{
                  marginTop: 30,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  count={Math.ceil(eventsData.getMedicalEvents.total / 5)}
                  // count={1}
                  page={page}
                  color="primary"
                  onChange={handleChangePage}
                />
              </div>
            )}
            {eventsData.getMedicalEvents.events.length === 0 && <NoResults />}
          </>
        )}
      <DialogInfo
        title="Le confermiamo che la sua iscrizione è stata completata."
        open={showSubscribedMessage}
        onClose={() => setShowSubscribedMessage(false)}
      >
        <Typography variant="h3">
          {currentEvent?.registrationConfirmation}
        </Typography>
      </DialogInfo>

      <DialogError
        title="Errore!"
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
      >
        <Typography variant="h3">
          Si è verificato un errore durante la registrazione all'evento. Si
          prega di riprovare più tardi. Se il problema persiste contattare
          l'assistenza.
        </Typography>
      </DialogError>
    </>
  )
}
