import React, { useEffect, useState } from 'react'
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Hidden,
} from '@material-ui/core'
import {
  CreateProfessionalForm,
  HomeHeader,
  HomeFooter,
  LoginForm,
  DialogInfo,
  OuterContactForm,
  RequestPasswordForm,
  ResetPasswordForm,
} from '../components'
import { Info } from '@material-ui/icons'
import { useLocation } from 'react-router-dom'
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      marginTop: 80,
      overflow: 'initial',
    },
  },
  infoButton: {
    marginBottom: 30,
    borderRadius: 20,
  },
  breadcrumbs: {
    margin: '30px auto',
    [theme.breakpoints.up('md')]: {
      margin: '40px auto',
    },
  },
  h2Title: {
    marginBottom: '0.25em',
    [theme.breakpoints.up('md')]: {
      marginBottom: '1em',
    },
  },
  mobileShadow: {
    background: 'white',
    height: 30,
    boxShadow: '0 -5px 5px -5px rgba(0,0,0,0.12)',
  },
  zeroPadding: {
    padding: '0 !important',
  },
}))

export default function Home() {
  const classes = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const [showCreateProfessionalForm, setShowCreateProfessionalForm] = useState(
    false
  )
  const [showContactForm, setShowContactForm] = useState(false)
  const [showMessageSent, setShowMessageSent] = useState(false)
  const [showRequestPasswordForm, setShowRequestPasswordForm] = useState(false)
  const [showRequestPasswordDone, setShowRequestPasswordDone] = useState(false)
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false)
  const [showResetPasswordDone, setShowResetPasswordDone] = useState(false)
  const [showResetPasswordError, setShowResetPasswordError] = useState(false)
  const [
    showProfessionalCreatedMessage,
    setShowProfessionalCreatedMessage,
  ] = useState(false)
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'))
  const token = query.get('token')

  // const [showPrivacy, setShowPrivacy] = useState(
  //   !document.cookie.split(';').some(function (item) {
  //     return item.trim().indexOf('cookieconsent_status') === 0
  //   })
  // )

  useEffect(() => {
    if (token) setShowResetPasswordForm(true)
  }, [token])

  return (
    <>
      <CreateProfessionalForm
        open={showCreateProfessionalForm}
        onClose={() => {
          setShowCreateProfessionalForm(false)
        }}
        onSuccess={() => {
          setShowCreateProfessionalForm(false)
          setShowProfessionalCreatedMessage(true)
        }}
      />
      <RequestPasswordForm
        open={showRequestPasswordForm}
        onClose={() => {
          setShowRequestPasswordForm(false)
        }}
        onSuccess={() => {
          setShowRequestPasswordForm(false)
          setShowRequestPasswordDone(true)
        }}
      />

      <ResetPasswordForm
        token={token || ''}
        open={showResetPasswordForm}
        onClose={() => {
          setShowResetPasswordForm(false)
        }}
        onSuccess={() => {
          setShowResetPasswordForm(false)
          setShowResetPasswordDone(true)
        }}
        onError={() => {
          setShowResetPasswordForm(false)
          setShowResetPasswordError(true)
        }}
      />
      <OuterContactForm
        open={showContactForm}
        onClose={() => {
          setShowContactForm(false)
        }}
        onSuccess={() => {
          setShowContactForm(false)
          setShowMessageSent(true)
        }}
      />
      <DialogInfo
        open={showResetPasswordError}
        title="Errore"
        onClose={() => setShowResetPasswordError(false)}
      >
        <Typography variant="h3">
          Si è verificato un errore durante il reset della password. Il link è
          scaduto.
        </Typography>
      </DialogInfo>
      <DialogInfo
        open={showResetPasswordDone}
        title="Password resettata"
        onClose={() => setShowResetPasswordDone(false)}
      >
        <Typography variant="h3">
          La password è stata reimpostata correttamente. Può loggarsi già da ora
          con le nuove credenziali.
        </Typography>
      </DialogInfo>
      <DialogInfo
        open={showMessageSent}
        title="Grazie per la sua richiesta"
        onClose={() => setShowMessageSent(false)}
      >
        <Typography variant="h3">
          La sua richiesta di informazione è stata inviata correttamente. Verrà
          contattato dall’agente di zona.
        </Typography>
      </DialogInfo>
      <DialogInfo
        open={showRequestPasswordDone}
        title="Grazie per la sua richiesta"
        onClose={() => setShowRequestPasswordDone(false)}
      >
        <Typography variant="h3">
          Abbiamo ricevuto la sua richiesta. Se l'indirizzo email è valido
          riceverà un link per resettare la password nella casella mail da lei
          indicata.
        </Typography>
      </DialogInfo>
      <DialogInfo
        open={showProfessionalCreatedMessage}
        onClose={() => setShowProfessionalCreatedMessage(false)}
      >
        <Typography variant="h3">
          La ringraziamo per la sua registrazione all’Area Riservata. Le
          confermiamo che il suo account è attivo. Per accedere usi le
          credenziali da lei scelte durante la fase di registrazione.
        </Typography>
      </DialogInfo>
      <HomeHeader />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h1">Professionisti</Typography>
            <Breadcrumbs
              className={classes.breadcrumbs}
              aria-label="breadcrumb"
            >
              <Link color="inherit" href="/">
                Home
              </Link>
              <Typography
                color="textPrimary"
                variant="inherit"
                component="span"
                style={{ marginTop: '0.75em' }}
              >
                Professionisti
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        {/* <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            {' '}
            <Typography
              variant="h2"
              className={classes.h2Title}
              style={{ marginBottom: 0 }}
            >
              Corsi di aggiornamento medico – scientifico e informazioni
              scientifiche
            </Typography>
          </Grid>
        </Grid> */}
        <Hidden mdUp>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.zeroPadding}>
              <div className={classes.mobileShadow}></div>
            </Grid>
          </Grid>
        </Hidden>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              // marginBottom: 50,
              backgroundColor: isMobile ? '#ffffff' : 'transparent',
              borderRight: isMobile ? 'none' : 'solid rgba(0,0,0,0.25) 1px',
            }}
          >
            <Typography
              variant="h2"
              className={classes.h2Title}
              style={{ marginBottom: 25, fontWeight: 600, fontSize: 32 }}
            >
              Corsi di aggiornamento medico - scientifico
            </Typography>
            <Typography
              variant="h3"
              paragraph={true}
              style={{ marginBottom: 25 }}
            >
              Per partecipare ai{' '}
              <b>corsi di aggiornamento medico – scientifico</b>, riservati al
              personale sanitario, è necessario iscriversi e accedere all’area
              riservata.
            </Typography>
            <Typography variant="h2" className={classes.h2Title}>
              Accesso area riservata
            </Typography>
            <LoginForm
              onRequestAccess={() => setShowCreateProfessionalForm(true)}
              onResetPassword={() => setShowRequestPasswordForm(true)}
            />
            {/* <Typography variant="h2" className={classes.h2Title}>
              Informazioni scientifiche e corsi di aggiornamento medico –
              scientifico
            </Typography> */}
          </Grid>
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              className={classes.zeroPadding}
              style={{ marginTop: 20 }}
            >
              <div className={classes.mobileShadow}></div>
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              marginBottom: 50,
              backgroundColor: isMobile ? '#ffffff' : 'transparent',
            }}
          >
            <Typography
              variant="h2"
              className={classes.h2Title}
              style={{ marginBottom: 25, fontWeight: 600, fontSize: 32 }}
            >
              Richiesta informazioni
            </Typography>
            <Typography variant="h3" style={{ marginBottom: '0.5em' }}>
              Per essere contattato dall’agente di zona per maggiori
              informazioni sui prodotti compili il modulo.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={classes.infoButton}
              startIcon={<Info />}
              onClick={() => setShowContactForm(true)}
            >
              RICHIESTA INFORMAZIONI
            </Button>
          </Grid>
        </Grid>
      </Container>
      <HomeFooter />
      {/* <Privacy
        show={showPrivacy}
        onDismiss={() => {
          setShowPrivacy(false)
          document.cookie = `cookieconsent_status=dismiss; expires=${addDays(
            new Date(),
            7
          ).toUTCString()}`
        }}
        onShow={() => {
          setShowPrivacy(true)
        }}
      /> */}
    </>
  )
}
