import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Grid,
  FormControl,
  Hidden,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Search as SearchIcon } from '@material-ui/icons'
import { GET_EVENTS, MY_EVENTS } from 'query'
import { userIdVar } from 'cache'
import {
  getMedicalEvents,
  getMedicalEventsVariables,
  myEvents,
  myEventsVariables,
  MedicalEventOrderByInput,
  MedicalEventWhereInput,
  MedicalEventType,
  MedicalEventStatus,
} from 'types/generated/schemaTypes'
import {
  Breadcrumb,
  EventItem,
  Title,
  NoResults,
  FiltersWrapper,
} from 'components'

const useStyle = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  preview: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        padding: '65px 105px 60px 105px',
      },
    },
  },
  search: {
    '& .MuiInputBase-root': {
      borderRadius: 20,
      backgroundColor: '#ffffff',
    },
  },
}))

export default function EventiArchiviati() {
  const classes = useStyle()

  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState<MedicalEventOrderByInput>(
    MedicalEventOrderByInput.DATE_DESC
  )
  const [type, setType] = useState<MedicalEventType | 'ALL'>('ALL')
  const [searchString, setSearchString] = useState('')

  const where: MedicalEventWhereInput = {}
  if (type !== 'ALL') {
    where.type = type
  }
  if (searchString.length > 2) where.searchPattern = searchString
  where.status = MedicalEventStatus.ARCHIVED
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useQuery<getMedicalEvents, getMedicalEventsVariables>(GET_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderby: orderBy,
      offset,
      limit: 5,
    },
  })

  const {
    data: myEventsData,
    loading: myEventsLoading,
    error: myEventsError,
  } = useQuery<myEvents, myEventsVariables>(MY_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      id: userIdVar() as string,
    },
  })

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * 5)
    setPage(value)
  }

  return (
    <>
      <Title title="Archivio eventi" />
      <Breadcrumb current="Archivio eventi" />
      <FiltersWrapper>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={orderBy}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setPage(1)
                  setOrderBy(e.target.value as MedicalEventOrderByInput)
                }}
              >
                <MenuItem value={MedicalEventOrderByInput.DATE_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={MedicalEventOrderByInput.DATE_ASC}>
                  Data creazione ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel id="type-label">Visualizza</InputLabel>
              <Select
                name="type"
                value={type}
                labelId="type-label"
                id="type"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0)
                  setPage(1)
                  setType(e.target.value as MedicalEventType | 'ALL')
                }}
              >
                <MenuItem value={'ALL'}>Tutti le tipologie</MenuItem>
                <MenuItem value={MedicalEventType.WEBINAR}>Webinar</MenuItem>
                <MenuItem value={MedicalEventType.CLASSROOM}>
                  In presenza
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Hidden only={['xs', 'sm']}>
            <Grid item xs={12} md={2}>
              &nbsp;
            </Grid>
          </Hidden>
          <Grid item xs={12} md={4}>
            <FormControl
              fullWidth={true}
              variant="outlined"
              size="small"
              className={classes.search}
            >
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <OutlinedInput
                name="searchString"
                id="searchString"
                label="Cerca"
                autoFocus={searchString.length > 2}
                // type="search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                // disabled={usersLoading}
                endAdornment={
                  <InputAdornment position="end">
                    {/* <IconButton
                      aria-label="search"
                      onClick={() => {}}
                      edge="end"
                    > */}
                    <SearchIcon />
                    {/* </IconButton> */}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {(eventsLoading || myEventsLoading) && (
        <div>Caricamento degli eventi in corso</div>
      )}
      {!eventsLoading &&
        !myEventsLoading &&
        !eventsError &&
        !myEventsError &&
        eventsData &&
        myEventsData && (
          <>
            {eventsData.getMedicalEvents.events.map((event) => {
              return (
                <EventItem
                  key={event.id}
                  event={event}
                  preview={true}
                  canRegister={false}
                  userRegistered={myEventsData.getUser?.events
                    ?.map((_event) => _event.id)
                    .includes(event.id)}
                  onRegister={async () => {}}
                />
              )
            })}

            {eventsData.getMedicalEvents.events.length > 0 && (
              <div
                style={{
                  marginTop: 30,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  count={Math.ceil(eventsData.getMedicalEvents.total / 5)}
                  // count={1}
                  page={page}
                  color="primary"
                  onChange={handleChangePage}
                />
              </div>
            )}
            {eventsData.getMedicalEvents.events.length === 0 && <NoResults />}
          </>
        )}
    </>
  )
}
