import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { SEND_REQUEST_OUTER } from 'query'
import {
  Profession,
  Specialization,
  sendContactRequestOuter,
  sendContactRequestOuterVariables,
  RequestInput,
} from 'types/generated/schemaTypes'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ActionType, DialogForm, GDPR } from 'components'
import { formatError, isValidFiscalCode, professionsMap } from 'utils'
import { provinces } from 'provinces'

export interface ContactFormValues {
  name: string
  lastname: string
  // gender: Gender | string
  // birthday: Date | undefined | null
  fiscalCode: string
  profession: Profession | string
  specialization: Specialization | string
  customProfession: string | null
  customSpecialization: string | null
  registerId: string
  email: string
  emailCheck: string
  phone: string
  privacy: boolean
  addressLine: string
  zip: string
  city: string
  province: string
  region: string
  subject: string
  message: string
  marketing: string | null
}

const defaultInitialValues = {
  name: '',
  lastname: '',
  // gender: '',
  // birthday: null,
  fiscalCode: '',
  profession: '',
  specialization: '',
  customProfession: '',
  customSpecialization: '',
  email: '',
  emailCheck: '',
  phone: '',
  agent: '',
  registerId: '',
  privacy: false,
  addressLine: '',
  zip: '',
  city: '',
  province: '',
  region: '',
  subject: '',
  message: '',
  marketing: null,
}

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obbligatorio'),
  lastname: Yup.string().required('Campo obbligatorio'),
  subject: Yup.string().required('Campo obbligatorio'),
  message: Yup.string().required('Campo obbligatorio'),
  // gender: Yup.string().required('Campo obbligatorio'),
  registerId: Yup.string().required('Campo obbligatorio'),
  // birthday: Yup.mixed().required('Campo obbligatorio'),
  fiscalCode: Yup.string()
    .required('Campo obbligatorio')
    .matches(/^[A-Za-z0-9]+$/, 'Sono ammessi solo numeri e caratteri')
    .min(16, 'Il codice fiscale deve contenere 16 caratteri')
    .max(16, 'Il codice fiscale deve contenere 16 caratteri')
    .test({
      name: 'fiscalCode',
      exclusive: true,
      message: 'Codice fiscale non valido',
      test: (value) => {
        if (!value) return false
        if (value.length !== 16) return false
        return isValidFiscalCode(value)
      },
    }),
  email: Yup.string()
    .email('Indirizzo email non valido')
    .required('Campo obbligatorio'),
  emailCheck: Yup.string()
    .email('Indirizzo email non valido')
    .test('equal', 'Le email non combaciano', function (v) {
      const ref = Yup.ref('email')
      return v === this.resolve(ref)
    })
    .required('Campo obbligatorio'),
  phone: Yup.string().required('Campo obbligatorio'),
  marketing: Yup.string().nullable().required('Campo obbligatorio'),
  privacy: Yup.boolean().oneOf([true], 'Campo obbligatorio'),
  addressLine: Yup.string().required('Campo obbligatorio'),
  zip: Yup.string().required('Campo obbligatorio'),
  city: Yup.string().required('Campo obbligatorio'),
  province: Yup.string().required('Campo obbligatorio'),
  profession: Yup.mixed().required('Campo obbligatorio'),
  region: Yup.mixed().required('Campo obbligatorio'),
  specialization: Yup.mixed().required('Campo obbligatorio'),
  customProfession: Yup.string().when('profession', {
    is: Profession.ALTRO,
    then: Yup.string().required('Campo obbligatorio'),
  }),
  customSpecialization: Yup.string().when('specialization', {
    is: Specialization.ALTRO,
    then: Yup.string().required('Campo obbligatorio'),
  }),
})

interface ContactFormProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

const ContactForm: React.FC<ContactFormProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [formError, setFormError] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [sendRequestQuery] = useLazyQuery<
    sendContactRequestOuter,
    sendContactRequestOuterVariables
  >(SEND_REQUEST_OUTER, {
    onError: (err) => {
      setFormError(formatError(err))
      setIsSending(false)
    },
    onCompleted: () => {
      onSuccess()
    },
  })
  const formik = useFormik<ContactFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const request: RequestInput = {
        email: values.email,
        name: values.name,
        lastname: values.lastname,
        address: {
          addressLine: values.addressLine,
          city: values.city,
          zip: values.zip,
          province: values.province,
          region: values.region,
        },
        profession: values.profession as Profession,
        specialization: values.specialization as Specialization,
        customProfession:
          values.customProfession === '' ? null : values.customProfession,
        customSpecialization:
          values.customSpecialization === ''
            ? null
            : values.customSpecialization,
        phone: values.phone,
        fiscalCode: values.fiscalCode,
        registerId: values.registerId,
        subject: values.subject,
        message: values.message,
        marketing: values.marketing === 'SI',
      }
      setIsSending(true)
      sendRequestQuery({ variables: { request } })
    },
  })

  return (
    <DialogForm
      open={open}
      title="Richiesta informazioni"
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      actions={[
        {
          type: ActionType.CANCEL,
          label: 'ANNULLA',
          callback: () => {
            formik.resetForm()
            onClose()
          },
          disabled: isSending,
        },
        {
          type: ActionType.SEND,
          label: 'INVIA',
          callback: formik.submitForm,
          disabled: isSending,
        },
      ]}
    >
      <div style={{ overflow: 'hidden' }}>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Dati personali
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* NOME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="outlined"
                id="name"
                size="small"
                label="Nome*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* COGNOME */}
            <FormControl fullWidth={true}>
              <TextField
                name="lastname"
                error={formik.touched.lastname && !!formik.errors.lastname}
                helperText={
                  formik.touched.lastname &&
                  !!formik.errors.lastname &&
                  formik.errors.lastname
                }
                variant="outlined"
                id="lastname"
                size="small"
                label="Cognome*"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* CODICE FISCALE */}
            <FormControl fullWidth={true}>
              <TextField
                name="fiscalCode"
                error={formik.touched.fiscalCode && !!formik.errors.fiscalCode}
                helperText={
                  formik.touched.fiscalCode &&
                  !!formik.errors.fiscalCode &&
                  formik.errors.fiscalCode
                }
                variant="outlined"
                id="fiscalCode"
                size="small"
                label="Codice fiscale*"
                value={formik.values.fiscalCode}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 16 }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Iscrizione al collegio/ordine/ASS */}
            <FormControl fullWidth={true}>
              <TextField
                name="registerId"
                error={formik.touched.registerId && !!formik.errors.registerId}
                helperText={
                  formik.touched.registerId &&
                  !!formik.errors.registerId &&
                  formik.errors.registerId
                }
                variant="outlined"
                id="registerId"
                size="small"
                placeholder="Indicare ordine di iscrizione all'Albo  professionale e numero*"
                label="Indicare ordine di iscrizione all'Albo  professionale e numero*"
                value={formik.values.registerId}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={9}>
            {/* INDIRIZZO */}
            <FormControl fullWidth={true}>
              <TextField
                name="addressLine"
                error={
                  formik.touched.addressLine && !!formik.errors.addressLine
                }
                helperText={
                  formik.touched.addressLine &&
                  !!formik.errors.addressLine &&
                  formik.errors.addressLine
                }
                variant="outlined"
                id="addressLine"
                size="small"
                label="Indirizzo professionale*"
                value={formik.values.addressLine}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* CAP */}
            <FormControl fullWidth={true}>
              <TextField
                name="zip"
                error={formik.touched.zip && !!formik.errors.zip}
                helperText={
                  formik.touched.zip && !!formik.errors.zip && formik.errors.zip
                }
                variant="outlined"
                id="zip"
                size="small"
                label="CAP*"
                value={formik.values.zip}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 5 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* CITTÀ */}
            <FormControl fullWidth={true}>
              <TextField
                name="city"
                error={formik.touched.city && !!formik.errors.city}
                helperText={
                  formik.touched.city &&
                  !!formik.errors.city &&
                  formik.errors.city
                }
                variant="outlined"
                id="city"
                size="small"
                label="Città*"
                value={formik.values.city}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* PROVINCIA */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={formik.touched.province && !!formik.errors.province}
                id="province-label"
              >
                Provincia*
              </InputLabel>
              <Select
                name="province"
                value={formik.values.province}
                labelId="province-label"
                id="province"
                label="Provincia*"
                error={formik.touched.province && !!formik.errors.province}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              >
                {provinces.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.province && !!formik.errors.province && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.province}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* REGIONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="region"
                error={formik.touched.region && !!formik.errors.region}
                helperText={
                  formik.touched.region &&
                  !!formik.errors.region &&
                  formik.errors.region
                }
                variant="outlined"
                id="region"
                size="small"
                label="Regione*"
                value={formik.values.region}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Dati professionali
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* PROFESSIONE */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={formik.touched.profession && !!formik.errors.profession}
                id="profession-label"
              >
                Professione*
              </InputLabel>
              <Select
                name="profession"
                value={formik.values.profession}
                labelId="profession-label"
                id="profession"
                label="Professione*"
                error={formik.touched.profession && !!formik.errors.profession}
                onChange={(e) => {
                  formik.setFieldValue('specialization', '')
                  formik.setFieldValue('profession', e.target.value)
                }}
                disabled={formik.isSubmitting}
              >
                {[
                  Profession.BIOLOGO,
                  // Profession.DIETISTA,
                  Profession.DISCIPLINE_OLISTICHE,
                  Profession.FARMACISTA,
                  // Profession.FISIOTERAPISTA,
                  // Profession.IGIENISTA_DENTALE,
                  Profession.MEDICO_CHIRURGO,
                  Profession.ODONTOIATRA,
                  Profession.PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE,
                  Profession.PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE,
                  Profession.PROFESSIONI_TECNICO_SANITARIE,
                  Profession.PSICOLOGO,
                  Profession.ALTRO,
                ].map((item) => (
                  <MenuItem key={item} value={item}>
                    {professionsMap[item].label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.profession && !!formik.errors.profession && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.profession}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* SPECIALIZZAZIONE */}
            <FormControl fullWidth={true} variant="outlined" size="small">
              <InputLabel
                error={
                  formik.touched.specialization &&
                  !!formik.errors.specialization
                }
                id="specialization-label"
              >
                Specializzazione*
              </InputLabel>
              <Select
                name="specialization"
                value={formik.values.specialization}
                labelId="specialization-label"
                id="specialization"
                label="Specializzazione*"
                error={
                  formik.touched.specialization &&
                  !!formik.errors.specialization
                }
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              >
                {formik.values.profession &&
                  professionsMap[
                    formik.values.profession as Profession
                  ].specializations.map(
                    (item: {
                      specialization: Specialization
                      label: string
                    }) => {
                      return (
                        <MenuItem
                          key={item.specialization}
                          value={item.specialization}
                        >
                          {item.label}
                        </MenuItem>
                      )
                    }
                  )}
              </Select>
              {formik.touched.specialization && !!formik.errors.specialization && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.specialization}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* PROFESSIONE CUSTOM */}
            {formik.values.profession === Profession.ALTRO && (
              <FormControl fullWidth={true}>
                <TextField
                  name="customProfession"
                  error={
                    formik.touched.customProfession &&
                    !!formik.errors.customProfession
                  }
                  helperText={
                    formik.touched.customProfession &&
                    !!formik.errors.customProfession &&
                    formik.errors.customProfession
                  }
                  variant="outlined"
                  id="customProfession"
                  size="small"
                  label="Indicare la professione*"
                  value={formik.values.customProfession}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {/* SPECIALIZZAZIONE CUSTOM */}
            {formik.values.specialization === Specialization.ALTRO && (
              <FormControl fullWidth={true}>
                <TextField
                  name="customSpecialization"
                  error={
                    formik.touched.customSpecialization &&
                    !!formik.errors.customSpecialization
                  }
                  helperText={
                    formik.touched.customSpecialization &&
                    !!formik.errors.customSpecialization &&
                    formik.errors.customSpecialization
                  }
                  variant="outlined"
                  id="customSpecialization"
                  size="small"
                  label="Indicare la specializzazione*"
                  value={formik.values.customSpecialization}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Contatti
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="outlined"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* VERIFICA EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="emailCheck"
                error={formik.touched.emailCheck && !!formik.errors.emailCheck}
                helperText={
                  formik.touched.emailCheck &&
                  !!formik.errors.emailCheck &&
                  formik.errors.emailCheck
                }
                variant="outlined"
                id="emailCheck"
                size="small"
                label="Verifica email*"
                value={formik.values.emailCheck}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* TELEFONO */}
            <FormControl fullWidth={true}>
              <TextField
                name="phone"
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={
                  formik.touched.phone &&
                  !!formik.errors.phone &&
                  formik.errors.phone
                }
                variant="outlined"
                id="phone"
                size="small"
                label="Telefono*"
                value={formik.values.phone}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                inputProps={{ pattern: '[0-9]' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginBottom: '1em' }}
        >
          Richiesta
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* OGGETTO */}
            <FormControl fullWidth={true}>
              <TextField
                name="subject"
                error={formik.touched.subject && !!formik.errors.subject}
                helperText={
                  (formik.touched.subject &&
                    !!formik.errors.subject &&
                    formik.errors.subject) ||
                  "Inserisci l'oggetto della richiesta"
                }
                variant="outlined"
                id="subject"
                size="small"
                label="Oggetto*"
                value={formik.values.subject}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* MESSAGGIO */}
            <FormControl fullWidth={true}>
              <TextField
                name="message"
                error={formik.touched.message && !!formik.errors.message}
                helperText={
                  (formik.touched.message &&
                    !!formik.errors.message &&
                    formik.errors.message) ||
                  'Inserisci il tuo messaggio'
                }
                variant="outlined"
                id="message"
                size="small"
                label="Messaggio*"
                value={formik.values.message}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                multiline={true}
                rows={4}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <GDPR />
            <Typography
              variant="caption"
              style={{ fontSize: 12 }}
              color="textSecondary"
            >
              Avendo letto e compreso le informazioni presenti nella{' '}
              <Link
                href="https://www.bromatech.it/privacy-policy"
                rel="noopener"
                target="_blank"
              >
                Privacy Policy
              </Link>{' '}
              di questo sito e sapendo che il Trattamento dei dati richiesti è
              funzionale all’iscrizione all’Area Riservata e/o al modulo di
              Richiesta Informazioni, dichiaro quanto segue:
            </Typography>
            <br />
            <br />
            <Typography
              variant="caption"
              style={{ fontSize: 12 }}
              color="textSecondary"
              display="block"
            >
              a) * di prestare il consenso informato al trattamento dei
              summenzionati dati al fine di{' '}
              <b>
                essere contattato dall’agente di zona per maggiori informazioni
                sui prodotti Bromatech.
              </b>
            </Typography>
            <FormControl fullWidth={true} variant="outlined">
              <FormControlLabel
                label={<div>Acconsento</div>}
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.privacy}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    name="privacy"
                  />
                }
              />
              {formik.touched.privacy && !!formik.errors.privacy && (
                <FormHelperText
                  error={formik.touched.privacy && !!formik.errors.privacy}
                  variant="standard"
                >
                  {formik.touched.privacy &&
                    !!formik.errors.privacy &&
                    formik.errors.privacy}
                </FormHelperText>
              )}
            </FormControl>
            <Typography
              variant="caption"
              style={{ fontSize: 12 }}
              color="textSecondary"
            >
              b) *di prestare il consenso informato al trattamento dei
              summenzionati dati al fine di{' '}
              <b>
                ricevere gli inviti di partecipazione ad eventi-corsi di
                aggiornamento medico-scientifico.
              </b>
            </Typography>
            <FormControl fullWidth={true} variant="outlined">
              <RadioGroup
                aria-label="marketing"
                name="marketing"
                value={formik.values.marketing}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  labelPlacement="end"
                  label={'Acconsento'}
                  control={
                    <Radio
                      color="primary"
                      disabled={formik.isSubmitting}
                      value={'SI'}
                    />
                  }
                />
                <FormControlLabel
                  labelPlacement="end"
                  label={'Non acconsento'}
                  control={
                    <Radio
                      color="primary"
                      disabled={formik.isSubmitting}
                      value={'NO'}
                    />
                  }
                />
              </RadioGroup>
              {formik.touched.marketing && !!formik.errors.marketing && (
                <FormHelperText
                  error={formik.touched.marketing && !!formik.errors.marketing}
                  variant="standard"
                >
                  {formik.touched.marketing &&
                    !!formik.errors.marketing &&
                    formik.errors.marketing}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        {formError && (
          <Typography color="error" variant="body1">
            {formError}
          </Typography>
        )}
      </div>
    </DialogForm>
  )
}

export default ContactForm
